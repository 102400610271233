import React from 'react';

export interface FinancialSecureCcProps {
  color: string;
  size?: number;
}

const FinancialSecureCc: React.FC<FinancialSecureCcProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_5205_36499)">
      <path
        d="M11.4108 17.1751H5.88215C4.84267 17.1751 4 16.3324 4 15.2929V5.88215C4 4.84267 4.84267 4 5.88215 4H18.9394C19.9789 4 20.8215 4.84267 20.8215 5.88215V7.76431V9.64646"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9914 12.5891L20.3962 13.1999C20.6466 13.2635 20.8231 13.491 20.8154 13.7491C20.6538 19.1681 17.5281 20.0001 17.5281 20.0001C17.5281 20.0001 14.4024 19.1681 14.2408 13.7491C14.2331 13.491 14.4097 13.2635 14.66 13.1999L17.0648 12.5891C17.3689 12.512 17.6874 12.512 17.9914 12.5891Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 8.25H15.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5205_36499">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FinancialSecureCc;
