import React from 'react';

export interface ISpaceLotProps {
  color: string;
  size?: number;
}

const SpaceLot: React.FC<ISpaceLotProps> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3.5 9.2963L12.2073 4L20.5 9.2963L12.2073 15L3.5 9.2963Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 12.2143L16 6.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 11.8333L8 6.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 13.2324L12.2195 19.3788L21 13.2324"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SpaceLot;
