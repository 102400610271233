import React from 'react';

export interface AmenitiesPoolProps {
  color: string;
  size?: number;
}

const AmenitiesPool: React.FC<AmenitiesPoolProps> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19 19.6924H18.4615C17.8903 19.6924 17.3425 19.4655 16.9385 19.0616C16.5346 18.6576 16.3077 18.1098 16.3077 17.5386C16.3077 18.1098 16.0808 18.6576 15.6768 19.0616C15.2729 19.4655 14.7251 19.6924 14.1538 19.6924C13.5826 19.6924 13.0348 19.4655 12.6308 19.0616C12.2269 18.6576 12 18.1098 12 17.5386C12 18.1098 11.7731 18.6576 11.3692 19.0616C10.9652 19.4655 10.4174 19.6924 9.84615 19.6924C9.27492 19.6924 8.72708 19.4655 8.32315 19.0616C7.91923 18.6576 7.69231 18.1098 7.69231 17.5386C7.69231 18.1098 7.46539 18.6576 7.06146 19.0616C6.65754 19.4655 6.1097 19.6924 5.53846 19.6924H5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.46143 7.46145C6.46143 6.80863 6.72076 6.18255 7.18237 5.72094C7.64398 5.25933 8.27006 5 8.92287 5C9.57569 5 10.2018 5.25933 10.6634 5.72094C11.125 6.18255 11.3843 6.80863 11.3843 7.46145V15.0765"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0769 5C15.7297 5 16.3558 5.25933 16.8174 5.72094C17.279 6.18255 17.5384 6.80863 17.5384 7.46145V15.0765"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3842 8.92285H17.5378"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3842 12.6152H17.5378"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AmenitiesPool;
