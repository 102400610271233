import React from 'react';

export interface FinancialPayProps {
  size: number;
  color: string;
}

const FinancialPay: React.FC<FinancialPayProps> = ({ size, color }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.80005 16.4762V6H15.8613V16.4762C15.8613 17.3178 15.1721 18 14.3219 18H9.33941C8.48924 18 7.80005 17.3178 7.80005 16.4762Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M7.13319 8.28571H5.13803C4.50951 8.28571 4 7.77404 4 7.14286C4 6.51167 4.50951 6 5.13803 6H18.0357C18.6641 6 19.1737 6.51167 19.1737 7.14286C19.1737 7.77404 18.6641 8.28571 18.0357 8.28571H16.0405"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M13.5 6V18"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FinancialPay;
