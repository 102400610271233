import './PropertyThumbnail.scss';

import PropTypes from 'prop-types';
import React from 'react';

function PropertyThumbnail(props) {
  const { thumbnail, address } = props;
  return (
    <div className="PropertyThumbnail">
      <div
        className="thumbnail"
        style={{ backgroundImage: `url("${thumbnail}")` }}
      />
      <div className="address">{address}</div>
    </div>
  );
}

PropertyThumbnail.propTypes = {
  /**
   * What is the thumbnail src
   */
  thumbnail: PropTypes.string.isRequired,
  /**
   * What is the address
   */
  address: PropTypes.string.isRequired,
};

export default PropertyThumbnail;
