import cx from 'classnames';
import React from 'react';
import { GradeEnum, GradeType, ListValueType } from '../../types';

type GradeValueProps = {
  grade?: GradeType;
  value: ListValueType;
};

const GradeValue: React.FC<GradeValueProps> = ({
  grade,
  value = '-',
}): React.ReactElement => {
  const getColorByValue = () => {
    switch (grade) {
      case GradeEnum.A:
        return 'bg-[#3cc32e]';
      case GradeEnum.B:
        return 'bg-[#98dd60]';
      case GradeEnum.C:
        return 'bg-[#ffc700]';
      case GradeEnum.D:
        return 'bg-[#ff7342]';
      case GradeEnum.F:
        return 'bg-[#ff1616]';
      default:
        return 'bg-[#858585]';
    }
  };

  const divCx = cx(
    'GradeValue text-white h-6 w-6 rounded-full flex items-center justify-center ml-auto',
    {
      [`${getColorByValue()}`]: true,
    }
  );

  return <div className={divCx}>{value === null ? '-' : value}</div>;
};

export default GradeValue;
