import './SectionNavigation.scss';

import { ScrollShadow } from '@nextui-org/react';
import cx from 'classnames';
import React, { useEffect, useRef } from 'react';

type SectionNavigationProps = {
  list: Array<{
    id: string;
    link?: string;
    label: string;
    detailedLabel?: string;
    icon?: string | React.ReactElement;
  }>;
  active: string;
};

const SectionNavigation: React.FC<SectionNavigationProps> = (props) => {
  const { list = [], active = '' } = props;
  const scrollRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    if (scrollRef.current) {
      const container = scrollRef.current;
      const activeEl = container.querySelector('.active');
      activeEl?.scrollIntoView({
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [active]);

  return (
    <div className="SectionNavigation w-[100vw] bg-white font-raleway">
      <ScrollShadow hideScrollBar orientation="horizontal">
        <ul className="flex flex-row p-0" ref={scrollRef}>
          {list.map((item) => {
            const liClassName = cx({ active: active === item.id });
            const anchorClassName = cx({
              'text-pl-main-brand-blue font-bold': active === item.id,
            });
            return (
              <li key={item.id} className={liClassName}>
                <a className={anchorClassName} href={item.id}>
                  <span className="mobile">{item.label}</span>
                  <span className="desktop">
                    {item.detailedLabel || item.label}
                  </span>
                </a>
              </li>
            );
          })}
        </ul>
      </ScrollShadow>
    </div>
  );
};
export default SectionNavigation;
