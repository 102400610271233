import React from 'react';

export interface NavigationalExternalLinkProps {
  color: string;
  size?: number;
}

const NavigationalExternalLink: React.FC<NavigationalExternalLinkProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_37150)">
        <path
          d="M17.8334 14.2166V17.2166C17.8334 18.3212 16.9379 19.2166 15.8334 19.2166H6.50004C5.39547 19.2166 4.50004 18.3212 4.50004 17.2166V9.1333C4.50004 8.02873 5.39547 7.1333 6.50004 7.1333L11.1667 7.1333"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.8634 10.6358L17.8634 7.10031M17.8634 7.10031L14.3279 7.10031M17.8634 7.10031L11.9709 12.9929"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_37150">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NavigationalExternalLink;
