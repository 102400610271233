import React from 'react';

export interface SubsectionsPropertyDetailsProps {
  color: string;
  size?: number;
}

const SubsectionsPropertyDetails: React.FC<SubsectionsPropertyDetailsProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clipPath="url(#clip0_6411_73683)">
      <path
        d="M27.5881 22.0534L15.6977 13.7188L3.81543 22.0534H27.5881Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5881 22.0534L15.6977 13.7188L3.81543 22.0534H27.5881Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5881 22.0534H44.812L29.7574 11.5005L21.1536 17.5354"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9184 22.0535H5.67505V41.2021H23.9184V22.0535Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.0964 22.0535H23.853V41.2021H42.0964V22.0535Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9209 31.6196H12.093V41.1939H16.9209V31.6196Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.2257 28.9773H27.5881V41.1939H39.2257V28.9773Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5881 32.158H39.2176"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5881 35.5422H39.2176"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5881 38.5842H39.2176"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6978 13.7188L24.9051 14.9013"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.7576 11.5005L40.1637 13.7187L52.1846 22.0534H44.8122"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.064 22.0535V37.4506L42.1045 41.1939"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6411_73683">
        <rect
          width="50"
          height="35.0514"
          fill="white"
          transform="translate(3 10)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SubsectionsPropertyDetails;
