import React from 'react';

export interface AmenitiesGymProps {
  color: string;
  size?: number;
}

const AmenitiesGym: React.FC<AmenitiesGymProps> = ({ color, size = 24 }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_36179)">
        <path
          d="M6.82856 12.243L5.02854 14.043C4.81551 14.256 4.81551 14.6014 5.02854 14.8144L10.6857 20.4716C10.8988 20.6847 11.2442 20.6847 11.4572 20.4716L13.2572 18.6716C13.4702 18.4586 13.4702 18.1132 13.2572 17.9002L7.6 12.243C7.38697 12.0299 7.04159 12.0299 6.82856 12.243Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5436 4.52862L12.7436 6.32864C12.5306 6.54168 12.5306 6.88705 12.7436 7.10007L18.4008 12.7573C18.6139 12.9703 18.9592 12.9703 19.1723 12.7573L20.9723 10.9573C21.1853 10.7442 21.1853 10.3989 20.9723 10.1858L15.3151 4.52862C15.1021 4.31559 14.7567 4.31557 14.5436 4.52862Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.47147 21.114L4.38572 18.0283C4.17269 17.8153 4.17269 17.4699 4.38572 17.2569L5.54287 16.0997C5.7559 15.8867 6.10129 15.8867 6.31431 16.0997L9.40006 19.1855C9.6131 19.3985 9.61309 19.7439 9.40006 19.9569L8.2429 21.114C8.02988 21.3271 7.68451 21.3271 7.47147 21.114Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.6151 6.97158L18.5294 3.88584C18.3164 3.67281 17.971 3.67279 17.758 3.88584L16.6008 5.04299C16.3878 5.25604 16.3878 5.6014 16.6008 5.81443L19.6866 8.90018C19.8996 9.11322 20.2449 9.11322 20.458 8.90018L21.6151 7.74302C21.8282 7.52998 21.8282 7.18463 21.6151 6.97158Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4285 15.0713L15.5714 9.92838"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_36179">
          <rect
            width="25"
            height="24"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AmenitiesGym;
