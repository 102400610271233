import React from 'react';

export interface RoomsAtticProps {
  color: string;
  size?: number;
}

const RoomsAttic: React.FC<RoomsAtticProps> = ({ color, size = 24 }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_6133_59428)">
        <path
          d="M2 11.8966L12.2069 5L22.4138 11.8966"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M20.3018 6L20.3018 10.4138"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M5 14L5 21"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 14L20 18L14.5 18L14.5 21L9.5 21"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12.25 5L12.25 11" stroke={color} strokeWidth="1.5" />
        <path
          d="M14.6499 13.25L10.1499 13.25L10.1499 13C10.1499 11.7574 11.1573 10.75 12.3999 10.75C13.6425 10.75 14.6499 11.7574 14.6499 13L14.6499 13.25Z"
          stroke={color}
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_6133_59428">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoomsAttic;
