// import { Button as NextUIButton, extendVariants } from '@nextui-org/react';
import cx from 'classnames';
import { Button as ThemeUIButton } from 'theme-ui';
import {
  useModalContext,
} from '@propertylens/ui-kit';
import { emitTrackingEvent } from '@propertylens/app-commons';

type Radius = 'none' | 'small' | 'medium' | 'large' | 'full';
type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'text';
  disabled?: boolean;
  link?: string;
  openLinkInNewTab: boolean;
  openReportsModal: boolean;
  className?: string;
  text: string;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'full';
  radius?: Radius;
  backgroundColor?: string;
  textColor?: string;
};

const determineRadiusAmount = (radius: Radius) => {
  switch (radius) {
    case 'small':
      return '8px';
    case 'medium':
      return '12px';
    case 'large':
      return '14px';
    case 'full':
      return '9999px';
    default:
      return '0';
  }
};

const navigate = (link: string, isNewTab: boolean) => {
  if (isNewTab) {
    window.open(link, '_blank');
    return;
  }
  window.location.href = link;
};

const Button: React.FC<ButtonProps> = (props) => {
  const { 
    text, 
    variant = 'primary', 
    disabled = false, 
    link, 
    className, 
    size = 'sm', 
    radius = 'none', 
    openLinkInNewTab, 
    openReportsModal, 
    backgroundColor, 
    textColor 
  } = props;

  const handleClick = (openReportsModal, link, openLinkInNewTab) => {
    if(openReportsModal) {
      emitTrackingEvent('Button Clicked',{
        component: 'Get PropertyLens Report'
      })
      openGetReports()
    } else {
      emitTrackingEvent('Button Clicked',{
        component: 'Login'
      })
      navigate(link, openLinkInNewTab)
    }
  }
  const baseCx = cx(
    {
      'sm-button': size === 'sm',
      'md-button': size === 'md',
      'lg-button': size === 'lg',
      'xl-button': size === 'xl',
    },
    className
  );
  const {
    openGetReports,
  } = useModalContext();

  return (
    <ThemeUIButton
      sx={{
        borderRadius: determineRadiusAmount(radius),
        ...(backgroundColor && { backgroundColor: backgroundColor }),
        ...(textColor && { color: textColor }),
      }}
      variant={variant}
      disabled={disabled}
      onClick={() => handleClick(openReportsModal, link, openLinkInNewTab)}
      className={baseCx}
    >
      {text}
    </ThemeUIButton>
  );
};

export default Button;
