import React from 'react';

export interface HomeUseProps {
  color: string;
  size?: number;
}

export default function HomeUse({ color, size = 24 }: HomeUseProps) {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_36515)">
        <mask id="path-1-inside-1_5205_36515" fill="white">
          <path d="M14.5 4L23 14H6L14.5 4Z" />
        </mask>
        <path
          d="M14.5 4L15.6429 3.02853C15.3579 2.69323 14.9401 2.5 14.5 2.5C14.0599 2.5 13.6421 2.69323 13.3571 3.02853L14.5 4ZM23 14V15.5C23.5847 15.5 24.1161 15.1603 24.3615 14.6296C24.6069 14.0989 24.5216 13.474 24.1429 13.0285L23 14ZM6 14L4.85709 13.0285C4.47843 13.474 4.39312 14.0989 4.63853 14.6296C4.88394 15.1603 5.41533 15.5 6 15.5V14ZM13.3571 4.97147L21.8571 14.9715L24.1429 13.0285L15.6429 3.02853L13.3571 4.97147ZM7.14291 14.9715L15.6429 4.97147L13.3571 3.02853L4.85709 13.0285L7.14291 14.9715ZM23 12.5H6V15.5H23V12.5Z"
          fill={color}
          mask="url(#path-1-inside-1_5205_36515)"
        />
        <path d="M9 13V21M20 13V21" stroke={color} strokeWidth="1.5" />
        <path d="M17 8.39024V5H20V12" stroke={color} strokeWidth="1.5" />
        <path d="M4 12V21" stroke={color} strokeWidth="1.5" />
        <path
          d="M1 21.0908H22.4642"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <circle cx="14.5" cy="10" r="1" fill={color} />
        <path d="M13 21V16H17V21" stroke={color} strokeWidth="1.5" />
        <path
          d="M9.29178 11.496H2L6 6H13.2944"
          stroke={color}
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_36515">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
