import React from 'react';

export interface SubsectionsModificationsProps {
  color: string;
  size?: number;
}

const SubsectionsModifications: React.FC<SubsectionsModificationsProps> = ({
  color,
  size = 56,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="20.1267"
      y="10.2948"
      width="37.1646"
      height="7.6"
      rx="3"
      transform="rotate(45 20.1267 10.2948)"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M17.4689 12.7003L16.981 12.2125C15.4189 10.6504 12.8863 10.6504 11.3242 12.2125L9.39825 14.1384C7.83615 15.7005 7.83615 18.2331 9.39825 19.7952L20.4361 30.833C21.9838 32.3807 21.9837 34.89 20.4361 36.4377V36.4377"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="27.7163"
      y1="12.8856"
      x2="32.2364"
      y2="8.36547"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="41.5659"
      y1="26.7352"
      x2="46.086"
      y2="22.2151"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="38.1719"
      y1="23.3412"
      x2="46.6482"
      y2="14.8648"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="31.3835"
      y1="16.5528"
      x2="39.8599"
      y2="8.07647"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="34.7776"
      y1="19.9471"
      x2="46.5508"
      y2="8.17391"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M11.2908 42.6382L11.2908 42.6382L11.2966 42.6337L14.9223 39.801C16.0909 38.888 16.4904 37.6152 16.7397 36.6488C16.7842 36.4762 16.8224 36.3197 16.8579 36.1744C16.9311 35.8752 16.9926 35.6235 17.0731 35.3769C17.1874 35.0269 17.2938 34.8501 17.3883 34.7556L17.8179 34.3261L22.5131 39.0213L22.0835 39.4508C21.989 39.5453 21.8123 39.6517 21.4622 39.766C21.2156 39.8465 20.9639 39.908 20.6647 39.9812C20.5194 40.0167 20.3629 40.055 20.1903 40.0995C19.2239 40.3487 17.9511 40.7482 17.0381 41.9169L14.2055 45.5425L14.2054 45.5425L14.201 45.5483L10.8621 49.8871C9.81301 51.2503 7.82903 51.377 6.64558 50.1936C5.46214 49.0101 5.58881 47.0261 6.95204 45.9771L11.2908 42.6382Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default SubsectionsModifications;
