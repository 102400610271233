import './SectionHeading.scss';

import { Tooltip } from '@nextui-org/react';
import { RiInformation2Line } from '@remixicon/react';
import cx from 'classnames';
import React, { HTMLAttributes } from 'react';

import Icon from '../Icons';
import { IconNameType } from '../Icons/Icon';

export interface SectionHeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
  className?: string;
  color?: string;
  hasUnderline?: boolean;
  icon?: IconNameType;
  level: 1 | 2 | 3 | 4 | 5 | 6 | 'title';
  rightContent?: React.ReactNode;
  tooltip?: React.ReactNode;
  classNames?: {
    base?: string;
    heading?: string;
  };
}

const SectionHeading: React.FC<SectionHeadingProps> = ({
  level,
  color,
  children,
  className,
  classNames = { base: '', heading: '' },
  rightContent,
  tooltip,
  icon,
  hasUnderline = true,
}) => {
  let HeaderTag = `h${level}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  if (level === 'title') {
    HeaderTag = 'h1';
  }

  const containerCx = cx(
    'SectionHeading flex gap-2 items-center font-raleway',
    {
      underlined: (level === 3 || level === 4) && hasUnderline,
      [`--level-${level}`]: true,
      [`${className}`]: className,
      [`${classNames.base}`]: classNames.base,
    }
  );

  const headerCx = cx('SectionHeading pl-dark', {
    [`--level-${level}`]: true,
    [`text-${color}`]: color,
    title: level === 'title',
    [`${classNames.heading}`]: classNames.heading,
  });

  return (
    <div className={containerCx}>
      {icon && <Icon icon={icon} />}
      <HeaderTag className={headerCx}>
        {children}
        {tooltip && (
          <Tooltip showArrow={true} content={tooltip}>
            <div className="inline-block h-[24px] w-[24px] rounded-full">
              <RiInformation2Line size={18} className="relative top-1" />
            </div>
          </Tooltip>
        )}
      </HeaderTag>
      {rightContent && <div className="ml-auto">{rightContent}</div>}
    </div>
  );
};

export default SectionHeading;
