import React, { FC } from 'react';

export interface RisksEarthDisturbanceSm {
  color: string;
  size?: number;
}

const RisksEarthDisturbanceSm: React.FC<RisksEarthDisturbanceSm> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.1721 4.25605L9.1721 1.92285L6.06665 1.92285L6.06665 7.5844"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.872 9.43759L12.0413 1.60938L4.21313 9.43759"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.06738 14.1869L6.06738 8.4375"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8016 13.7689L13.8016 10.7573L10.2788 10.7573L10.2788 13.7689"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0107 8.4375L18.0107 14.1869"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="3.75"
      y1="16.0967"
      x2="20.1105"
      y2="16.0967"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="4.75"
      y1="18.4775"
      x2="10.25"
      y2="18.4775"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="9.91016"
      y1="20.8594"
      x2="14.4102"
      y2="20.8594"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="16.4355"
      y1="20.8594"
      x2="17.3169"
      y2="20.8594"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="6.94141"
      y1="20.8594"
      x2="7.8228"
      y2="20.8594"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="12.6719"
      y1="18.4775"
      x2="18.1719"
      y2="18.4775"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default RisksEarthDisturbanceSm;
