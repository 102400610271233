import React from 'react';

export interface FormsWwwProps {
  color: string;
  size?: number;
}

const FormsWww: React.FC<FormsWwwProps> = ({ color, size = 24 }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_37287)">
        <path
          d="M7.3665 3.66602H16.6248C19.5915 3.66602 20.3332 4.40768 20.3332 7.36602V12.641C20.3332 15.6077 19.5915 16.341 16.6332 16.341H7.3665C4.40817 16.3493 3.6665 15.6077 3.6665 12.6493V7.36602C3.6665 4.40768 4.40817 3.66602 7.3665 3.66602Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 16.3496V20.3329"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.6665 12.834H20.3332"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.25 20.334H15.75"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_37287">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FormsWww;
