import './StarRating.scss';

import React from 'react';

type StarRatingProps = {
  rating: number | string;
};

const StarRating: React.FC<StarRatingProps> = ({ rating }) => {
  return (
    <div className="StarRating inline-flex gap-2 items-center px-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <g filter="url(#filter0_i_7476_60718)">
          <path
            d="M7 0.5L8.76923 5.06486L13.6574 5.33688L9.86268 8.43014L11.1145 13.1631L7 10.51L2.8855 13.1631L4.13732 8.43014L0.342604 5.33688L5.23077 5.06486L7 0.5Z"
            fill="url(#paint0_linear_7476_60718)"
          />
        </g>
        <defs>
          <filter
            id="filter0_i_7476_60718"
            x="-0.657227"
            y="-0.5"
            width="14.3145"
            height="13.6631"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-1" dy="-1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.66 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_7476_60718"
            />
          </filter>
          <linearGradient
            id="paint0_linear_7476_60718"
            x1="7"
            y1="0.5"
            x2="12.5"
            y2="25.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFA800" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <div className="rating font-semibold">{rating}</div>
    </div>
  );
};

export default StarRating;
