import React from 'react';

export interface SubsectionsHomeownerServiceHistoryProps {
  color: string;
  size?: number;
}

const SubsectionsHomeownerServiceHistory: React.FC<
  SubsectionsHomeownerServiceHistoryProps
> = ({ color, size = 56 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.331 24.2156V52.9999H6V5.75342H14.6249"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.7059 5.75342H41.3308V12.4992"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.7155 3H14.6257V8.50748H32.7155V3Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.1841 24.346C41.6085 23.913 42.2233 23.7052 42.8295 23.7572L45.1242 23.9996C45.765 24.0689 46.3972 23.8438 46.8562 23.3848L49.125 21.116C49.4713 20.7696 49.6878 20.3193 49.7398 19.8344L50.1554 16.0242C50.1901 15.6951 49.7917 15.5133 49.5579 15.7384L46.3799 18.9165C45.843 19.4447 44.977 19.4447 44.4488 18.9165L43.0546 17.5136C42.5264 16.9854 42.5264 16.1194 43.0546 15.5825L46.2327 12.4045C46.4665 12.1707 46.276 11.781 45.9469 11.8156L42.1367 12.2226C41.6518 12.2833 41.1928 12.4997 40.8551 12.8375L38.5863 15.1063C38.1273 15.5652 37.9022 16.1974 37.9714 16.8382L38.2053 19.1416C38.2745 19.7478 38.0494 20.354 37.6251 20.7869L26.1598 32.2435C25.7355 32.6765 25.1207 32.8843 24.5145 32.8324L22.2197 32.5899C21.5789 32.5206 20.9468 32.7458 20.4878 33.2047L18.219 35.4735C17.8726 35.8199 17.6561 36.2702 17.6042 36.7552L17.1885 40.5654C17.1539 40.8944 17.5522 41.0763 17.786 40.8511L20.9641 37.6731C21.501 37.1448 22.3669 37.1448 22.8952 37.6731L24.2894 39.0759C24.8176 39.6041 24.8176 40.4701 24.2894 41.007L21.1113 44.1851C20.8775 44.4189 21.068 44.8085 21.3971 44.7739L25.2073 44.3669C25.6922 44.3063 26.1512 44.0898 26.4889 43.7521L28.7577 41.4833C29.2166 41.0243 29.4418 40.3922 29.3725 39.7514L29.1387 37.4479C29.0694 36.8417 29.2946 36.2356 29.7189 35.8026L41.1841 24.346Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2612 14.8113H29.8228"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2612 20.5703H27.3375"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2612 26.3291H24.9735"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SubsectionsHomeownerServiceHistory;
