import React from 'react';

export interface RoomsGameroomProps {
  color: string;
  size?: number;
}

const RoomsGameroom: React.FC<RoomsGameroomProps> = ({ color, size = 24 }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_36897)">
        <path
          d="M11.1781 8.9082L10.0877 9.36254L3.3196 12.439C3.12496 12.5274 3 12.7215 3 12.9353V13.0592C3 13.273 3.12496 13.4671 3.3196 13.5555L11.3347 17.1988C11.8125 17.416 12.361 17.416 12.8388 17.1988L20.8539 13.5555C21.0486 13.4671 21.1735 13.273 21.1735 13.0592V12.9353C21.1735 12.7215 21.0486 12.5274 20.8539 12.439L14.0859 9.36254L12.9954 8.9082"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M21.1735 12.9961V16.7326C21.1735 16.9472 21.0477 17.1417 20.852 17.2298L12.8325 20.8386C12.3583 21.052 11.8153 21.052 11.341 20.8386L3.32147 17.2298C3.12582 17.1417 3 16.9472 3 16.7326V12.9961"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M12.0864 8.45206C10.5808 8.45206 9.36035 7.23157 9.36035 5.72603C9.36035 4.22049 10.5808 3 12.0864 3C13.592 3 14.8124 4.22049 14.8124 5.72603C14.8124 7.23157 13.592 8.45206 12.0864 8.45206Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.1787 8.45312V12.9965C11.1787 13.4984 11.6264 13.9052 12.1787 13.9052C12.731 13.9052 13.1787 13.4984 13.1787 12.9965V8.45312"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M15.7217 12.9961H16.6304"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_36897">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoomsGameroom;
