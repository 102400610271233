import React from 'react';

export interface NavigationalHiddenProps {
  color: string;
  size?: number;
}

const NavigationalHidden: React.FC<NavigationalHiddenProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M14.3907 12.9278C14.2127 13.9024 13.4287 14.6761 12.4387 14.8523C12.0407 14.9242 11.7767 15.2992 11.8487 15.691C11.9127 16.0385 12.2217 16.2816 12.5677 16.2816C12.6117 16.2816 12.6557 16.2777 12.6997 16.2708C14.2877 15.9863 15.5467 14.746 15.8307 13.1828C15.9027 12.79 15.6377 12.4159 15.2397 12.346C14.8447 12.2751 14.4617 12.538 14.3907 12.9278Z"
        fill={color}
      />
      <path
        d="M21.9401 12.2142C21.4071 11.0044 20.7711 9.90973 20.0471 8.96373C19.8031 8.64577 19.3451 8.58178 19.0231 8.82099C18.6991 9.06118 18.6351 9.51204 18.8781 9.82999C19.4751 10.6106 20.0091 11.5074 20.4681 12.5007C18.5921 16.5662 15.4451 18.9829 12.0001 18.9829C11.2201 18.9829 10.4441 18.8579 9.69407 18.6118C9.30907 18.4887 8.89607 18.6895 8.76907 19.0676C8.64107 19.4446 8.84807 19.8521 9.23107 19.9791C10.1301 20.2744 11.0621 20.424 12.0001 20.424C16.1271 20.424 19.8431 17.5683 21.9401 12.7851C22.0201 12.604 22.0201 12.3963 21.9401 12.2142Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.56112 12.5007C9.56112 11.1757 10.6551 10.0998 12.0001 10.0998C12.3951 10.0998 12.7771 10.2041 13.1191 10.3804L9.84712 13.6013C9.66712 13.2636 9.56112 12.8915 9.56112 12.5007ZM20.4201 4.21115C20.1341 3.92962 19.6711 3.92962 19.3851 4.21115L17.2771 6.28724C13.8511 3.94734 9.51912 4.07432 6.22112 6.64261C4.51912 7.96071 3.08112 9.88816 2.06013 12.2153C1.98013 12.3964 1.98013 12.6031 2.05913 12.7842C2.53412 13.874 3.10112 14.8771 3.74312 15.766C3.88612 15.9648 4.11112 16.0692 4.34012 16.0692C4.48712 16.0692 4.63512 16.0259 4.76412 15.9353C5.09312 15.7049 5.17012 15.2551 4.93612 14.9312C4.41012 14.2028 3.93812 13.3867 3.53212 12.5017C4.44512 10.5211 5.68412 8.89096 7.12712 7.77368C9.85912 5.64443 13.3561 5.48693 16.2241 7.32381L14.1901 9.32508C13.5531 8.89785 12.7871 8.65963 12.0001 8.65963C9.84811 8.65963 8.09812 10.3823 8.09812 12.5007C8.09812 13.2814 8.33712 14.0246 8.77612 14.6546L3.58012 19.7705C3.29412 20.051 3.29412 20.5068 3.58012 20.7884C3.72312 20.9291 3.91012 21 4.09812 21C4.28512 21 4.47212 20.9291 4.61512 20.7884L20.4201 5.23C20.7061 4.94846 20.7061 4.49269 20.4201 4.21115Z"
        fill={color}
      />
    </svg>
  );
};

export default NavigationalHidden;
