export interface HomeRoofV2Props {
  color: string;
  size?: number;
}

const HomeRoofV2 = ({ color, size }: HomeRoofV2Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill={color}
      d="M4 13.954V11.06l7.956-5.181 4.022 2.59V6.132h-.593V4.354h3.648v1.778h-.572v3.936L20 11.06v2.895l-8.044-5.282L4 13.954Z"
    />
    <path
      stroke={color}
      strokeWidth={0.517}
      d="M6.805 14.213h.517v.763h-.517zM16.678 14.213h.517v.763h-.517zM6.805 16.79h.517v.763h-.517zM6.805 19.625h.517v.763h-.517zM16.678 16.79h.517v.763h-.517zM16.678 19.625h.517v.763h-.517z"
    />
  </svg>
);
export default HomeRoofV2;
