import React from 'react';

export interface RisksVolcanoSmProps {
  color: string;
  size?: number;
}

const RisksVolcanoSm: React.FC<RisksVolcanoSmProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M7.92493 13.2915V15.2645C7.92493 16.1103 8.61062 16.796 9.45646 16.796H9.68619C10.4052 16.796 10.988 16.2132 10.988 15.4942V15.2435C10.988 14.663 11.4586 14.1924 12.039 14.1924V14.1924C12.6195 14.1924 13.0901 14.663 13.0901 15.2435V17.8699C13.0901 18.6915 13.7561 19.3576 14.5777 19.3576V19.3576C15.3866 19.3576 16.0472 18.7113 16.065 17.9026L16.1532 13.8921"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M10.2071 10.4084V8.28528H8.34525C7.51599 8.28528 6.84375 7.61304 6.84375 6.78378C6.84375 5.95453 7.51599 5.28228 8.34525 5.28228H9.03028C9.20603 3.99327 10.3113 3 11.6486 3C12.9858 3 14.0911 3.99327 14.2668 5.28228H14.7116C15.5409 5.28228 16.2131 5.95453 16.2131 6.78378C16.2131 7.61304 15.5409 8.28528 14.7116 8.28528H12.9699V10.4084"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M3.5 19.4995L3 21.9998H21.0781L20.4775 19.1169L18.2553 18.2761L14.5916 10.3481H9.06607L8.04504 13.5313L5.52252 16.0539L5.16216 18.2761L3.5 19.4995ZM3.5 19.4995H8.04504"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default RisksVolcanoSm;
