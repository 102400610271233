'use client';

import { colors } from '@propertylens/app-commons/dist/tailwindBaseConfig';
import useResizeObserver from '@react-hook/resize-observer';
import { RemixiconComponentType } from '@remixicon/react';
import cx from 'classnames';
import React, { useLayoutEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import Icons from '../Icons';
import { IconNameType } from '../Icons/Icon';
import SectionHeading from '../SectionHeading';

type SectionHeadingGroupProps = {
  className?: string;
  color?: string;
  description?: string;
  hasUnderline?: boolean;
  heading?: string;
  icon?: IconNameType | React.ReactNode | RemixiconComponentType;
  iconDesktop?: IconNameType | React.ReactNode | RemixiconComponentType;
  level?: 1 | 2 | 3 | 4 | 5 | 6 | 'title';
  classNames?: {
    base?: string;
    heading?: string;
    headingBase?: string;
    icon?: string;
    description?: string;
  };
  tooltip?: string;
  alwaysVisible?: boolean;
  slot?: string;
};

const useSize = (target: any) => {
  const [size, setSize] = useState<number | null>();

  const debounced = useDebouncedCallback((value) => {
    setSize(value);
  }, 250);

  useLayoutEffect(() => {
    if (target) {
      const width = target.ownerDocument?.defaultView?.innerWidth;
      debounced(width);
    }
  }, [target]);

  if (typeof window !== 'undefined') {
    // Where the magic happens
    useResizeObserver(target, (entry) => {
      const width = entry.target?.ownerDocument?.defaultView?.innerWidth;
      return debounced(width);
    });
  }

  return size;
};

const SectionHeadingGroup: React.FC<SectionHeadingGroupProps> = ({
  className,
  classNames = {
    base: '',
    heading: '',
    icon: '',
    description: '',
  },
  color = 'pl-dark',
  description,
  hasUnderline = false,
  heading,
  icon,
  iconDesktop,
  level = 3,
  tooltip,
  alwaysVisible,
}) => {
  const [target, setTarget] = useState<HTMLDivElement | null>();
  const size = useSize(target);

  const getIconNode = () => {
    if (size && size > 1024) {
      return iconDesktop || icon;
    }

    return icon;
  };

  const iconSize = size && size < 1024 ? 'lg' : 'xl';
  // @ts-ignore
  const iconColor = color ? colors[color] : colors['pl-dark'];
  const iconNode = getIconNode();

  const renderIcon = () => {
    if (!icon) return null;

    if (
      (icon && typeof icon === 'string' && iconSize === 'lg') ||
      (iconDesktop && typeof iconDesktop === 'string' && iconSize === 'xl')
    ) {
      return (
        <Icons
          icon={iconNode as IconNameType}
          size={iconSize}
          color={iconColor}
        />
      );
    }

    let Icon = iconNode as RemixiconComponentType;

    return <Icon size={iconSize === 'lg' ? 32 : 48} color={iconColor} />;
  };

  const containerCx = cx(
    'SectionHeadingGroup flex items-center gap-1 lg:gap-3 mb-4 lg:mb-8',
    {
      [`${className}`]: className,
      [`${classNames.base}`]: classNames.base,
      [`icon-${icon}`]: icon && typeof icon === 'string',
      [`icon-${iconDesktop}`]: iconDesktop && typeof iconDesktop === 'string',
    }
  );

  const iconContainerCx = cx('items-center flex', {
    'min-w-6 m-h-6': iconSize === 'lg',
    'min-h-12 min-w-12': iconSize === 'xl',
    [`${classNames.icon}`]: classNames.icon,
  });

  const descriptionCx = cx('font-raleway hidden lg:!block font-semibold', {
    ['text-xl/[1.15]']: level === 1,
    ['text-base/[1.1]']: level === 2,
    ['text-sm']: level === 3,
    [`text-${color}`]: color,
    [`${classNames.description}`]: classNames.description,
    ['!block']: alwaysVisible,
  });

  return (
    <div className={containerCx} ref={setTarget}>
      {icon && <div className={iconContainerCx}>{renderIcon()}</div>}
      <div>
        <SectionHeading
          classNames={{
            base: classNames.headingBase,
            heading: `${classNames.heading} !mb-0 !lg:mb-1`,
          }}
          color={color}
          level={level}
          hasUnderline={hasUnderline}
          tooltip={tooltip}
        >
          {heading}
        </SectionHeading>
        {description && <div className={descriptionCx}>{description}</div>}
      </div>
    </div>
  );
};

export default SectionHeadingGroup;
