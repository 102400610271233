import './FeaturesList.scss';

import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { BREAKPOINTS } from '../../constants';
import AnimateChangeInHeight from '../../global/AnimateChangeInProps';
import formatValue from '../DetailedList/formatValue';
// import useSize from '../../utils/useSize';
import Icon from '../Icons';
import { IconNameType } from '../Icons/Icon';
import { GradeType, ListValueType } from '../../types';
import type { ListValueEnumType } from '../DetailedList/DetailedList';

type FeaturesListProps = {
  list: {
    icon?: IconNameType;
    id: string;
    label: string | number;
    value: ListValueType;
    type?: ListValueEnumType;
    grade?: GradeType;
  }[];
};
const FeaturesList: React.FC<FeaturesListProps> = (props) => {
  const { list } = props;
  const [storedList, setStoredList] = useState(list.slice(0, 3));
  const [isDesktop, setIsDesktop] = useState(false);
  const [showAll, setShowAll] = useState(storedList.length === list.length);
  // const windowSize = useSize();
  const windowSize = [1024, 1024];

  useEffect(() => {
    setStoredList(list);
  }, [list]);

  useEffect(() => {
    if (windowSize[0] > BREAKPOINTS.md) {
      setIsDesktop(true);
      setStoredList(list);
      setShowAll(true);
    }
  }, []);

  useEffect(() => {
    if (windowSize[0] > BREAKPOINTS.sm && !isDesktop && !showAll) {
      setIsDesktop(true);
      setStoredList(list);
      setShowAll(true);
    }
  });

  function handleShowMore() {
    setStoredList(list);
    setShowAll(true);
  }

  function handleShowLess() {
    setStoredList(list.slice(0, 3));
    setShowAll(false);
  }

  return (
    <div className="FeaturesList">
      <AnimateChangeInHeight>
        <div className="list-grouping mt-2">
          <ul>
            {storedList.map((item) => {
              const valueCx = cx('value', {
                'grayed-out': item.value === undefined || item.value === null,
              });
              return (
                <li className="list-item" key={item.id}>
                  <div className="icon">
                    <Icon icon={item.icon as IconNameType} size="md" />
                  </div>
                  <div className="label">{item.label}</div>
                  <div className={valueCx}>
                    {formatValue({
                      grade: item.grade,
                      type: item.type,
                      value: item.value,
                    })}
                  </div>
                </li>
              );
            })}
          </ul>
          {!isDesktop && (
            <div className="show-more">
              {showAll ? (
                <button
                  type="button"
                  onClick={() => {
                    handleShowLess();
                  }}
                >
                  Show less
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    handleShowMore();
                  }}
                >
                  Show more
                </button>
              )}
            </div>
          )}
        </div>
      </AnimateChangeInHeight>
    </div>
  );
};

export default FeaturesList;
