import React from 'react';

export interface OutdoorsFrontyardProps {
  color: string;
  size?: number;
}

const OutdoorsFrontyard: React.FC<OutdoorsFrontyardProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_6409_68286)">
        <g clipPath="url(#clip1_6409_68286)">
          <path
            d="M6.17 8.93C6.72 9.21 7.41 9.37 8.17 9.37C8.93 9.37 9.63 9.2 10.17 8.93M13.58 8.93C14.13 9.21 14.82 9.37 15.58 9.37C16.34 9.37 17.04 9.2 17.58 8.93M10.17 20.13C9.62 19.85 8.93 19.69 8.17 19.69C7.41 19.69 6.71 19.86 6.17 20.13M17.59 20.13C17.04 19.85 16.35 19.69 15.59 19.69C14.83 19.69 14.13 19.86 13.59 20.13M4.46 23.31C3.52 23.31 2.75 22.55 2.75 21.6V7.46C2.75 6.52 3.51 5.75 4.46 5.75C5.41 5.75 6.17 6.51 6.17 7.46V21.61C6.17 22.55 5.41 23.32 4.46 23.32V23.31ZM13.58 21.6V7.46C13.58 6.52 12.82 5.75 11.87 5.75C10.92 5.75 10.16 6.51 10.16 7.46V21.61C10.16 22.55 10.92 23.32 11.87 23.32C12.82 23.32 13.58 22.56 13.58 21.61V21.6ZM21 21.6V7.46C21 6.52 20.24 5.75 19.29 5.75C18.34 5.75 17.58 6.51 17.58 7.46V21.61C17.58 22.55 18.34 23.32 19.29 23.32C20.24 23.32 21 22.56 21 21.61V21.6Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6409_68286">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_6409_68286">
          <rect
            width="19.75"
            height="19.06"
            fill="white"
            transform="translate(2 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OutdoorsFrontyard;
