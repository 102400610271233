import './SpotlightScoreCard.scss';

import cx from 'classnames';
import React from 'react';

import RadialScore from './RadialScore';
import { RatingType } from './type';
import { colors } from '@propertylens/app-commons/dist/tailwindBaseConfig';

type SpotlightScoreCardProps = {
  value: number;
  total: number;
};

type LevelMap = Record<RatingType, string>;

const SpotlightScoreCard: React.FC<SpotlightScoreCardProps> = (props) => {
  const { value, total } = props;

  const rating = (value / total) * 100;

  const levelMap: LevelMap = {
    'very-high': colors['pl-rating-red'],
    high: colors['pl-rating-orange'],
    moderate: colors['pl-rating-orange'],
    low: colors['pl-rating-light-green'],
    'very-low': colors['pl-rating-green'],
  };

  let ratingType: RatingType = 'very-high';
  if (rating > 0 && rating <= 60) {
    ratingType = 'very-high';
  } else if (rating > 60 && rating <= 70) {
    ratingType = 'high';
  } else if (rating > 70 && rating <= 80) {
    ratingType = 'moderate';
  } else if (rating > 80 && rating <= 90) {
    ratingType = 'low';
  } else if (rating > 90) {
    ratingType = 'very-low';
  }
  const circleRadius = 70;

  const divCx = cx('SpotlightScoreCard', ratingType);
  return (
    <div className={divCx}>
      <RadialScore
        circleRadius={circleRadius}
        level={levelMap[ratingType]}
        rating={rating}
        value={value}
        total={total}
      />
      <div className="score-label">Spotlight Index Score</div>
    </div>
  );
};

export default SpotlightScoreCard;
