import React from 'react';

export interface RisksEarthquakeLgProps {
  color: string;
  size?: number;
}

const RisksEarthquakeLg: React.FC<RisksEarthquakeLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M6.06641 31.123L9.13244 34.8966L13.6136 32.3023L15.7362 35.84"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M5.35938 47.9907V30.1794L19.7462 20.0379V12.7266H38.1424V43.3681"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M22.8125 12.4904V8.24512H35.0767V12.4338"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M28.9414 8.24574V4.70801"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M20 34H37.4528M37.4528 39.1321H20"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M20 29H37.4528"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M19.9805 12.9053V48.226"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect
      x="26.6406"
      y="44.1514"
      width="4.60377"
      height="5.07547"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M19.982 48.2268V43.1514H5.35938V48.2268"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M38.1415 17.208L32.0094 20.5099L28 19.3306"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M32.0078 21.2168V23.5753"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M45.9745 49.4814C47.3403 48.7083 47.3004 48.2882 47.475 46.8422C47.6511 45.3849 46.6084 41.3298 45.9745 42.2574C44.8554 43.8948 44.2133 43.8948 44.2133 43.8948C44.2133 43.8948 44.4784 41.2749 41.4686 38C41.4696 38.8239 41.3479 40.1503 39.9946 41.9299C38.6413 43.7094 37.0078 44.179 37.0111 46.8978C37.0119 47.5667 37.2223 48.199 37.5849 48.7603"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="4"
      y1="49.2266"
      x2="52"
      y2="49.2266"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default RisksEarthquakeLg;
