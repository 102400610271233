'use client';

import { ModalHeader as NextModalHeader } from '@nextui-org/react';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

const ModalHeader: React.FC<Props> = ({ children }: Props) => {
  return (
    <>
      <NextModalHeader className="mb-3 flex flex-col gap-1 border-b-1 p-0 pb-2.5 lg:mb-7">
        {children}
      </NextModalHeader>
    </>
  );
};

export default ModalHeader;
