import React from 'react';

export interface RisksSinkholeLgProps {
  color: string;
  size?: number;
}

const RisksSinkholeLg: React.FC<RisksSinkholeLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M46.3855 8.23988L42.0362 44.2399C41.9148 45.2444 41.0624 46 40.0506 46H11.7639C10.402 46 9.43837 44.6683 9.86423 43.3746L21.7149 7.37464C21.9849 6.55433 22.751 6 23.6146 6H44.3999C45.5996 6 46.5294 7.04885 46.3855 8.23988Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.9414 46L41.8826 6"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8826 46L26.9414 6"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 45.7317L20.4844 40.9954L20.2025 37.6829L24.5534 37.2347L23.9036 32.1781L27.8006 31.5539L28.0507 24.1769L31.5097 28.7214L35.7667 27.1691L32.3395 33.314L29.8041 34.4662L33.897 38.7227L28.7323 39.923L28.5683 45.7317"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.7636 6L32.9989 8.94118L35.3518 10.7059L33.5871 12.4706L31.2341 17.7647H28.293"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.2344 17.7646L33.5873 21.2941"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksSinkholeLg;
