import React from 'react';

export interface RisksHurricaneLgProps {
  color: string;
  size?: number;
}

const RisksHurricaneLg: React.FC<RisksHurricaneLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M23.7319 12.0802C25.9591 10.8234 28.7618 9.73902 32.0067 9.49989C37.6622 9.0856 44.6218 11.2294 52.1403 19.5069C52.5268 19.9324 52.4963 20.5913 52.0708 20.9778C51.651 21.3615 51.0003 21.3337 50.6111 20.9222C50.1745 20.5274 46.5849 17.4216 39.756 16.1648C41.9553 17.4633 44.291 19.3429 46.1178 22.0288C49.3098 26.7196 50.9364 33.8265 47.5247 44.4815H47.5275C47.3523 45.0292 46.7656 45.3323 46.2179 45.1571C45.6812 44.9847 45.3809 44.4203 45.5339 43.8836C45.6451 43.3776 46.5765 38.6897 44.2326 32.0915C44.2076 34.6468 43.746 37.6081 42.3307 40.5359C39.8616 45.6437 34.523 50.6041 23.5956 52.9759C23.034 53.0983 22.4779 52.7424 22.3555 52.1807C22.2332 51.619 22.5891 51.0629 23.1535 50.9406C23.7958 50.732 28.228 49.1499 32.6796 43.9226C30.4524 45.1794 27.6524 46.2638 24.4048 46.5029C18.7492 46.9172 11.7896 44.7734 4.27111 36.4958C3.88462 36.0704 3.9152 35.4114 4.34062 35.0249C4.76048 34.6412 5.41112 34.6691 5.79761 35.0806C6.23415 35.4754 9.82378 38.5812 16.6527 39.838C13.5969 38.0335 10.2798 35.1084 8.43075 30.4622C6.62898 25.9355 6.23415 19.7989 8.88397 11.5213H8.88119C9.05636 10.9736 9.64305 10.6705 10.1908 10.8456C10.7275 11.018 11.0277 11.5825 10.8748 12.1191C10.7636 12.6252 9.83213 17.3131 12.1761 23.9113C12.2039 21.356 12.6627 18.3947 14.078 15.4669C16.5471 10.3563 21.8856 5.39863 32.8158 3.02408C33.3775 2.90173 33.9336 3.25764 34.0559 3.8193C34.1783 4.38096 33.8224 4.93705 33.2579 5.05939C32.6156 5.26793 28.1835 6.85007 23.7319 12.0774V12.0802Z"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M52.6024 10.9746C48.7552 6.76758 45.6452 5.51126 41.0002 3.87598"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M3.99999 44.5975C7.73921 48.9007 10.8162 50.2356 15.4182 51.9883"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="28.0002" cy="28" r="5" stroke={color} strokeWidth="2" />
    <circle cx="28.0002" cy="28" r="10" stroke={color} strokeWidth="2" />
  </svg>
);

export default RisksHurricaneLg;
