import React from 'react';

export interface FloodRiskLgProps {
  color: string;
  size?: number;
}

const FloodRiskLg: React.FC<FloodRiskLgProps> = ({ color, size = 56 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clipPath="url(#clip0_6501_42265)">
      <path
        d="M3.87921 50.1929C6.89486 50.1929 6.89486 51.8458 9.91051 51.8458C12.9262 51.8458 12.9262 50.1929 15.9418 50.1929C18.9575 50.1929 18.9575 51.8458 21.9731 51.8458C24.9888 51.8458 24.9888 50.1929 28.0044 50.1929C31.0201 50.1929 31.0201 51.8458 34.0269 51.8458C37.0338 51.8458 37.0426 50.1929 40.0582 50.1929C43.0739 50.1929 43.0739 51.8458 46.0983 51.8458C49.1228 51.8458 49.114 50.1929 52.1384 50.1929"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.7065 13.3809C46.8632 17.8736 48.6919 25.5754 48.6919 25.5754C43.6014 20.7926 38.2822 24.9688 38.2822 24.9688C32.4883 19.9398 27.8813 24.9688 27.8813 24.9688C27.8813 24.9688 23.2743 19.9398 17.4716 24.9688C17.4716 24.9688 12.1524 20.7926 7.07068 25.5754C7.07068 25.5754 10.7193 10.2158 27.8813 10.2158C30.4046 10.2158 32.629 10.5499 34.5984 11.1214"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.0338 6.57617C37.0338 6.57617 30.7211 15.2011 37.1129 15.2011C41.377 15.2011 39.8648 10.005 37.0338 6.57617Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.626 12.8184C23.626 12.8184 19.0014 19.1398 23.6788 19.1398C26.8087 19.1398 25.6921 15.3329 23.626 12.8184Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.511 4.87915C15.511 4.87915 11.6074 10.2159 15.555 10.2159C18.1926 10.2159 17.2606 6.99802 15.511 4.87915Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.684 42.0602V23.9048"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0697 23.9141V42.0607"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.87921 44.6365C6.89486 44.6365 6.89486 46.2894 9.91051 46.2894C12.9262 46.2894 12.9262 44.6365 15.9418 44.6365C18.9575 44.6365 18.9575 46.2894 21.9731 46.2894C24.9888 46.2894 24.9888 44.6365 28.0044 44.6365C31.0201 44.6365 31.0201 46.2894 34.0269 46.2894C37.0338 46.2894 37.0426 44.6365 40.0582 44.6365C43.0739 44.6365 43.0739 46.2894 46.0983 46.2894C49.1228 46.2894 49.114 44.6365 52.1384 44.6365"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6501_42265">
        <rect
          width="50"
          height="48.734"
          fill="white"
          transform="translate(3 4)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default FloodRiskLg;
