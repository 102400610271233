import React from 'react';

export interface SystemsElectricityProps {
  color: string;
  size?: number;
}

const SystemsElectricity: React.FC<SystemsElectricityProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
    >
      <g clipPath="url(#clip0_5205_37097)">
        <path
          d="M8.5 19H15.5"
          stroke={color}
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.66699 22H14.3337"
          stroke={color}
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask id="path-3-inside-1_5205_37097" fill="white">
          <path d="M8.50096 17C8.50115 14.6667 7.91781 14.0833 6.75096 12.9167C5.58412 11.75 5.02826 10.568 5.00096 8.83333C4.94498 5.27534 7.33412 3 12.0009 3C16.6678 3 19.0569 5.27534 19.0009 8.83333C18.9736 10.568 18.4175 11.75 17.2509 12.9167C16.0845 14.0833 15.5012 14.6667 15.5009 17" />
        </mask>
        <path
          d="M6.75096 16.9999C6.75088 17.9664 7.53432 18.7499 8.50082 18.75C9.46732 18.7501 10.2509 17.9666 10.251 17.0001L6.75096 16.9999ZM6.75096 12.9167L5.51362 14.1542L5.51362 14.1542L6.75096 12.9167ZM5.00096 8.83333L3.25118 8.86086L3.25118 8.86087L5.00096 8.83333ZM19.0009 8.83333L17.2512 8.80579V8.8058L19.0009 8.83333ZM17.2509 12.9167L16.0134 11.6793L16.0134 11.6794L17.2509 12.9167ZM13.7509 16.9998C13.7508 17.9663 14.5343 18.7499 15.5008 18.75C16.4673 18.7501 17.2508 17.9667 17.2509 17.0002L13.7509 16.9998ZM10.251 17.0001C10.2511 15.7 10.0916 14.6637 9.62885 13.7382C9.18725 12.855 8.52791 12.2187 7.9883 11.6791L5.51362 14.1542C6.14086 14.7813 6.35662 15.02 6.49835 15.3035C6.61892 15.5446 6.75104 15.9666 6.75096 16.9999L10.251 17.0001ZM7.98831 11.6791C7.12097 10.8119 6.77042 10.0557 6.75074 8.8058L3.25118 8.86087C3.28611 11.0803 4.04727 12.6881 5.51362 14.1542L7.98831 11.6791ZM6.75074 8.8058C6.72937 7.44717 7.16073 6.52263 7.87373 5.90687C8.62656 5.2567 9.92788 4.75 12.0009 4.75V1.25C9.40718 1.25 7.18052 1.88097 5.58608 3.25797C3.9518 4.66937 3.21658 6.6615 3.25118 8.86086L6.75074 8.8058ZM12.0009 4.75C14.074 4.75 15.3754 5.2567 16.1282 5.90687C16.8412 6.52262 17.2725 7.44716 17.2512 8.80579L20.7507 8.86087C20.7853 6.66151 20.0501 4.66938 18.4159 3.25797C16.8214 1.88097 14.5947 1.25 12.0009 1.25V4.75ZM17.2512 8.8058C17.2315 10.0556 16.8808 10.8119 16.0134 11.6793L18.4884 14.154C19.9542 12.6881 20.7158 11.0805 20.7507 8.86087L17.2512 8.8058ZM16.0134 11.6794C15.474 12.2189 14.8149 12.8552 14.3734 13.7382C13.9107 14.6636 13.7511 15.6997 13.7509 16.9998L17.2509 17.0002C17.251 15.9669 17.3832 15.5448 17.5039 15.3035C17.6457 15.0198 17.8615 14.7811 18.4885 14.154L16.0134 11.6794Z"
          fill={color}
          mask="url(#path-3-inside-1_5205_37097)"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_37097">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SystemsElectricity;
