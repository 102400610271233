import React, { FC } from 'react';

export interface Props {
  color: string;
  size?: number;
}

const RisksDroughtSm: FC<Props> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19.7769 15.5557H5.55469C4.45012 15.5557 3.55469 16.4511 3.55469 17.5557V20.0001C3.55469 21.1047 4.45012 22.0001 5.55469 22.0001H19.7769C20.8815 22.0001 21.7769 21.1047 21.7769 20.0001V17.5557C21.7769 16.4511 20.8815 15.5557 19.7769 15.5557Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12.2227 17.7773L12.2227 9.3329C12.2227 7.73741 13.5161 6.44401 15.1115 6.44401V6.44401C16.707 6.44401 18.0004 7.73741 18.0004 9.3329L18.0004 9.99957"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12.2227 15.333L12.2227 4.88856C12.2227 3.29307 10.9293 1.99967 9.33377 1.99967V1.99967C7.73828 1.99967 6.44488 3.29307 6.44488 4.88856L6.44488 5.55523"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M19.0282 11.7779C19.0282 12.7558 18.4205 13.2501 18.0004 13.2501C17.5803 13.2501 16.9727 12.7558 16.9727 11.7779C16.9727 10.8 17.5803 10.3057 18.0004 10.3057C18.4205 10.3057 19.0282 10.8 19.0282 11.7779Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M5.55701 7.33252C4.86552 8.02401 4.0863 7.94385 3.78924 7.64679C3.49219 7.34973 3.41203 6.57051 4.10351 5.87902C4.795 5.18754 5.57422 5.2677 5.87128 5.56475C6.16834 5.86181 6.2485 6.64103 5.55701 7.33252Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M6.76721 7.33252C7.45869 8.02401 8.23792 7.94385 8.53498 7.64679C8.83203 7.34973 8.91219 6.57051 8.22071 5.87902C7.52922 5.18754 6.75 5.2677 6.45294 5.56475C6.15588 5.86181 6.07572 6.64103 6.76721 7.33252Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M9.36719 16.1963L8.49029 17.5879C7.94446 18.4542 7.58741 19.4257 7.44244 20.4393V20.4393"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M5.55717 18.2855L7.93792 18.7515"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M16.6654 15.7773L19.332 18.444L15.332 19.7773L17.5543 21.9996"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default RisksDroughtSm;
