import { Autocomplete, AutocompleteItem } from '@nextui-org/react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import {
  SelectedPlace,
  isPlaceValid,
  emitTrackingEvent,
} from '@propertylens/app-commons';
import { useEffect, useState, FC } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useModalContext } from '../../hooks/useModal';
import { useMyReportsContext } from '../../hooks/useMyReports';

type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};
type Props = {
  googleAPIKey: string;
  inputLabel?: string;
  placeholder?: string;
  size?: 'sm' | 'md' | 'lg' | undefined;
  classNames?: {
    input?: string;
    inputWrapper?: string;
  };
  parentComponent: string;
};

const autocompleteFilter = (textValue: string, inputValue: string) => {
  if (inputValue.length === 0) {
    return true;
  }

  // Normalize both strings so we can slice safely
  // take into account the ignorePunctuation option as well...
  textValue = textValue
    .normalize('NFC')
    .toLocaleLowerCase()
    .replaceAll(',', '');
  inputValue = inputValue
    .normalize('NFC')
    .toLocaleLowerCase()
    .replaceAll(',', '');

  const isMatched = textValue.includes(inputValue);

  return isMatched;
};

const AddressLookup: FC<Props> = ({
  googleAPIKey,
  inputLabel = 'Search a property address',
  placeholder,
  size = undefined,
  classNames = {
    input: '',
    inputWrapper: '',
  },
  parentComponent,
}: Props) => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: googleAPIKey,
    debounce: 500,
    options: {
      componentRestrictions: { country: 'us' },
    },
  });
  const {
    selectedPlace,
    setSelectedPlace,
    setValidPlace,
    addressInputError,
    setAddressInputError,
    autocompleteInputValue,
    setAutocompleteInputValue,
    selectedAutocompleteValue,
    setSelectedAutocompleteValue,
    shouldBePopulated,
    setShouldBePopulated,
    setExistingReport,
    userCoordinates,
    setIsAllowed,
    setWarning,
  } = useModalContext();
  const { reports } = useMyReportsContext();

  useEffect(() => {
    if (shouldBePopulated) {
      getPlacePredictions({
        input: autocompleteInputValue,
        origin: userCoordinates,
      });
      setShouldBePopulated(false);
    }
  }, [shouldBePopulated]);

  const [internalPlacePredications, setInternalPlacePredications] =
    useState<any>(shouldBePopulated ? placePredictions : []);

  const checkIfReportExists = (gmapsPlaceId: string) => {
    const matchingReport = reports.find((item) => {
      return item.gmaps_place_id === gmapsPlaceId;
    });

    if (!matchingReport) {
      setExistingReport(null); // No matching report found
      return null;
    }

    const reportCreateDaysAgoDuration = formatDistanceToNowStrict(
      matchingReport.report_body.created_dt,
      {
        unit: 'day',
        addSuffix: false,
      }
    );

    const daysValue = reportCreateDaysAgoDuration.split(' ')[0];

    if (parseInt(daysValue) <= 30) {
      setExistingReport(matchingReport);
    }
  };

  const onInputChange = (value: string) => {
    if (addressInputError && !shouldBePopulated) setAddressInputError(false);
    setAutocompleteInputValue(value);
    if (value) getPlacePredictions({ input: value, origin: userCoordinates });
    if (shouldBePopulated) setShouldBePopulated(false);
  };

  const onSelectionChange = (item: any) => {
    if (item) {
      placesService?.getDetails(
        {
          placeId: item,
        },
        (placeDetails: {
          geometry: any;
          address_components: AddressComponent[];
        }) => {
          const address: SelectedPlace = {
            gmapsPlaceId: item as string,
            latitude: placeDetails.geometry.location.lat(),
            longitude: placeDetails.geometry.location.lng(),
            address1: '',
            address2: '',
            locality: '',
            state: '',
            zipCode: '',
          };
          let streetNumber = '';
          let route = '';
          const sublocalityLevels = [
            'sublocality_level_1',
            'sublocality_level_2',
            'sublocality_level_3',
            'sublocality_level_4',
            'sublocality_level_5',
          ];
          placeDetails?.address_components?.forEach(
            (addressComponent: AddressComponent) => {
              const { long_name, short_name, types } = addressComponent;

              if (types.includes('street_number')) {
                streetNumber = long_name;
              }

              if (types.includes('subpremise')) {
                address['address2'] = long_name;
              }

              if (types.includes('route')) {
                route = long_name;
              }

              if (types.includes('locality')) {
                address['locality'] = long_name;
              }

              if (types.includes('administrative_area_level_1')) {
                address['state'] = short_name;
              }

              if (types.includes('postal_code')) {
                address['zipCode'] = long_name;
              }
            }
          );

          if (streetNumber && route) {
            address.address1 = `${streetNumber} ${route}`;
          }

          if (!address.locality) {
            const sublocalityComponent = placeDetails.address_components.find(
              ({ types }) =>
                types.includes('sublocality') ||
                sublocalityLevels.some((level) => types.includes(level))
            );

            const neighborhoodFallback = placeDetails.address_components.find(
              ({ types }) => types.includes('neighborhood')
            );

            if (sublocalityComponent) {
              address.locality = sublocalityComponent.long_name;
            } else if (neighborhoodFallback) {
              address.locality = neighborhoodFallback.long_name;
            } else {
              emitTrackingEvent('Address Error', {
                address: {
                  ...address,
                },
                reason: 'NO_LOCALITY',
                validationResult: null,
              });
              setWarning({
                message: (
                  <div>
                    <p className="font-semibold">
                      We couldn't find a city for this address.
                    </p>
                    <p>Please double check the address and try again.</p>
                  </div>
                ),
                type: 'warning',
              });
              setIsAllowed(false);
              return;
            }
          }
          if (address.state === 'HI') {
            setWarning({
              message: (
                <div>
                  <p className="font-semibold">
                    Aerial imagery may be limited for Hawaii.
                  </p>
                  <p>
                    We are constantly striving to improve our roof condition
                    data, which is limited to the major population areas of the
                    state.
                  </p>
                </div>
              ),
              type: 'info',
            });
            setIsAllowed(true);
          } else if (address.state === 'AK') {
            emitTrackingEvent('Address Error', {
              address: {
                ...address,
              },
              reason: 'UNSUPPORTED_STATE',
              validationResult: null,
            });
            // Don't let properties from Alaska proceed
            setWarning({
              message: (
                <div>
                  <p>
                    We&apos;re sorry - PropertyLens is not yet available in
                    Alaska.
                  </p>
                </div>
              ),
              type: 'warning',
            });
            setIsAllowed(false);
          } else {
            setIsAllowed(true);
            setWarning({ message: null, type: 'info' });
          }
          setSelectedAutocompleteValue(item);
          setSelectedPlace(address);
          setValidPlace(isPlaceValid(address));
        }
      );
    }
    checkIfReportExists(item);
  };

  useDeepCompareEffect(() => {
    // added the additional shouldbepop check ensure this works on plinapp
    if (!autocompleteInputValue && !shouldBePopulated) {
      setInternalPlacePredications([]);
    } else {
      setInternalPlacePredications(placePredictions);
    }
  }, [placePredictions, autocompleteInputValue]);

  return (
    <Autocomplete
      allowsCustomValue={true}
      allowsEmptyCollection={true}
      {...(shouldBePopulated ? { selectedKey: selectedAutocompleteValue } : {})}
      defaultFilter={autocompleteFilter}
      items={internalPlacePredications}
      defaultItems={internalPlacePredications}
      isLoading={isPlacePredictionsLoading}
      {...(size ? { size: size } : {})}
      inputProps={{
        classNames: {
          input: classNames?.input,
          inputWrapper: classNames?.inputWrapper,
        },
      }}
      classNames={{
        selectorButton: 'invisible pointer-events-none',
      }}
      label={inputLabel}
      name="address"
      onInputChange={onInputChange}
      onSelectionChange={onSelectionChange}
      onClick={() =>
        emitTrackingEvent('Address Search Clicked', {
          component: parentComponent,
        })
      }
      variant="bordered"
      menuTrigger="input"
      {...(placeholder ? { placeholder: placeholder } : {})}
    >
      {(item: any) => (
        <AutocompleteItem key={item.place_id}>
          {item.description}
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
};

export default AddressLookup;
