import './SpotlightScoreHorizontal.scss';

import React from 'react';

import getSpotlightScore, {
  levelMap,
  RatingType,
} from '../../utils/spotlightScoreUtils';
import Icon from '../Icons';
import RadialScore from '../SpotlightScoreCard/RadialScore';
import Marker from './Marker';
import MarkerStateAverage from './MarkerStateAverage';
import { IconNameType } from '../Icons/Icon';

function isOdd(num: number) {
  return num % 2;
}

type Ratings = {
  id: string;
  value: number;
  icon?: IconNameType;
  label: string;
};
type SpotlightScoreHorizontalProps = {
  stateAverage: number;
  ratings: Ratings[];
};

const SpotlightScoreHorizontal: React.FC<SpotlightScoreHorizontalProps> = (
  props,
) => {
  const { stateAverage, ratings } = props;
  const [clientWidth, setClientWidth] = React.useState(100);

  return (
    <div className="SpotlightScoreHorizontal">
      <div className="timeline-wrapper">
        <MarkerStateAverage clientWidth={clientWidth} value={stateAverage} />
        <div className="marker-wrapper">
          {ratings.map((item, index) => {
            const score = getSpotlightScore(item.value);
            return (
              <Marker
                clientWidth={clientWidth}
                key={item.id}
                level={score}
                position={isOdd(index) ? 'bottom' : 'top'}
                value={item.value}
              />
            );
          })}
        </div>
        <div className="horizontal-line-wrapper">
          <div className="horizontal-line very-high" />
          <div className="horizontal-line high" />
          <div className="horizontal-line moderate" />
          <div className="horizontal-line low" />
        </div>
      </div>
      <div className="list-wrapper">
        {ratings.map((item) => {
          const ratingType: RatingType = getSpotlightScore(item.value);
          const rating = (item.value / 100) * 100;
          return (
            <div
              className="list-item border-dashed border-pl-gray-light py-2 [&+.list-item]:border-t-1"
              key={item.id}
            >
              {item.icon && <Icon icon={item.icon} size="md" />}
              <div className="label text-pl-blue-gray">{item.label}</div>
              <div className="value">
                <RadialScore
                  circleRadius={45}
                  level={levelMap[ratingType]}
                  rating={rating}
                  value={item.value}
                  size="sm"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SpotlightScoreHorizontal;
