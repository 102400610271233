import React from 'react';

export interface SubsectionsHealthSafetySmProps {
  color: string;
  size?: number;
}

const SubsectionsHealthSafetySm: React.FC<SubsectionsHealthSafetySmProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.3333 8.66667V2.47947C15.3333 2.21675 15.1166 2 14.8539 2H9.14614C8.88341 2 8.66667 2.21675 8.66667 2.47947V8.66667H2.47947C2.21675 8.66667 2 8.88342 2 9.14614V14.8539C2 15.1166 2.21675 15.3333 2.47947 15.3333H8.66667V21.5205C8.66667 21.7832 8.88341 22 9.14614 22H14.8539C15.1166 22 15.3333 21.7832 15.3333 21.5205V15.3333H21.5205C21.7832 15.3333 22 15.1166 22 14.8539V9.14614C22 8.88342 21.7832 8.66667 21.5205 8.66667H15.3333Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SubsectionsHealthSafetySm;
