import React from 'react';

export interface CommunicationQuestionProps {
  color: string;
  size?: number;
}

const CommunicationQuestion: React.FC<CommunicationQuestionProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_36240)">
        <path
          d="M15 9H9"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M12 12H9"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M8.58333 18C8.58333 18.2943 8.75544 18.5614 9.02342 18.683C9.2914 18.8046 9.60574 18.7582 9.82721 18.5644L12.8172 15.9482C13.0451 15.7488 13.3376 15.6389 13.6404 15.6389H16C17.5188 15.6389 18.75 14.4077 18.75 12.8889V8C18.75 6.48122 17.5188 5.25 16 5.25H8C6.48122 5.25 5.25 6.48122 5.25 8V12.8889C5.25 14.4077 6.48122 15.6389 8 15.6389H8.58333V18Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_36240">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CommunicationQuestion;
