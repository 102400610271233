import type { Theme } from 'theme-ui'

const makeTheme = <T extends Theme>(t: T) => t

const theme = makeTheme({
  breakpoints: ['40em', '52em', '64em'],
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
  fonts: {
    body: 'Raleway, -apple-system, Roboto, Helvetica, sans-serif',
    heading: 'Raleway, -apple-system, Roboto, Helvetica, sans-serif',
  },
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700,
  },
  colors: {
    'pl-main-brand': '#4494D0',
    'pl-blue-gray': '#767676',
    'pl-border-gray': '#E1E3EA',
    'pl-dark': '#050A30',
    'pl-gray': '#858585',
    'pl-gray-background': '#F9F9F9',
    'pl-gray-light': '#E0E0E0',
    'pl-gray-medium': '#979797',
    'pl-gray-white': '#FFFFFF',
    'pl-light-blue': '#DFF2FE',
    'pl-rating-green': '#3CC32E',
    'pl-rating-light-green': '#98DD60',
    'pl-rating-orange': '#FF7342',
    'pl-rating-red': '#FF1616',
    'pl-rating-yellow': '#FFC700',
    'pl-rating-secondary-blue': '#F0F9FF',
    'pl-rating-secondary-green': '#E3F6D4',
    'pl-rating-secondary-orange': '#FDEBE3',
    'pl-rating-secondary-red': '#FFF5F8',
    'pl-rating-secondary-yellow': '#FFF8DD',
    'pl-stytch-white': '#E5E7EB',
    'pl-blue-highlight': '#0A94EF',
    
  },
  buttons: {
    primary: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'inline-block',
      backgroundColor: 'pl-dark',
      border: '2px solid',
      borderColor: 'pl-dark',
      borderRadius: 'inherit',
      color: 'pl-gray-white',
      cursor: 'pointer',
      letterSpacing: '.5px',
      padding: '0 16px',
      '&:hover': {
        backgroundColor: 'pl-dark',
      },
      '&:disabled': {
        cursor: 'not-allowed',
        backgroundColor: 'pl-blue-gray',
        borderColor: 'pl-blue-gray',
      },
    },
    secondary: {
      display: 'inline-block',
      backgroundColor: 'pl-gray-white',
      border: '2px solid',
      borderColor: 'pl-dark',
      color: 'pl-dark',
      cursor: 'pointer',
      letterSpacing: '.5px',
      padding: '0 16px',
      '&:hover': {
        backgroundColor: 'pl-dark',
        color: 'pl-gray-white',
      },
      '&:disabled': {
        cursor: 'not-allowed',
        backgroundColor: 'pl-gray-light',
      },
    },
    text: {
      display: 'inline-block',
      backgroundColor: 'transparent',
      borderBottom: '2px solid',
      borderColor: 'pl-dark',
      borderRadius: 0,
      color: 'pl-dark',
      cursor: 'pointer',
      fontWeight: 'bold',
      letterSpacing: '.5px',
      padding: '0 0 2px',
    },
  },
  text: {
    body: {
      fontFamily: 'body',
      lineHeight: 1.5,
      fontWeight: 'body',
    },
  },
})

export type AppTheme = typeof theme

export default theme
