import React from 'react';

export interface UploadProps {
  color: string;
  size?: number;
}

const Upload: React.FC<UploadProps> = ({ color, size = 24 }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 9.80029L12 15.8753"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7 11.3753L12 8.67529L9.30005 11.3753"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 12.5C20.25 7.94365 16.5563 4.25 12 4.25C7.44365 4.25 3.75 7.94365 3.75 12.5C3.75 17.0563 7.44365 20.75 12 20.75C16.5563 20.75 20.25 17.0563 20.25 12.5Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Upload;
