export const td = {};

export const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const RATINGS = {
  VERY_LOW: 'very-low',
  LOW: 'low',
  MODERATE: 'moderate',
  HIGH: 'high',
  VERY_HIGH: 'very-high',
};

export const ADDRESS_INPUT_ERROR = {
  INVALID_ADDRESS: 'invalidAddress',
  INVALID_ADDRESS_2: 'invalidAddress2',
};
