import React from 'react';

export interface SpaceStoriesProps {
  color: string;
  size?: number;
}

export default function SpaceStories({ color, size = 24 }: SpaceStoriesProps) {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4 16.25C4 15.6977 4.44772 15.25 5 15.25H8C8.55228 15.25 9 15.6977 9 16.25V20.875H5C4.44772 20.875 4 20.4273 4 19.875V16.25Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M9 10.625C9 10.0727 9.44772 9.625 10 9.625H13C13.5523 9.625 14 10.0727 14 10.625V20.875H9V10.625Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M14 5C14 4.44771 14.4477 4 15 4H18C18.5523 4 19 4.44772 19 5V19.875C19 20.4273 18.5523 20.875 18 20.875H14V5Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
}
