import { createContext, useContext, useState, FC, Dispatch, SetStateAction, ReactNode } from 'react';
import { SelectedPlace } from '@propertylens/app-commons/dist/types';
import { SENTRY_ERRORS } from '@propertylens/app-commons/dist/constants';
import { ServerReport, LatLng, Warning } from '../../types';

import * as Sentry from '@sentry/react';

export enum ModalType {
  GetReports,
}
export interface ModalContextType {
  openGetReports: () => void;
  closeModal: () => void;
  modalType?: ModalType;
  selectedPlace: SelectedPlace;
  setSelectedPlace: Dispatch<SetStateAction<SelectedPlace>>;
  countOfUnredeemed: number;
  setCountUnredeemed: (count: number) => void;
  validPlace: boolean;
  setValidPlace: Dispatch<SetStateAction<boolean>>;
  orderComplete: boolean;
  setOrderComplete: (valid: boolean) => void;
  isAutoRedeem: boolean;
  setIsAutoRedeem: (valid: boolean) => void;
  modalView: ModalView;
  setModalView: (status: ModalView) => void;
  modalErrorView: ModalErrorView;
  setModalErrorView: (error: ModalErrorView) => void;
  hasCredit: boolean;
  blobImage: Blob | undefined;
  setBlobImage: (blob: Blob) => void;
  pendingReportImages: Array<string>;
  setPendingReportImages: (images: Array<string>) => void;
  savePreviewReportImage: (reportId: string) => void;
  isSamePageRedirect: boolean;
  setIsSamePageRedirect: (redirect: boolean) => void;
  setUserCredit: () => void;
  digitalPurchase: DigitalPurchase | undefined;
  setDigitalPurchase: Dispatch<
    SetStateAction<DigitalPurchase | undefined>
  >;
  addressInputError: boolean;
  setAddressInputError: Dispatch<SetStateAction<boolean>>;
  addressInputErrorType: string;
  setAddressInputErrorType: Dispatch<SetStateAction<string>>;
  selectedAutocompleteValue: string;
  setSelectedAutocompleteValue: Dispatch<SetStateAction<string>>;
  shouldBePopulated: boolean;
  setShouldBePopulated: Dispatch<SetStateAction<boolean>>;
  autocompleteInputValue: string;
  setAutocompleteInputValue: Dispatch<SetStateAction<string>>;
  existingReport: ServerReport | null;
  setExistingReport: Dispatch<SetStateAction<ServerReport | null>>;
  userCoordinates: LatLng | undefined; 
  setUserCoordinates: Dispatch<SetStateAction<LatLng | undefined>>;
  warning: Warning;
  setWarning: Dispatch<SetStateAction<Warning>>;
  isAllowed: boolean;
  setIsAllowed: Dispatch<SetStateAction<boolean>>;
  addressLookupRedirectURL: string;
  setAddressLookupRedirectURL: Dispatch<SetStateAction<string>>;
}
interface Props {
  children: ReactNode;
}
export const defaultPlace: SelectedPlace = {
  address1: '',
  address2: '',
  locality: '',
  state: '',
  zipCode: '',
  gmapsPlaceId: '',
  latitude: 0,
  longitude: 0,
};

export enum ModalView {
  Idle = '',
  Preview = 'preview',
  Error = 'error',
  Retrieving = 'retrieving',
  SecondaryForm = 'secondary_form',
}

export enum ModalErrorView {
  None = '',
  Error = 'error',
  ErrorOnPreview = 'error_on_preview',
  ErrorOnBase = 'error_on_base',
  ReportAPIDown = 'report_api_down',
}

export type DigitalPurchase = {
  id: number;
  sku: string;
  order_number: string;
  created_at: string;
  updated_at: string;
  metadata: {
    address?: {
      address1: string;
      address2: string;
      locality: string;
      state: string;
      zip_code: string;
      latitude: number;
      longitude: number;
      gmaps_place_id: string;
    }
  };
  redeemed: boolean;
};

const ModalContext = createContext<ModalContextType>(
  {} as ModalContextType
);
export const ModalContextProvider: FC<Props> = ({ children }) => {
  // contains the current modal open state if any
  const [modalType, setModalType] = useState<ModalType>();
  const [selectedPlace, setSelectedPlace] =
    useState<SelectedPlace>(defaultPlace);
  const [validPlace, setValidPlace] = useState(false);

  const [countOfUnredeemed, setCountUnredeemed] = useState<number>(0);
  const [orderComplete, setOrderComplete] = useState(false);
  const [isAutoRedeem, setIsAutoRedeem] = useState(false);
  const [modalView, setModalView] = useState<ModalView>(ModalView.Idle);
  const [modalErrorView, setModalErrorView] = useState<ModalErrorView>(
    ModalErrorView.None
  );
  const hasCredit = countOfUnredeemed > 0;

  const [blobImage, setBlobImage] = useState<Blob>();
  const [pendingReportImages, setPendingReportImages] = useState<string[]>([]);
  const savePreviewReportImage = async (reportId: string) => {
    try {
      if (!blobImage) {
        Sentry.captureMessage(SENTRY_ERRORS['IMAGE_UPLOAD_ERROR']);
        Sentry.captureException({
          data: {
            reason: 'No image or reportId',
            blobImage: blobImage,
            reportId: reportId,
          },
        });
        return;
      }
      const formData = new FormData();
      formData.append('file', blobImage, `${reportId}.jpg`);
      formData.append('reportId', reportId);
      setPendingReportImages([...pendingReportImages, reportId]);
      const uploadResponse = await fetch('/api/images', {
        method: 'POST',
        body: formData,
      });
      const saveImageData = await uploadResponse.json();
      if (saveImageData.detail === 'success' && saveImageData?.content?.url) {
        localStorage.setItem(
          `reportImageUrl-${reportId}`,
          saveImageData.content.url
        );
        const filteredPendingImages = pendingReportImages.filter(
          (pendingReportId) => pendingReportId !== reportId
        );
        setPendingReportImages([...filteredPendingImages]);
      }
      if (uploadResponse.status !== 201) {
        Sentry.captureMessage(SENTRY_ERRORS['IMAGE_UPLOAD_ERROR']);
        Sentry.captureException({
          data: saveImageData,
        });
      }
    } catch (error) {
      Sentry.captureMessage(SENTRY_ERRORS['IMAGE_UPLOAD_ERROR']);
      Sentry.captureException({
        data: error,
      });
    }
  };
  const [isSamePageRedirect, setIsSamePageRedirect] = useState(false);

  const [digitalPurchase, setDigitalPurchase] = useState<DigitalPurchase>();
  const setUserCredit = async () => {
    try {
      const getOrders = await fetch('/api/orders');
      const orderData = await getOrders.json();
      if (orderData.success === true) {
        const tempDigitalPurchase = orderData.data.digital_purchases.find(
          (product: DigitalPurchase) => !product.redeemed
        );
        setDigitalPurchase(tempDigitalPurchase);
        setCountUnredeemed(orderData.data.unredeemed_count);
      }
    } catch (error) {
      Sentry.captureMessage(SENTRY_ERRORS['GET_USER_CREDIT_ERROR']);
      Sentry.captureException({
        data: { error: error },
      });
    }
  };

  const [addressInputError, setAddressInputError] = useState(false);
  const [addressInputErrorType, setAddressInputErrorType] = useState('');
  const [selectedAutocompleteValue, setSelectedAutocompleteValue] = useState('');
  const [shouldBePopulated, setShouldBePopulated] = useState(false);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');
  const [existingReport, setExistingReport] = useState<ServerReport | null>(
    null
  );
  const [warning, setWarning] = useState<Warning>({
    message: null,
    type: 'info',
  });
  const [isAllowed, setIsAllowed] = useState<boolean>(true);
  const [userCoordinates, setUserCoordinates] = useState<LatLng | undefined>();
  const [addressLookupRedirectURL, setAddressLookupRedirectURL] = useState('');
  
  return (
    <ModalContext.Provider
      value={{
        blobImage,
        closeModal: () => {
          setExistingReport(null);
          setModalType(undefined);
          setModalView(ModalView.Idle);
          setModalErrorView(ModalErrorView.None);
        },
        countOfUnredeemed,
        setUserCredit,
        digitalPurchase,
        setDigitalPurchase,
        hasCredit,
        isAutoRedeem,
        isSamePageRedirect,
        modalType,
        openGetReports: async () => {
          setModalType(ModalType.GetReports);
          // This is so that we can get the count of unredeemed orders wheneever the modal is opened
          // This is to fix stale data from Chord
          setUserCredit();
        },
        orderComplete,
        pendingReportImages,
        modalView,
        savePreviewReportImage,
        selectedPlace,
        setBlobImage,
        setCountUnredeemed,
        setIsAutoRedeem,
        setIsSamePageRedirect,
        setOrderComplete,
        setPendingReportImages,
        setModalView,
        modalErrorView,
        setModalErrorView,
        setSelectedPlace,
        setValidPlace,
        validPlace,
        addressInputError,
        addressInputErrorType,
        setAddressInputError,
        setAddressInputErrorType,
        selectedAutocompleteValue,
        setSelectedAutocompleteValue,
        shouldBePopulated,
        setShouldBePopulated,
        autocompleteInputValue, 
        setAutocompleteInputValue,
        existingReport,
        setExistingReport,
        userCoordinates, 
        setUserCoordinates,
        warning, 
        setWarning,
        isAllowed, 
        setIsAllowed,
        addressLookupRedirectURL,
        setAddressLookupRedirectURL,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }

  return context;
};
