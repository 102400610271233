import React from 'react';

export interface SubsectionsSpotlightProps {
  color: string;
  size?: number;
}

const SubsectionsSpotlight: React.FC<SubsectionsSpotlightProps> = ({
  color,
  size = 56,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7800_6684)">
      <path
        d="M12.0186 21.3438C8.15256 21.3438 5.01855 20.0006 5.01855 18.3438C5.01855 16.6869 8.15256 15.3438 12.0186 15.3438C15.8846 15.3438 19.0186 16.6869 19.0186 18.3438C19.0186 20.0006 15.8846 21.3438 12.0186 21.3438Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0186 3.17773C13.9516 3.17773 15.5186 4.74474 15.5186 6.67773V7.8444H8.51855V6.67773C8.51855 4.74474 10.0855 3.17773 12.0186 3.17773Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.51855 17.3438L8.51855 11.3438"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5186 17.3438L15.5186 11.3438"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7800_6684">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.0185547 0.177734)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SubsectionsSpotlight;
