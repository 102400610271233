import React from 'react';

type HomeGarageProps = {
  color: string;
  size?: number;
};

const HomeGarage: React.FC<HomeGarageProps> = ({ color, size = 24 }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.2753 4.50676L4.37361 10.0527C4.1374 10.2425 4 10.5292 4 10.8322V21C4 21.5523 4.44772 22 5 22H6.97647C7.52876 22 7.97647 21.5523 7.97647 21V15.4824C7.97647 14.9301 8.42419 14.4824 8.97647 14.4824H15.4706C16.0229 14.4824 16.4706 14.9301 16.4706 15.4824V21C16.4706 21.5523 16.9183 22 17.4706 22H18.7059C19.2582 22 19.7059 21.5523 19.7059 21V10.8282C19.7059 10.5275 19.5706 10.2427 19.3374 10.0528L12.5332 4.5109C12.1673 4.21295 11.643 4.21122 11.2753 4.50676Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="7.93823"
        y1="17.75"
        x2="16.4382"
        y2="17.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="7.93823"
        y1="20.603"
        x2="16.4382"
        y2="20.603"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="9.75"
        y1="11.25"
        x2="14.25"
        y2="11.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HomeGarage;
