import React from 'react';

export interface SubsectionsExteriorConditionProps {
  color: string;
  size?: number;
}

const SubsectionsExteriorCondition: React.FC<
  SubsectionsExteriorConditionProps
> = ({ color, size = 56 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_6414_74217" fill="white">
      <rect x="13.0962" y="9" width="7.69231" height="10.5769" rx="1" />
    </mask>
    <rect
      x="13.0962"
      y="9"
      width="7.69231"
      height="10.5769"
      rx="1"
      stroke={color}
      strokeWidth="4"
      mask="url(#path-1-inside-1_6414_74217)"
    />
    <path
      d="M13.5769 15.25H9.05553C8.60305 15.25 8.20694 15.5538 8.08969 15.9909L3.33447 33.7149C3.16509 34.3462 3.63705 34.9677 4.2907 34.9739L51.445 35.4274C52.1728 35.4344 52.664 34.6863 52.3684 34.0213L51.3409 31.7092C51.1804 31.3481 50.8222 31.1154 50.427 31.1154H38.0962L30.8846 27.75L28.9615 21.5L24.1538 15.25H19.8269"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.0192 15.25L42.6635 25.1058L43.9856 30.0337"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.25 15.25L35.5721 20.1779L36.2332 22.6418L36.5637 23.8738"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.2693 15.25H24.6346"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.7115 21.0193H29.4423"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.1154 27.2693H30.8846"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.1923 35.4424V47.4616"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.3269 35.4424V47.4616"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.5 43V46C30.5 47.1046 31.3954 48 32.5 48H39.5C40.6046 48 41.5 47.1046 41.5 46V43C41.5 41.8954 40.6046 41 39.5 41H32.5C31.3954 41 30.5 41.8954 30.5 43Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 43V46C14.5 47.1046 15.3954 48 16.5 48H23.5C24.6046 48 25.5 47.1046 25.5 46V43C25.5 41.8954 24.6046 41 23.5 41H16.5C15.3954 41 14.5 41.8954 14.5 43Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SubsectionsExteriorCondition;
