import React from 'react';

export interface RisksWeatherAndNaturalDisastersSm {
  color: string;
  size?: number;
}

const RisksWeatherAndNaturalDisastersSm: React.FC<
  RisksWeatherAndNaturalDisastersSm
> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11.9626 5.14607V2M7.09367 7.16854L6.11985 6.19476M5.14608 11.9625H2M6.19478 17.8052L7.16856 16.8315M11.9626 18.8539V22M16.8315 16.8315L17.8053 17.8052M18.8539 11.9625H22M16.8315 7.16854L17.8053 6.19476M16.7566 11.9625C16.7566 14.5843 14.5843 16.7566 11.9626 16.7566C9.34086 16.7566 7.16856 14.5843 7.16856 11.9625C7.16856 9.34082 9.34086 7.16854 11.9626 7.16854C14.5843 7.16854 16.7566 9.34082 16.7566 11.9625Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksWeatherAndNaturalDisastersSm;
