import { Button as NextUIButton, extendVariants } from '@nextui-org/react';

const Button = extendVariants(NextUIButton, {
  variants: {
    color: {
      primary: 'bg-pl-dark text-pl-gray-white',
      monetization: 'bg-pl-main-brand-blue text-pl-gray-white',
    },
    isLoading: {
      true: 'bg-opacity-50 cursor-wait',
    },
    variant: {
      bordered: 'bg-pl-gray-white text-pl-dark',
    },
  },
  defaultVariants: {
    color: 'primary',
    size: 'md',
  },
});

export default Button;
