import { Case } from 'change-case-all';

import GradeValue from './GradeValue';
import { ListValueType, GradeType } from '../../types';

export type ListValueProps = {
  type?: 'currency' | 'percentage' | 'number' | 'text' | 'grade' | 'date';
  value: ListValueType;
  grade?: GradeType;
  formatCase?: string;
};

const formatValue = (props: ListValueProps) => {
  const { type = 'text', value, grade, formatCase } = props;

  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value);
  }

  switch (type) {
    case 'currency':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(Number(value));

    case 'percentage':
      return `${value}%`;

    case 'number':
      return new Intl.NumberFormat('en-US').format(Number(value));

    case 'grade':
      return <GradeValue grade={grade} value={value} />;

    default: {
      if (value === undefined || value === null) return '-';
      if (formatCase === 'sentence') return Case.sentence(value.toString());
      if (formatCase === 'lowercase') return Case.lower(value.toString());
      if (formatCase === 'uppercase') return Case.upper(value.toString());
      if (formatCase === 'none') return value;

      // Defaults to title case
      // Convert to lower case first for UPPER CASE words then convert to Title Case.
      return Case.title(Case.lower(value.toString()));
    }
  }
};

export default formatValue;
