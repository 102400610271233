import React, { FC } from 'react';

export interface AmenitiesFireplaceProps {
  color: string;
  size?: number;
}

const AmenitiesFireplace: FC<AmenitiesFireplaceProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_3912_2849)">
      <path
        d="M3.51929 3.25H20.4808V6.32143H3.51929V3.25Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M3.51904 18.6792H20.4806V21.7506H3.51904V18.6792Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M5.21448 19.2856V5.71422M18.7859 19.2856V5.71422"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M16 18.4228V14.5766V14.5766C16 12.4525 14.278 10.7305 12.1538 10.7305H11.8462C9.72198 10.7305 8 12.4525 8 14.5766V14.5766V18.4228"
        stroke={color}
        strokeWidth="1.5"
      />
    </g>
    <path
      d="M141.625 291.5V283.25C141.625 280.25 142.375 279.5 145.375 279.5H150.625C153.625 279.5 154.375 280.25 154.375 283.25V290.75C154.375 290.855 154.375 290.96 154.367 291.065"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M143.762 289.25H154.375V291.875C154.375 293.322 153.197 294.5 151.75 294.5H144.25C142.803 294.5 141.625 293.322 141.625 291.875V291.388C141.625 290.21 142.585 289.25 143.762 289.25Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M145 283H151"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M145 286H148.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_3912_2849">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(2 2.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default AmenitiesFireplace;
