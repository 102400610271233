import { SelectedPlace } from '@propertylens/app-commons/dist/types';

const validateAddress = async (selectedPlace: SelectedPlace, googleAPIKey: string) => {
    const { address1, address2, locality, state, zipCode } = selectedPlace;
    try {
      const validationRequest = await fetch(
        `https://addressvalidation.googleapis.com/v1:validateAddress?key=${googleAPIKey}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            address: {
              regionCode: 'US',
              locality: locality,
              addressLines: [
                `${address1} ${address2} ${locality} ${state} ${zipCode}`,
              ],
            },
          }),
        }
      );
      const validationResponse = await validationRequest.json();
      return validationResponse.result;
    } catch (error) {
      throw new Error('Error validating address');
    }
  };

export default validateAddress;