import React from 'react';

export interface RisksNaturalDisastersLgProps {
  color: string;
  size?: number;
}

const RisksNaturalDisastersLg: React.FC<RisksNaturalDisastersLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M19.0161 19.9814L18.8819 20.9601C18.7212 22.1314 19.6063 23.1856 20.7877 23.2303L21.7151 23.2654C22.7255 23.3037 23.5484 24.09 23.6325 25.0977L23.7328 26.2986C23.7735 26.7867 23.6337 27.2728 23.3399 27.6647L20.5289 31.4137C20.235 31.8056 19.8076 32.076 19.3276 32.1737L17.156 32.6157C16.676 32.7134 16.2486 32.9838 15.9547 33.3757L15.4113 34.1005C14.9917 34.6601 14.3102 34.9598 13.6142 34.8909L10.3281 34.5657"
      stroke={color}
      strokeWidth="2"
    />
    <circle
      cx="23.9829"
      cy="33.0151"
      r="13.2751"
      transform="rotate(36.8627 23.9829 33.0151)"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M30.4982 21.2217L29.8749 25.2487C29.7619 25.9788 30.0605 26.7116 30.6516 27.1548L32.9378 28.869C33.3992 29.215 33.9875 29.345 34.5518 29.2255L36.8912 28.7303"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M37.5128 34.6265L32.3891 32.1188C31.8597 31.8597 31.2429 31.8473 30.7035 32.085L27.5347 33.4812C26.8092 33.8009 26.341 34.5189 26.3411 35.3117L26.3414 37.6622C26.3415 38.2917 26.6379 38.8844 27.1416 39.2621L27.3347 39.4068C28.1574 40.0237 28.3243 41.1908 27.7074 42.0135V42.0135L26.0319 44.2481C25.5178 44.9337 25.6569 45.9063 26.3425 46.4204V46.4204"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M12.19 40.7722L15.0163 39.0697C15.9593 38.5017 17.184 38.8025 17.7565 39.7428L18.7815 41.4262C19.124 41.9888 19.7215 42.3466 20.3791 42.3831L20.7911 42.4059C21.851 42.4646 22.6803 43.3411 22.6805 44.4026L22.6808 47.1655"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M37.2545 30.395C39.1778 28.386 42.2065 21.0535 40.1911 21.7391C36.6334 22.9493 36.0841 21.222 36.0841 21.222C36.0841 21.222 39.0399 18.5492 38.1996 10.1635C37.2647 11.4136 35.5726 13.2861 31.4983 14.4456C27.424 15.605 25.8253 15.2346 22.74 19.3598"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M13.9513 8.8339V6.25M9.95244 10.495L9.15264 9.69521M8.35286 14.4324H5.76895M9.21418 19.231L10.014 18.4313M13.9513 20.0923V22.6762M17.9502 18.4313L18.75 19.231M19.6113 14.4324H22.1952M17.9502 10.495L18.75 9.69521"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0094 14.4634C17.0094 16.1182 15.6383 17.4893 13.9835 17.4893C12.3287 17.4893 10.9576 16.1182 10.9576 14.4634C10.9576 12.8086 12.3287 11.4375 13.9835 11.4375C15.6383 11.4375 17.0094 12.8086 17.0094 14.4634Z"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksNaturalDisastersLg;
