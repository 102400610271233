import React from 'react';

export interface RisksHailLgProps {
  color: string;
  size?: number;
}

const RisksHailLg: React.FC<RisksHailLgProps> = ({ color, size = 56 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M24 31H12C9.23853 31 7 28.7615 7 26C7 23.5063 8.8252 21.4392 11.2126 21.0615C11.0732 20.3962 11 19.7068 11 19C11 13.4771 15.4771 9 21 9C24.5281 9 27.6294 10.8269 29.4094 13.5864C30.1936 13.2104 31.0723 13 32 13C34.4421 13 36.5437 14.4592 37.48 16.5532C38.2461 16.1982 39.1001 16 40 16C43.1123 16 45.6711 18.3696 45.9707 21.4033C47.7522 22.1682 49 23.9382 49 26C49 28.7615 46.7615 31 44 31H31"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M21 13C17.6862 13 15 15.6862 15 19"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M21 35.6073L27.888 27H35L29.54 33.6326H32.872L24.78 44L26.208 35.6073H21Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="42" cy="36" r="2" stroke={color} strokeWidth="2" />
    <circle cx="44" cy="45" r="2" stroke={color} strokeWidth="2" />
    <circle cx="36" cy="41" r="2" stroke={color} strokeWidth="2" />
    <circle
      cx="3"
      cy="3"
      r="2"
      transform="matrix(-1 0 0 1 15 33)"
      stroke={color}
      strokeWidth="2"
    />
    <circle
      cx="3"
      cy="3"
      r="2"
      transform="matrix(-1 0 0 1 13 42)"
      stroke={color}
      strokeWidth="2"
    />
    <circle
      cx="3"
      cy="3"
      r="2"
      transform="matrix(-1 0 0 1 21 38)"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);

export default RisksHailLg;
