import React from 'react';

export interface DocumentsPermitProps {
  color: string;
  size?: number;
}

const DocumentsPermit: React.FC<DocumentsPermitProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.0002 11.0005V8.31189C18.0002 8.19254 17.9528 9.07808 17.8685 7.99368L14.507 4.63229C13.4226 4.5479 14.3082 4.50049 14.1889 4.50049H6.45024C6.20172 4.50049 6.00024 4.70196 6.00024 4.95049V19.0505C6.00024 19.299 6.20172 19.5005 6.45024 19.5005H12.7502"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99976 11.5H12.9998M8.99976 8.5H10.9998M8.99976 14.5H10.2498"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 4.5V7.9C14 8.23137 14.2686 8.5 14.6 8.5H18"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9945 13.344L18.911 13.8307C19.1105 13.8814 19.2512 14.0627 19.2451 14.2684C19.1163 18.5872 16.6253 19.2502 16.6253 19.2502C16.6253 19.2502 14.1342 18.5872 14.0054 14.2684C13.9993 14.0627 14.14 13.8814 14.3395 13.8307L16.256 13.344C16.4984 13.2825 16.7522 13.2825 16.9945 13.344Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocumentsPermit;
