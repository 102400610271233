import React from 'react';

export interface ActionLogOutProps {
  color: string;
  size?: number;
}

const ActionLogOut: React.FC<ActionLogOutProps> = ({ color, size = 24 }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9 4.5H19V18.5C19 19.6046 18.1046 20.5 17 20.5H9"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.5L15 12.5M15 12.5L12 9.5M15 12.5H5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ActionLogOut;