import React from 'react';

export interface AmenitiesLaundryProps {
  color: string;
  size?: number;
}

const AmenitiesLaundry: React.FC<AmenitiesLaundryProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M22.2499 5.56999L22.25 22.13C22.25 22.8836 21.6663 23.45 21 23.45H7C6.33375 23.45 5.75 22.8836 5.75 22.13V5.57C5.75 4.81646 6.33375 4.25 7 4.25H20.9999C21.6662 4.25 22.2499 4.81645 22.2499 5.56999Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0002 6.51215L20.0107 6.50049"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9998 20.5024C17.3135 20.5024 19.9998 17.8161 19.9998 14.5024C19.9998 11.1887 17.3135 8.50244 13.9998 8.50244C10.686 8.50244 7.99976 11.1887 7.99976 14.5024C7.99976 17.8161 10.686 20.5024 13.9998 20.5024Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0002 17.5024C12.3433 17.5024 11.0002 16.1593 11.0002 14.5024"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AmenitiesLaundry;
