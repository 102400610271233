import React from 'react';

export interface HomeInteriorProps {
  color: string;
  size?: number;
}

const HomeInterior: React.FC<HomeInteriorProps> = ({ color, size = 24 }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 20.5H20"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6457 3.2369L14.5549 7.2369C14.3814 7.87303 14.8603 8.5 15.5197 8.5H20.5371C21.1965 8.5 21.6753 7.87304 21.5019 7.2369L20.411 3.2369C20.2924 2.80183 19.8972 2.5 19.4463 2.5H16.6105C16.1596 2.5 15.7644 2.80183 15.6457 3.2369Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M18.158 9.55273L18.158 19.658"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.1052 17.9736L13.1052 19.6578"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.52624 17.9736L5.52625 19.6578"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.10547 11.2369V10.2896C5.10547 9.18498 6.0009 8.28955 7.10547 8.28955H10.6844C11.789 8.28955 12.6844 9.18498 12.6844 10.2896V10.8159"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M5.78972 11.2368H4.00024C3.44796 11.2368 3.00024 11.6845 3.00024 12.2368V15.5526C3.00024 16.6572 3.89567 17.5526 5.00024 17.5526H13.2108C14.3153 17.5526 15.2108 16.6572 15.2108 15.5526V12.2368C15.2108 11.6845 14.7631 11.2368 14.2108 11.2368H12.4213C11.869 11.2368 11.4213 11.6845 11.4213 12.2368V13.7631H6.78972V12.2368C6.78972 11.6845 6.342 11.2368 5.78972 11.2368Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default HomeInterior;
