import React from 'react';

export interface SubsectionsOwnershipProps {
  color: string;
  size?: number;
}

const SubsectionsOwnership: React.FC<SubsectionsOwnershipProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clipPath="url(#clip0_6411_73721)">
      <path
        d="M23.2579 51.2579C22.3907 50.6329 22.1954 49.422 22.8204 48.5548L24.4454 46.297C25.0704 45.4298 26.2813 45.2345 27.1485 45.8595C28.0157 46.4845 28.211 47.6954 27.586 48.5626L25.961 50.8204C25.336 51.6876 24.1251 51.8829 23.2579 51.2579Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7423 49.5313C18.8751 48.9063 18.6798 47.6954 19.3048 46.8282L21.5391 43.7266C22.1641 42.8595 23.3751 42.6641 24.2423 43.2891C25.1095 43.9141 25.3048 45.1251 24.6798 45.9923L22.4454 49.0938C21.8204 49.961 20.6095 50.1563 19.7423 49.5313Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7188 47.1095C15.8516 46.4845 15.6563 45.2813 16.2813 44.4063L18.0391 41.961C18.6641 41.0938 19.8673 40.8985 20.7423 41.5235C21.6095 42.1485 21.8048 43.3595 21.1798 44.2266L19.422 46.672C18.797 47.5391 17.5938 47.7345 16.7188 47.1095Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.039 44.6328C13.2812 44.0859 13.1094 43.039 13.6562 42.2812L14.9453 40.4844C15.4922 39.7265 16.539 39.5547 17.2969 40.1015C18.0547 40.6484 18.2265 41.6953 17.6797 42.4531L16.3906 44.25C15.8437 45.0078 14.7969 45.1797 14.039 44.6328Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.0469 50.2031C33.0703 50.5 33.0078 50.8125 32.875 51.1172C32.6094 51.7109 32.0859 52.1094 31.5469 52.1953C31.2812 52.2422 31.0156 52.2187 30.7656 52.1016L26.6953 49.7969"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.9063 45.4531C39.0938 46 39.0234 46.6094 38.6875 47.1172L38.6094 47.2266C38.0469 48.0703 36.9297 48.3203 36.0391 47.8281"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.0391 47.8281C36.2109 48.3438 36.1484 48.9375 35.8125 49.4297L35.625 49.7031C35.0547 50.5547 33.9297 50.7969 33.1172 50.25L33.0469 50.2031"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.7266 42.4297C41.2891 42.8047 41.5937 43.4063 41.5937 44.0078C41.5937 44.3359 41.5 44.6641 41.3125 44.9531C40.7969 45.7266 39.75 45.9297 38.9062 45.4531"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.7188 42.4219L27.7109 34.1797"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.711 34.1797L25.2969 35.3984C25.2969 35.3984 24.2422 37.2344 21.6797 37.4531C17.7344 37.7813 22.1797 32.2031 22.1797 32.2031C22.1797 32.2031 23.2969 30.7109 24.75 30.2656C25.3594 30.0781 25.9766 29.9766 27.086 29.8906"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.9063 45.4531L35.5781 43.4219"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.0391 47.8281L32.8516 45.7891"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.125 50.25L30.3203 48.5781"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7109 39.1562C11.8516 39.6562 13.125 40.5391 13.8437 42.0313"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7891 28.6172L17.1094 28.7578C18.0078 29.1563 18.9922 29.2656 19.9609 29.1016L22.25 28.6953C23.2031 28.5391 24.1719 28.6484 25.0547 29.0234L27.0937 29.8906"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.4922 39.0938C43.5 39.6094 41.7969 40.7891 40.7266 42.4297"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0938 29.8906L29.8047 29.4922C30.3125 29.4219 30.8359 29.4453 31.3359 29.5625L34.1094 30.2266C35.0625 30.4531 36.0547 30.3359 36.9141 29.8984L38.6641 29"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.4609 38.2266L45.3594 40.5937L38.1484 28.1016L42.2422 25.7344"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.78125 38.2266L9.875 40.5937L17.0937 28.1016L12.9922 25.7344"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.4844 9.64062V3.78125H39.0234V12.2813"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1328 21.8828V13.8359V13.8281"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.1016 21.8828V13.8281"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.8594 15.1406L27.6172 3.78125L12.3828 15.1406"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0469 24.7734V20.4375H27.6172H31.1953V25.5938"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6411_73721">
        <rect
          width="45.2422"
          height="50"
          fill="white"
          transform="translate(5 3)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SubsectionsOwnership;
