import React from 'react';

export interface SubsectionsPossibleContaminationProps {
  color: string;
  size?: number;
}

const SubsectionsPossibleContamination: React.FC<
  SubsectionsPossibleContaminationProps
> = ({ color, size = 56 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clipPath="url(#clip0_6551_27347)">
      <path
        d="M19.604 3.82764H36.8859C42.8369 3.82764 47.6623 8.653 47.6623 14.604V46.7344C47.6623 49.7389 45.2289 52.1723 42.2244 52.1723H14.2655C11.261 52.1723 8.82764 49.7389 8.82764 46.7344V14.604C8.82764 8.653 13.653 3.82764 19.604 3.82764Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.3915 24.4037V14.5129C43.3915 10.8877 40.4533 7.94946 36.828 7.94946H19.662C16.0368 7.94946 13.0985 10.8877 13.0985 14.5129V24.4037H43.3998H43.3915Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2686 24.4036C18.1143 19.5948 22.7824 16.1765 28.245 16.1765C33.7077 16.1765 38.3758 19.5948 40.2215 24.4036"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.6866 16.4085L28.245 11.459L25.8033 16.4085"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.8808 42.7615C29.7595 42.7615 31.2825 41.2385 31.2825 39.3598C31.2825 37.481 29.7595 35.958 27.8808 35.958C26.002 35.958 24.479 37.481 24.479 39.3598C24.479 41.2385 26.002 42.7615 27.8808 42.7615Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9321 39.8894C18.1225 43.5395 20.2827 46.6681 23.3782 48.2407"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.3916 48.2407C35.4788 46.6764 37.6474 43.5477 37.8377 39.8894"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9065 30.7354C24.3715 29.8912 26.0682 29.4028 27.8808 29.4028C29.6934 29.4028 31.3985 29.8829 32.8635 30.7354"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.1841 36.4132L22.9065 30.7354"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.8634 30.7354L29.5858 36.4132"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.8295 39.8896L31.2743 39.542"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.3917 48.2408L29.4203 42.3892"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.4873 39.542L17.9321 39.8896"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3414 42.3892L23.3783 48.2408"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6551_27347">
        <rect
          width="40.49"
          height="50"
          fill="white"
          transform="translate(8 3)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SubsectionsPossibleContamination;
