import React from 'react';

export interface SubsectionsRisksGlanceProps {
  color: string;
  size?: number;
}

const SubsectionsRisksGlance: React.FC<SubsectionsRisksGlanceProps> = ({
  color,
  size = 56,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.1213 30.0024L39.3822 31.8947C36.9234 34.57 35.7729 37.0119 35.2446 38.8258C35.1166 39.2652 35.0256 39.6663 34.9613 40.0232C34.6646 39.7087 34.3068 39.272 33.8936 38.6674C33.7717 38.4891 33.5881 38.2847 33.3177 38.1515C33.0228 38.0062 32.7002 37.9837 32.4014 38.0677C31.8966 38.2095 31.5805 38.6167 31.4153 38.8611C31.0364 39.4219 30.7135 40.2749 30.4592 41.1387C30.1967 42.0304 29.9777 43.0384 29.8413 43.9767C29.7078 44.896 29.6421 45.8245 29.727 46.5274L31.7126 46.2851C31.6597 45.8479 31.6947 45.1282 31.8205 44.2619C31.9436 43.4146 32.1428 42.4994 32.3777 41.7013C32.5132 41.2411 32.6519 40.85 32.7823 40.5433C33.4688 41.4333 34.0573 41.973 34.5384 42.3008C34.8421 42.5077 35.1057 42.632 35.3251 42.7043C35.4347 42.7404 35.5318 42.763 35.6157 42.7766C35.6575 42.7834 35.6958 42.7879 35.7304 42.7906C35.7476 42.792 35.7639 42.793 35.7792 42.7936L35.8014 42.7943L35.812 42.7944L35.8171 42.7945L35.8196 42.7945C35.8209 42.7945 35.8221 42.7945 35.8234 41.7945L35.8221 42.7945H36.9298L36.819 41.6993L36.8181 41.6857C36.817 41.6683 36.8154 41.636 36.8146 41.5895C36.813 41.4967 36.8144 41.3473 36.8294 41.1472C36.8593 40.7472 36.9432 40.1438 37.1648 39.3828C37.4981 38.2383 38.1473 36.7222 39.4125 34.995C39.726 36.2075 40.3609 37.6596 41.6282 39.3259C42.2026 40.0813 42.8013 40.69 43.3473 41.2254C43.443 41.3193 43.5361 41.41 43.6267 41.4982C44.067 41.9272 44.4491 42.2996 44.792 42.7033C45.5548 43.6018 46.095 44.6235 46.0928 46.4933L48.0928 46.4933C48.0956 44.109 47.3604 42.6363 46.3172 41.4077C45.9075 40.9252 45.4449 40.4749 45.0036 40.0455C44.9176 39.9618 44.8324 39.8788 44.7485 39.7966C44.2198 39.2781 43.705 38.7509 43.2207 38.114C41.2597 35.5354 41.1167 33.6846 41.1181 32.5709L41.1213 30.0024ZM33.1441 39.8871C33.1441 39.8868 33.1409 39.8895 33.1347 39.8964C33.1411 39.8908 33.1442 39.8874 33.1441 39.8871Z"
      fill={color}
    />
    <path
      d="M35.9655 13.0481L35.4472 12.4988C35.2583 12.2986 34.9952 12.1851 34.7199 12.1851C34.4446 12.1851 34.1815 12.2986 33.9925 12.4988L32.6301 13.9428C32.2665 14.3281 32.2665 14.93 32.6301 15.3153L35.2381 18.0794C35.427 18.2796 35.6902 18.3931 35.9655 18.3931C36.2407 18.3931 36.5039 18.2796 36.6928 18.0794L41.2276 13.2733C41.5912 12.888 41.5912 12.286 41.2276 11.9007L39.8652 10.4568C39.6762 10.2566 39.4131 10.1431 39.1378 10.1431C38.8625 10.1431 38.5994 10.2566 38.4105 10.4568L35.9655 13.0481Z"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M41.0862 42.3931C41.9413 42.9606 42.7004 44.7733 42.7324 45.9645"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M17.8605 14.2236L17.8592 13.2236C17.8605 14.2236 17.8617 14.2236 17.863 14.2236L17.8655 14.2236L17.8706 14.2236L17.8812 14.2234L17.9034 14.2227C17.9187 14.2221 17.935 14.2212 17.9523 14.2198C17.9868 14.217 18.0251 14.2125 18.0669 14.2057C18.1508 14.1921 18.248 14.1695 18.3575 14.1334C18.5769 14.0611 18.8405 13.9369 19.1442 13.73C19.6253 13.4022 20.2138 12.8624 20.9003 11.9724C21.0307 12.2791 21.1694 12.6702 21.3049 13.1304C21.5398 13.9286 21.739 14.8437 21.8621 15.691C21.9879 16.5573 22.0229 17.277 21.97 17.7142C21.6031 20.7513 18.4149 23.4969 14.6935 23.4969C12.846 23.4969 11.039 22.8381 9.70718 21.7867C8.3739 20.7341 7.59158 19.3564 7.58985 17.9225C7.58759 16.0526 8.12777 15.0309 8.89063 14.1324C9.23347 13.7287 9.61565 13.3563 10.0559 12.9274C10.1465 12.8391 10.2396 12.7484 10.3353 12.6545C10.8813 12.1192 11.48 11.5104 12.0544 10.755C13.3217 9.08868 13.9566 7.63665 14.2701 6.4241C15.5353 8.15131 16.1845 9.66744 16.5178 10.8119C16.7395 11.5729 16.8233 12.1763 16.8532 12.5763C16.8682 12.7764 16.8696 12.9258 16.868 13.0187C16.8672 13.0651 16.8656 13.0974 16.8646 13.1148L16.8636 13.1285L16.7528 14.2236H17.8605ZM20.5385 11.3162C20.5385 11.3159 20.5417 11.3187 20.5479 11.3255C20.5415 11.3199 20.5384 11.3165 20.5385 11.3162Z"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M12.5971 14.7144C11.252 15.6072 10.3601 17.8394 11.6995 19.6251"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M22.1964 17.8394L28.8928 14.7144"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M22.1964 13.375L28.8928 10.25"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M45.4107 16.0535L52.5308 20.5128C52.8227 20.6956 53 21.0158 53 21.3603V23.6623C53 24.4396 52.152 24.9197 51.4855 24.5198L44.0714 20.0713"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M7.93421 20.0715L3.53785 22.3627C3.20731 22.5349 3 22.8767 3 23.2495V25.5964C3 26.3397 3.78231 26.8232 4.44721 26.4908L11.0357 23.1965"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M44.0715 21.4106V39.7142M8.35718 24.9821V46.8571"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M36.9286 4.44653L44.9643 8.01796C46.1548 12.1846 46.2143 21.5001 36.9286 25.4287"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M37.4832 4.44653L29.4474 8.01796C28.257 12.1846 28.1974 21.5001 37.4832 25.4287"
      stroke={color}
      strokeWidth="2"
    />
    <mask id="path-13-inside-1_6417_74257" fill="white">
      <rect x="5.67859" y="45.9644" width="44.6429" height="6.25" rx="1" />
    </mask>
    <rect
      x="5.67859"
      y="45.9644"
      width="44.6429"
      height="6.25"
      rx="1"
      stroke={color}
      strokeWidth="4"
      mask="url(#path-13-inside-1_6417_74257)"
    />
    <path
      d="M26.2143 46.857V33.6785C26.2143 32.5739 25.3189 31.6785 24.2143 31.6785H17.5C16.3954 31.6785 15.5 32.5739 15.5 33.6785V46.857M23.0893 38.8213V41.9463"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);

export default SubsectionsRisksGlance;
