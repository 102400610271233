import React, { FC } from 'react';

export interface Props {
  color: string;
  size?: number;
}

const RisksHailSm: FC<Props> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M6.32271 16.3638L4.90808 20.0001M9.10321 16.3638L7.68858 20.0001M12.1808 16.3638L10.7662 20.0001"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6364 4.58182C14.2182 4.21818 14.9454 4 15.6727 4C17.5636 4 19.0909 5.23636 19.5273 6.98182C20.9091 6.98182 22 8.14545 22 9.52727C22 10.8364 20.9818 11.9273 19.6727 12.0727M6.79998 9.38182C6.29089 8.94545 5.56363 8.65455 4.83636 8.65455M13.2 7.41818C12.6182 7.49091 12.0364 7.70909 11.6 8M13.5636 7.41818C13.4182 7.41818 13.3455 7.41818 13.2 7.49091C12.4 6.18182 10.9455 5.30909 9.27273 5.30909C7.16364 5.30909 5.34545 6.76364 4.83636 8.72727C3.23636 8.8 2 10.0364 2 11.6364C2 13.1636 3.16362 14.4 4.6909 14.5455H13.5636C15.5273 14.5455 17.1273 12.9455 17.1273 10.9818C17.1273 9.01818 15.5273 7.41818 13.5636 7.41818Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksHailSm;
