import React from 'react';

export interface DocumentsFlagProps {
  color: string;
  size?: number;
}

const DocumentsFlag: React.FC<DocumentsFlagProps> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_5205_36950)">
      <path
        d="M4.64514 3.64516L4.64514 21"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.75 4.76341C4.75 4.65011 4.82241 4.56672 4.9108 4.54391C6.05552 4.24842 8.23389 3.75 9.80645 3.75C11.379 3.75 13.5574 4.24842 14.7021 4.54391C14.7905 4.56672 14.8629 4.65011 14.8629 4.76341V12.6337C14.8629 12.7957 14.6949 12.9484 14.4992 12.9013C13.2721 12.6054 11.3369 12.2039 9.80645 12.2039C8.27601 12.2039 6.34083 12.6054 5.11366 12.9013C4.91797 12.9484 4.75 12.7957 4.75 12.6337V4.76341Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M15.2695 7.1129C17.2822 7.1129 20.875 5.82258 20.875 5.82258L18.375 10.9839L20.875 14.8548C20.875 14.8548 17.8877 15.5 15.875 15.5C13.8623 15.5 10.875 14.8548 10.875 14.8548V12.8992"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5205_36950">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DocumentsFlag;
