import React from 'react';

export interface CommunicationPhoneProps {
  color: string;
  size?: number;
}

const CommunicationPhone: React.FC<CommunicationPhoneProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_36247)">
        <path
          d="M19 16.431C19 16.683 18.9439 16.942 18.8247 17.194C18.7056 17.446 18.5513 17.684 18.348 17.908C18.0045 18.286 17.6259 18.559 17.1983 18.734C16.7777 18.909 16.322 19 15.8312 19C15.1162 19 14.352 18.832 13.5458 18.489C12.7396 18.146 11.9334 17.684 11.1342 17.103C10.328 16.515 9.56385 15.864 8.83475 15.143C8.11267 14.415 7.46069 13.652 6.87882 12.854C6.30396 12.056 5.84126 11.258 5.50476 10.467C5.16825 9.669 5 8.906 5 8.178C5 7.702 5.08413 7.247 5.25238 6.827C5.42063 6.4 5.68703 6.008 6.05859 5.658C6.50726 5.217 6.998 5 7.51678 5C7.71307 5 7.90936 5.042 8.08463 5.126C8.2669 5.21 8.42814 5.336 8.55433 5.518L10.1808 7.807C10.307 7.982 10.3981 8.143 10.4612 8.297C10.5243 8.444 10.5593 8.591 10.5593 8.724C10.5593 8.892 10.5103 9.06 10.4121 9.221C10.321 9.382 10.1878 9.55 10.0195 9.718L9.48673 10.271C9.40961 10.348 9.37456 10.439 9.37456 10.551C9.37456 10.607 9.38157 10.656 9.39559 10.712C9.41663 10.768 9.43766 10.81 9.45168 10.852C9.57787 11.083 9.79519 11.384 10.1037 11.748C10.4191 12.112 10.7556 12.483 11.1202 12.854C11.4987 13.225 11.8633 13.568 12.2349 13.883C12.5994 14.191 12.9009 14.401 13.1392 14.527C13.1743 14.541 13.2163 14.562 13.2654 14.583C13.3215 14.604 13.3776 14.611 13.4407 14.611C13.5598 14.611 13.651 14.569 13.7281 14.492L14.2609 13.967C14.4362 13.792 14.6044 13.659 14.7656 13.575C14.9269 13.477 15.0881 13.428 15.2634 13.428C15.3966 13.428 15.5368 13.456 15.691 13.519C15.8453 13.582 16.0065 13.673 16.1818 13.792L18.5023 15.437C18.6845 15.563 18.8107 15.71 18.8878 15.885C18.9579 16.06 19 16.235 19 16.431Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_36247">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CommunicationPhone;
