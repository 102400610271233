import React from 'react';

export interface RisksWildfireLgProps {
  color: string;
  size?: number;
}

const RisksWildfireLg: React.FC<RisksWildfireLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M14.0153 5.47209C13.6918 4.66332 12.0377 3 12.0377 3C11.5982 6.22287 8.41281 9.91361 11.5508 12.8675C11.5508 12.8675 13.9229 11.5195 13.9766 9.30886"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M19.9405 27.7668L17.5142 23.3994L12.3381 32.6194H14.7644L10.5588 39.0896H14.7644L10.5588 45.398H18.6465"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.8526 27.7668L36.2789 23.3994L41.4551 32.6194H39.0288L43.2344 39.0896H39.0288L43.2344 45.398H35.1467"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.9844 24.9147L26.7453 19.8408L32.6354 30.5523H29.8745L34.6602 38.0691H29.8745L34.6602 45.398H25.4569"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.4844 24.9147L26.7234 19.8408L20.8333 30.5523H23.5943L18.8086 38.0691H23.5943L18.8086 45.398H28.0119"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M29.4844 24.9147L26.7234 19.8408L20.8333 30.5523H23.5943L18.8086 38.0691H23.5943L18.8086 45.398H28.0119"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="15.2422"
      y1="46.7217"
      x2="15.2422"
      y2="51.5154"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M18.9726 45.7217L18.9727 52.552"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="29.0078"
      y1="51.5156"
      x2="29.0078"
      y2="46.7219"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M25.2813 52.5156L25.2813 45.6853"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="38.7109"
      y1="51.5156"
      x2="38.7109"
      y2="46.7219"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M11.7305 53L41.8168 53"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M34.9844 52.5146L34.9844 45.8827"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M9.10317 37.1481C6.56902 36.2315 1.01056 31.6852 6.02984 25.1783C10.3969 19.5168 10.4512 17.0366 10.3973 17.5758C10.1815 19.3551 10.0087 23.1077 11.0439 23.8842C12.338 24.8547 16.7053 14.1789 16.7053 14.1789C16.7053 14.1789 16.9964 18.3766 18.9699 18.8698C21.4645 19.4932 22.6902 17.0581 22.0432 13.0466C22.852 5.44416 27.8664 4.47363 27.8664 4.47363C27.8664 4.47363 25.7635 8.841 27.8664 11.4291"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.9608 14.3718C34.6517 17.4451 34.3365 10.9447 34.3365 10.9447C34.3365 10.9447 36.667 12.683 36.8827 16.3494C41.7353 15.7024 40.3214 9.32715 40.3214 9.32715C40.3214 9.32715 43.7182 10.1359 40.8066 20.4882C40.4292 21.5666 40.2567 23.9497 42.5859 24.8556C44.9152 25.7614 44.8505 28.1446 44.527 29.2229"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.8989 22.1079C47.0189 18.8866 51.0515 19.188 51.0515 19.188M51.0515 19.188C51.0515 19.188 51.0535 19.2748 51.0543 19.4306M51.0515 19.188L51.0543 19.4306M51.0543 19.4306C51.0595 20.3884 49.2215 21.9709 50.2227 25.9387C50.2227 25.9387 51.7956 30.083 50.8697 32.4752C49.9023 34.9746 45.6689 36.9528 45.6689 36.9528"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksWildfireLg;
