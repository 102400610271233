import React from 'react';

export interface SubsectionsListingDetailsProps {
  color: string;
  size?: number;
}

const SubsectionsListingDetails: React.FC<SubsectionsListingDetailsProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clipPath="url(#clip0_6411_73702)">
      <path
        d="M11.6843 43.4111V3.84937H17.0521V44.1161"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0435 7.54395H43.7718V12.9032H17.0435"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6842 12.9032H7.36108V7.54395H11.6842"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.9927 38.6123H20.2625V18.5681H43.9927L49.9805 28.5902L43.9927 38.6123Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1121 18.5681V12.9031"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.8796 12.9031V18.5681"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3311 18.5681V12.9031"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.8391 18.5681V12.9031"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.2088 26.4753H27.261V35.5122H38.2088V26.4753Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.8686 31.6648H31.4905V35.5123H33.8686V31.6648Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.7306 21.0823L26.2927 26.4755H39.177L32.7306 21.0823Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1554 52.1508C10.1554 52.1508 11.3444 46.4773 6.75806 41.5342C6.75806 41.5342 12.9667 41.6531 14.3596 47.9126C14.3596 47.9126 15.7525 42.3156 22.1904 42.9101C22.1904 42.9101 17.2982 46.851 18.7336 52.1508H10.1554Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.84937 52.1506H43.7719"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6411_73702">
        <rect
          width="45.8298"
          height="50"
          fill="white"
          transform="translate(5 3)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SubsectionsListingDetails;
