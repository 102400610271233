import React from 'react';

export interface RisksCrimeSm {
  color: string;
  size?: number;
}

const RisksCrimeSm: React.FC<RisksCrimeSm> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_6501_41450)">
      <path
        d="M15.8646 11.6707L11.9637 7.68861L8.0629 11.6707M8.98122 10.7362V14.7264H14.9544V10.7362M20.3181 11.5326C20.3262 13.9137 19.2697 16.1242 17.5143 17.392L11.9637 21.3904L6.41316 17.392C4.65778 16.1242 3.60129 13.9137 3.60942 11.5326C3.60942 11.5326 3.65006 4.47041 3.65006 3.90966C4.65778 4.5598 5.8199 4.93363 7.05518 4.93363C8.97309 4.93363 10.7041 4.04781 11.9637 2.60938C13.2234 4.04781 14.9544 4.93363 16.8723 4.93363C18.1076 4.93363 19.2697 4.5598 20.2774 3.90966C20.2774 4.47041 20.3181 11.5326 20.3181 11.5326Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6501_41450">
        <rect
          width="17.9277"
          height="20"
          fill="white"
          transform="translate(3 2)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RisksCrimeSm;
