import React from 'react';

export interface SystemsPlumbingProps {
  color: string;
  size?: number;
}

const SystemsPlumbing: React.FC<SystemsPlumbingProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_37105)">
        <path
          d="M3 9.99981C3 11.8563 3.31606 13.6367 3.87866 14.9494C4.44125 16.2621 5.20429 16.9996 5.99992 16.9996C6.79555 16.9996 7.55859 16.2621 8.12118 14.9494C8.68378 13.6367 8.99984 11.8563 8.99984 9.99981C8.99984 8.14335 8.68378 6.36292 8.12118 5.0502C7.55859 3.73748 6.79555 3 5.99992 3C5.20429 3 4.44125 3.73748 3.87866 5.0502C3.31606 6.36292 3 8.14335 3 9.99981Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.9994 9.99981C20.9994 6.13392 19.6565 3 17.9995 3"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 3H17.9997"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.9994 10.0005V20.0002L17.9995 19.0002L14.9996 21.0002L11.9997 18.0003L8.99976 20.0002V10.0005"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 10.0005H6.01"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_37105">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SystemsPlumbing;
