import React from 'react';

export interface SubsectionsExposureWeatherProps {
  color: string;
  size?: number;
}

const SubsectionsExposureWeather: React.FC<SubsectionsExposureWeatherProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clipPath="url(#clip0_6551_27348)">
      <path
        d="M24.4493 29.7529L20.1129 22.6614L14.8981 31.1984H17.4616L12.8378 38.6333H16.0321L11.5121 45.6849H18.404H21.8219H27.9551"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8219 45.6848H18.3959V52.2013H21.8219V45.6848Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3731 45.6849H38.0494H41.4753H48.3592L43.8472 38.6333H47.0335L42.4097 31.1984H44.9732L39.7663 22.6614L35.1584 30.1921"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.4674 45.6848H38.0414V52.2013H41.4674V45.6848Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8652 35.0879H21.1277L26.5502 26.3593H23.5395L29.6647 16.3369L35.782 26.3593H32.7713L38.1937 35.0879H34.4563L39.759 43.3614H19.5625L24.8652 35.0879Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3731 43.3611H27.9471V52.2015H31.3731V43.3611Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9513 28.9304C11.9513 28.9304 3.86953 24.61 10.2583 14.0845C10.2583 14.0845 10.8173 22.9089 14.954 20.9444C20.105 18.5007 14.371 14.0845 14.371 14.0845C14.371 14.0845 18.0206 11.4092 21.8139 15.7775C21.8139 15.7775 23.9302 12.8147 21.8139 9.99568C19.6977 7.17664 22.7563 3.79858 22.7563 3.79858C22.7563 3.79858 23.3153 7.35233 25.751 7.04088C29.6482 6.54575 30.5426 10.0037 30.9658 12.5352C30.9658 12.5352 35.0546 10.9859 34.3519 5.77111C34.3519 5.77111 38.8639 6.76137 39.1434 11.1297C39.423 15.498 43.7913 20.3854 46.0513 12.9585C46.0513 12.9585 51.7852 20.3055 46.1471 26.1113"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6551_27348">
        <rect
          width="42.4293"
          height="50"
          fill="white"
          transform="translate(7 3)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SubsectionsExposureWeather;
