import React from 'react';

export interface Props {
  color: string;
  size?: number;
}

const RoomsBedroom: React.FC<Props> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_5205_36855)">
      <path
        d="M2.67993 16.1824H21.32H21.3109M2.67993 16.1824L4.73796 9.70012H19.2529M2.67993 16.1824V20.5614H5.56298V18.9839H18.4279V20.5614H21.3109V16.1824M19.2529 9.70012L21.3109 16.1824M19.2529 9.70012V5.36649C19.2529 4.43267 18.5004 3.68018 17.5666 3.68018H6.43333C5.49951 3.68018 4.74702 4.43267 4.74702 5.36649V9.70012M12 9.70012V8.22233M12 8.22233C12 7.47891 11.4016 6.88054 10.6582 6.88054H9.11692C8.37349 6.88054 7.77513 7.47891 7.77513 8.22233V9.70012M12 8.22233C12 7.47891 12.5983 6.88054 13.3418 6.88054H14.883C15.6264 6.88054 16.2248 7.47891 16.2248 8.22233V9.70012"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5205_36855">
        <rect
          width="20"
          height="18.2412"
          fill="white"
          transform="translate(2 3)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RoomsBedroom;
