'use client';

import * as Sentry from '@sentry/react';

import {
  useModalContext,
  ModalErrorView,
  ModalView,
  ModalContextType,
  ModalType,
  defaultPlace,
} from '../../hooks/useModal';
import { Modal, ModalContent } from '@nextui-org/react';
import { useEffect, useState, FC } from 'react';
import { getCookie } from '../../utils/getCookie';
import BaseContent from './components/BaseContent';
import ErrorContent from './components/ErrorContent';
import PreviewContent from './components/PreviewContent';
import RetrievingContent from './components/RetrievingContent';
import SecondaryForm from './components/SecondaryForm';
import { isPlaceValid } from '@propertylens/app-commons/dist/placeUtils';
import { SelectedPlace } from '@propertylens/app-commons/dist/types';
import { SENTRY_ERRORS } from '@propertylens/app-commons/dist/constants';
import { useDisclosure } from '@nextui-org/react';
import { useMyReportsContext } from '../../hooks/useMyReports';
import { removeNullValues } from '../../utils/removeNullValues';
import { setCookie, emitTrackingEvent } from '@propertylens/app-commons';

type Position = {
  coords: {
    latitude: number;
    longitude: number;
  };
};
type UserEmailRecord = {
  email: string;
};
type AuthProviderData = {
  user: {
    emails?: UserEmailRecord[];
  };
  isInitialized: boolean;
};
type Props = {
  googleAPIKey: string;
  navigate: (url: string) => void;
  authProvider: () => AuthProviderData;
};

const mapParamsToReportPayload = (params: URLSearchParams) => {
  let reportPayload: SelectedPlace = {
    address1: params.get('address_1'),
    address2: params.get('address_2'),
    locality: params.get('locality'),
    state: params.get('state'),
    zipCode: params.get('zip_code'),
    gmapsPlaceId: params.get('gmaps_place_id'),
    latitude: null,
    longitude: null,
  };
  const latitude = params.get('latitude');
  const longitude = params.get('longitude');
  if (latitude) {
    reportPayload.latitude = latitude ? parseFloat(latitude) : null;
  }
  if (longitude) {
    reportPayload.longitude = longitude ? parseFloat(longitude) : null;
  }
  return reportPayload;
};

export enum ValidationState {
  Idle = 'idle',
  Loading = 'loading',
}

export const GetReportsModal: FC<Props> = ({
  googleAPIKey,
  navigate,
  authProvider,
}) => {
  const { onOpenChange } = useDisclosure();
  const { getReports } = useMyReportsContext();
  const { user, isInitialized } = authProvider();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const {
    modalType,
    closeModal,
    setAddressInputError,
    setSelectedPlace,
    setValidPlace,
    setUserCredit,
    digitalPurchase,
    setOrderComplete,
    setIsAutoRedeem,
    modalView,
    setModalView,
    setModalErrorView,
    setShouldBePopulated,
    openGetReports,
    savePreviewReportImage,
    setIsSamePageRedirect,
    setAutocompleteInputValue,
    setExistingReport,
    setUserCoordinates,
    setWarning,
    setIsAllowed,
    hasCredit,
    addressLookupRedirectURL,
  } = useModalContext() as ModalContextType;
  const getReportsModalOpen = modalType === ModalType.GetReports;
  const [desiredCreditsToPurchase, setDesiredCreditsToPurchase] =
    useState<number>(1);

  const handleCreditsChange = (credit: number) => {
    setDesiredCreditsToPurchase(credit);
  };

  //  CHORD INTEGRATION
  //  This is specifically for handling users coming from chord(buy.propertylens.com)
  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === '/reports') {
      const queryParams = new URLSearchParams(window.location.search);
      const chordUser = queryParams.get('chord_customer_id');
      const digitalPurchaseId = queryParams.get('digital_purchase_id');
      const digitalPurchaseIdInt = digitalPurchaseId
        ? parseInt(digitalPurchaseId)
        : undefined;

      const reportsPayload = mapParamsToReportPayload(queryParams);
      if (chordUser && digitalPurchaseIdInt) {
        openGetReports();
        getReport(digitalPurchaseIdInt, reportsPayload, true);
      }
    }
  }, []);
  //  CHORD INTEGRATION END

  useEffect(() => {
    function geoSuccess(position: Position) {
      const { latitude, longitude } = position.coords;
      // get user coordinates for closer address predictions
      setUserCoordinates({ lat: latitude, lng: longitude });
    }
    function geoError() {
      console.log('Unable to retrieve your location');
    }

    if (getReportsModalOpen) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
      } else {
        console.log('Geolocation not supported');
      }
    }
  }, [getReportsModalOpen]);

  useEffect(() => {
    if (user && isInitialized) {
      setUserCredit();
      setIsLoggedIn(true);
    }
  }, [user, isInitialized]);

  const getReport = async (
    digitalPurchaseId: number,
    selectedPlace: SelectedPlace,
    fromParams = false
  ) => {
    setModalView(ModalView.Retrieving);
    try {
      if (digitalPurchaseId && isPlaceValid(selectedPlace)) {
        const redeemOrderRequest = await fetch('/api/orders', {
          method: 'POST',
          body: JSON.stringify({ digitalPurchaseId }),
        });
        if (redeemOrderRequest.status !== 200) {
          setModalView(ModalView.Error);
          setModalErrorView(ModalErrorView.ErrorOnPreview);
          return;
        }
        const reportResponse = await fetch('/api/reports', {
          method: 'POST',
          body: JSON.stringify(selectedPlace),
        });
        const reportData = await reportResponse.json();
        if (reportResponse.status !== 200) {
          setModalView(ModalView.Error);
          setModalErrorView(ModalErrorView.ReportAPIDown);
          emitTrackingEvent('Error Creating Report', {
            address: {
              ...selectedPlace,
            },
            digitalPurchaseId,
          });
          return;
        }
        const {
          content: {
            report_from_cache: reportFromCache = false,
            report_id: reportId = '',
          } = {},
        } = reportData;
        if (reportFromCache) {
          window.location.href = '/reports';
        } else {
          setModalView(ModalView.Retrieving);
          // Update state to show the pending report
          savePreviewReportImage(reportId);
          getReports();
        }
        const appendMetadataRequest = await fetch(
          '/api/orders/digital-purchases',
          {
            method: 'POST',
            body: JSON.stringify({
              digitalPurchaseId,
              metadata: {
                address: {
                  ...selectedPlace,
                },
                reportId,
              },
            }),
          }
        );
        if (appendMetadataRequest.status !== 200) {
          Sentry.captureMessage(SENTRY_ERRORS['UPDATE_METADATA_ERROR']);
          Sentry.captureException({
            data: {
              digitalPurchaseId,
              selectedPlace,
              reportId,
            },
          });
        }
        setUserCredit();
      } else if (!isPlaceValid(selectedPlace) || !digitalPurchaseId) {
        if (fromParams) {
          // Track errors from chord integration
          Sentry.captureMessage(SENTRY_ERRORS['FROM_PARAMS_ERROR']);
          Sentry.captureException({
            data: {
              digitalPurchaseId,
              selectedPlace,
            },
          });
          alert('Invalid place or digital purchase id 2');
        }
        setModalView(ModalView.Error);
        // todo: fetch orders and get the order that has the address that this user is trying to redeem
        // todo: if this fails fire a tracking event with the data to create the report manually
      }
    } catch (error) {
      setModalView(ModalView.Error);
      setModalErrorView(ModalErrorView.ErrorOnPreview);
    }
  };
  const handleChordReport = async (
    selectedPlace: SelectedPlace,
    previewImageUrl: string | null
  ) => {
    if (!hasCredit) {
      if (previewImageUrl) {
        // maybe use state insteasd firest test
        localStorage.setItem(
          `checkoutImageUrl-${selectedPlace.gmapsPlaceId}`,
          previewImageUrl
        );
      }
      const filteredPlace = removeNullValues(selectedPlace);
      const addressParams = new URLSearchParams(filteredPlace).toString();
      const redirectParams = new URLSearchParams({
        address: encodeURIComponent(addressParams),
      }).toString();
      navigate(`${addressLookupRedirectURL}?${redirectParams}`);
      closeModal();
    } else if (digitalPurchase?.id) {
      try {
        if (digitalPurchase?.id && isPlaceValid(selectedPlace)) {
          const filteredPlace = removeNullValues(selectedPlace);
          const handoffParams = new URLSearchParams({
            ...filteredPlace,
            digitalPurchaseId: digitalPurchase?.id.toString(),
            intent: 'REDEEM',
            pagestate: 'pending',
            ...(user.emails &&
              user.emails.length > 0 && { email: user.emails[0].email }),
          }).toString();
          const reportToRedeem = {
            selectedPlace: { ...filteredPlace },
            digitalPurchaseId: digitalPurchase?.id.toString(),
            ...(user.emails &&
              user.emails.length > 0 && { email: user.emails[0].email }),
          };
          setCookie('reportToRedeem', JSON.stringify(reportToRedeem));
          window.location.href = `${process.env.NEXT_PUBLIC_IN_APP_DOMAIN}/reports/pending?${handoffParams}`;
        } else if (!isPlaceValid(selectedPlace) || !digitalPurchase?.id) {
          setModalView(ModalView.Error);
        }
      } catch (error) {
        setModalView(ModalView.Error);
        setModalErrorView(ModalErrorView.ErrorOnPreview);
      }
    }
  };

  const handleRedirectToCheckout = () => {
    setIsAutoRedeem(true);
    setModalView(ModalView.Idle);
    closeModal();
    setOrderComplete(false);
    if (window.location.href.includes('buy-reports')) {
      setIsSamePageRedirect(true);
    }

    navigate(`/buy-reports?creditAmount=${desiredCreditsToPurchase}`);
  };

  const onModalClose = () => {
    emitTrackingEvent('Address Cancelled');
    setModalView(ModalView.Idle);
    setSelectedPlace(defaultPlace);
    setValidPlace(false);
    setDesiredCreditsToPurchase(1);
    setWarning({ message: null, type: 'info' });
    setIsAllowed(true);
    setAutocompleteInputValue('');
    setAddressInputError(false);
    setShouldBePopulated(true);
    setExistingReport(null);
    closeModal();
  };

  const handleOnBack = () => {
    emitTrackingEvent('Address Revised');
    setWarning({ message: null, type: 'info' });
    setSelectedPlace(defaultPlace);
    setAutocompleteInputValue('');
    setExistingReport(null);
    setModalView(ModalView.Idle);
  };

  return (
    <Modal
      isOpen={getReportsModalOpen}
      onOpenChange={onOpenChange}
      onClose={onModalClose}
      placement="center"
      size="full"
      className="p-5 md:h-auto md:min-h-[500px] md:min-w-[600px] md:max-w-md md:!rounded-lg md:p-8"
    >
      <ModalContent>
        {() => {
          switch (modalView) {
            case 'preview':
              const previewContentProps = {
                desiredCreditsToPurchase,
                handleCreditsChange,
                handleRedirectToCheckout,
                onModalClose,
                handleOnBack,
                getReport,
                digitalPurchaseId: digitalPurchase?.id,
                handleChordReport,
                isLoggedIn,
                navigate,
              };
              return <PreviewContent {...previewContentProps} />;

            case 'error':
              return <ErrorContent />;

            case 'retrieving':
              return <RetrievingContent onModalClose={onModalClose} />;

            case 'secondary_form':
              return <SecondaryForm googleAPIKey={googleAPIKey} />;

            default:
              const baseContentProps = {
                onModalClose,
                navigate,
                googleAPIKey,
                handleOnBack,
              };
              return <BaseContent {...baseContentProps} />;
          }
        }}
      </ModalContent>
    </Modal>
  );
};

export default GetReportsModal;
