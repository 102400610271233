'use client';

import { useEffect, useState, FC } from 'react';
import {
  useModalContext,
  ModalErrorView,
  ModalView,
  ModalContextType,
} from '../../../hooks/useModal';
import SectionHeadingGroup from '../../SectionHeadingGroup';
import Button from '../../Button';
import { ModalHeader, ModalBody } from '@nextui-org/react';

const ErrorContent: FC = () => {
  const {
    modalErrorView,
    closeModal,
    setModalView,
    setModalErrorView,
    setUserCredit,
    selectedPlace,
  } = useModalContext() as ModalContextType;

  const [errorDescription, setErrorDescription] = useState<string>(
    "Let's try that again?"
  );
  const [additionalErrorDescription, setAdditionalErrorDescription] =
    useState<string | undefined>();
  const [errorButtonLabel, setErrorButtonLabel] =
    useState<string>('Try again');

  useEffect(() => {
    switch (modalErrorView) {
      case ModalErrorView.ErrorOnBase:
        setErrorDescription(
          'Something went wrong. Please try again or contact support.'
        );
        break;
      case ModalErrorView.ReportAPIDown:
        const { address1, address2, locality, state, zipCode } = selectedPlace;
        const address = `${address1}${address2 ? ` ${address2}` : ``}, ${locality}, ${state} ${zipCode}`;
        setErrorDescription(
          `Hmm. It appears we’re experiencing some technical issues running your report for ${address}.`
        );
        setAdditionalErrorDescription(
          'We apologize for the delay. Rest assured, we are actively working on a solution and will notify you via email as soon as your report is ready.'
        );
        setErrorButtonLabel('Acknowledge');
        break;
      case ModalErrorView.ErrorOnPreview:
        setErrorDescription(
          'Seems like we ran into an issue while trying to redeem your report.'
        );
        break;
      default:
        break;
    }
  }, []);

  const handleClick = (errorType: ModalErrorView) => {
    switch (errorType) {
      case ModalErrorView.ErrorOnBase:
        setModalView(ModalView.Idle);
        setModalErrorView(ModalErrorView.None);
        break;
      case ModalErrorView.ReportAPIDown:
        closeModal();
        break;
      case ModalErrorView.ErrorOnPreview:
        setUserCredit();
        setModalView(ModalView.Preview);
        setModalErrorView(ModalErrorView.None);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ModalHeader className="mb-8 block p-0">
        <SectionHeadingGroup
          heading="Something went wrong"
          level={2}
          classNames={{
            base: 'mt-0 mb-4',
            description: 'mt-4',
            heading: 'font-[raleway]'
          }}
          alwaysVisible={true}
        />
        <hr />
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="flex flex-col gap-8">
          <p>{errorDescription}</p>
          {additionalErrorDescription && <p>{additionalErrorDescription}</p>}
          <div className="flex justify-end gap-2">
            <Button
              color="primary"
              type="button"
              radius="sm"
              onClick={() => {
                handleClick(modalErrorView);
              }}
              size="lg"
            >
              {errorButtonLabel}
            </Button>
          </div>
        </div>
      </ModalBody>
    </>
  );
};

export default ErrorContent;
