import React from 'react';

export interface RoomsLivingRoomProps {
  color: string;
  size?: number;
}

const RoomsLivingRoom: React.FC<RoomsLivingRoomProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17 10V9C17 7.89543 16.1046 7 15 7H7C5.89543 7 5 7.89543 5 9V10M6 15V12C6 10.8954 5.10457 10 4 10V10C2.89543 10 2 10.8954 2 12V17C2 18.1046 2.89543 19 4 19H18C19.1046 19 20 18.1046 20 17V12C20 10.8954 19.1046 10 18 10V10C16.8954 10 16 10.8954 16 12V15H6Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M10.75 15L10.75 7" stroke={color} strokeWidth="1.5" />
    </svg>
  );
};

export default RoomsLivingRoom;
