import React from 'react';
import { BuilderComponent } from '@builder.io/react';
import { CheckoutProvider } from '~/context/checkout';
import 'cms/builder/registry';

interface Props {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  header: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  footer: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  page: any;
}

const Layout: React.FC<React.PropsWithChildren<Props>> = ({
  header,
  children,
  footer,
  page,
}) => {
  const hideNav = page?.data?.hideNav;
  const hideFooter = page?.data?.hideFooter;

  return (
    <>
      {!hideNav && (
        <header className="header fixed-top pt-0">
          <BuilderComponent model="header" content={header} />
        </header>
      )}

      <main role="main" id="main-content-wrapper">
        <CheckoutProvider>{children}</CheckoutProvider>
      </main>

      {!hideFooter && (
        <footer>
          <BuilderComponent model="footer" content={footer} />
        </footer>
      )}
    </>
  );
};

export default Layout;
