import './DetailedList.scss';

import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { BREAKPOINTS } from '../../constants';
import AnimateChangeInHeight from '../../global/AnimateChangeInProps';
import SectionHeading from '../SectionHeading';
import formatValue from './formatValue';
import { GradeType, ListValueType } from '../../types';

export enum ListValueEnumType {
  text = 'text',
  number = 'number',
  date = 'date',
  currency = 'currency',
  grade = 'grade',
  percentage = 'percentage',
}

type DetailedListProps = {
  className?: string;
  icon?: string;
  isStacked?: boolean;
  isPadded?: boolean;
  list: {
    id: string;
    label: string | number;
    value?: ListValueType;
    type?: ListValueEnumType;
    isStacked?: boolean;
    grade?: GradeType | number;
    formatCase?: string;
  }[];
  viewport?: 'mobile' | 'desktop';
  title?: string;
  variant?: 'default' | 'light';
};

const DetailedList: React.FC<DetailedListProps> = (props) => {
  const { list, isStacked, isPadded = true, title, className, variant } = props;
  const [storedList, setStoredList] = useState(list.slice(0, 3));
  const [isDesktop, setIsDesktop] = useState(false);
  const [showAll, setShowAll] = useState(storedList.length === list.length);
  const windowSize = [1024, 1024];

  useEffect(() => {
    setStoredList(list);
  }, [list]);

  useEffect(() => {
    if (windowSize[0] > BREAKPOINTS.md) {
      setIsDesktop(true);
      setStoredList(list);
      setShowAll(true);
    }
  }, []);

  useEffect(() => {
    if (windowSize[0] > BREAKPOINTS.sm && !isDesktop && !showAll) {
      setIsDesktop(true);
      setStoredList(list);
      setShowAll(true);
    }
  });

  function handleShowMore() {
    setStoredList(list);
    setShowAll(true);
  }

  function handleShowLess() {
    setStoredList(list.slice(0, 3));
    setShowAll(false);
  }
  const divCx = cx('DetailedList', {
    stacked: isStacked,
    [`variant-${variant}`]: variant,
    [`${className}`]: className,
    ['padded']: isPadded,
  });

  return (
    <div className={divCx}>
      {title && <SectionHeading level={4}>{title}</SectionHeading>}
      <AnimateChangeInHeight>
        <div className="list-grouping mt-2">
          <ul className="list">
            {storedList.map((item) => {
              let ValueComponent;
              const listItemCx = cx('list-item', {
                stacked: item.isStacked,
              });
              const valueCx = cx('value w-full', {
                'grayed-out': item.value === undefined || item.value === null,
              });

              return (
                <li className={listItemCx} key={item.id}>
                  <div className="label mr-auto leading-7">{item.label}</div>
                  <div className={valueCx}>
                    {React.isValidElement(item.value)
                      ? item.value
                      : formatValue({
                          grade: item.grade as GradeType,
                          type: item.type,
                          value: item.value as string, // Type assertion
                          formatCase: item.formatCase,
                        })}
                  </div>
                </li>
              );
            })}
          </ul>
          {!isDesktop && list.length > 3 && (
            <div className="show-more">
              {showAll ? (
                <button
                  type="button"
                  onClick={() => {
                    handleShowLess();
                  }}
                >
                  Show less
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    handleShowMore();
                  }}
                >
                  Show more
                </button>
              )}
            </div>
          )}
        </div>
      </AnimateChangeInHeight>
    </div>
  );
};

export default DetailedList;
