import './ImageHeader.scss';

type ImageHeaderProps = {
  thumbnail: string;
  address: string;
};

function ImageHeader(props: ImageHeaderProps) {
  const { thumbnail, address } = props;
  return (
    <div className="ImageHeader">
      <div
        className="thumbnail"
        style={{ backgroundImage: `url("${thumbnail}")` }}
      />
      <div className="address">{address}</div>
    </div>
  );
}

export default ImageHeader;
