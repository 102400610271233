import React from 'react';

export interface FormsEmailProps {
  color: string;
  size?: number;
}

const FormsEmail: React.FC<FormsEmailProps> = ({ color, size = 24 }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1162 8.11612C4.35062 7.8817 4.66856 7.75 5.00008 7.75H19.0001C19.3316 7.75 19.6495 7.8817 19.884 8.11612C20.0301 8.26225 20.1363 8.44084 20.1956 8.63494L12.0001 14.0986L3.80457 8.63494C3.86385 8.44084 3.97006 8.26225 4.1162 8.11612ZM2.25011 8.98621C2.24997 8.99423 2.24996 9.00225 2.25008 9.01026V19C2.25008 19.7293 2.53981 20.4288 3.05554 20.9445C3.57126 21.4603 4.27073 21.75 5.00008 21.75H19.0001C19.7294 21.75 20.4289 21.4603 20.9446 20.9445C21.4603 20.4288 21.7501 19.7293 21.7501 19V9.01023M20.2501 10.4014V19C20.2501 19.3315 20.1184 19.6495 19.884 19.8839C19.6495 20.1183 19.3316 20.25 19.0001 20.25H5.00008C4.66856 20.25 4.35062 20.1183 4.1162 19.8839C3.88178 19.6495 3.75008 19.3315 3.75008 19V10.4014L11.5841 15.624C11.836 15.792 12.1642 15.792 12.4161 15.624L20.2501 10.4014ZM21.75 8.98624C21.7464 8.26186 21.4571 7.56794 20.9446 7.05546C20.4289 6.53973 19.7294 6.25 19.0001 6.25H5.00008C4.27073 6.25 3.57126 6.53973 3.05554 7.05546C2.54306 7.56793 2.25374 8.26184 2.25011 8.98621"
        fill={color}
      />
    </svg>
  );
};

export default FormsEmail;
