import React from 'react';

export interface RisksThunderstormLgProps {
  color: string;
  size?: number;
}

const RisksThunderstormLg: React.FC<RisksThunderstormLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M40 30.7469L44.5441 30.7469C47.2768 30.7469 49.5 28.5237 49.5 25.791C49.5 24.467 48.9844 23.2225 48.0484 22.2868C47.2643 21.5024 46.2635 21.0133 45.1808 20.8754C44.8671 18.4424 42.7825 16.5566 40.2656 16.5566C39.8273 16.5566 39.3968 16.6132 38.9796 16.7253C38.3554 15.5046 37.435 14.4615 36.2901 13.6839C34.9359 12.7643 33.3517 12.2782 31.7087 12.2782C30.9818 12.2782 30.2643 12.3735 29.569 12.5619C28.6939 11.2608 27.527 10.1676 26.1681 9.38078C24.608 8.47748 22.8253 8 21.0128 8C19.6221 8 18.2726 8.27251 17.0018 8.80998C15.7747 9.32896 14.6728 10.0718 13.7267 11.0178C12.7807 11.9639 12.0378 13.0658 11.5187 14.2928C10.9812 15.5636 10.7087 16.9131 10.7087 18.3038C10.7087 19.2314 10.8314 20.1454 11.0741 21.0304C8.98931 21.6327 7.5 23.5555 7.5 25.791C7.5 27.1153 8.01559 28.3599 8.95146 29.295C9.88697 30.2313 11.1316 30.7469 12.4559 30.7469L18 30.7469"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M24 31H33" stroke={color} strokeWidth="2" />
    <path
      d="M29 36L35.5 28H41.5L36.5 34H40L31.5 44.5L33 36H29Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 36L20.5 28H26.5L21.5 34H25L16.5 44.5L18 36H14Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.0882 40L18 49"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.1503 42L25 46"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.6383 48L41.7266 39"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.5762 45L44.7266 41"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.00107 48L13.7266 42"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.57624 42L10.7266 38"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5 14C19 14.1667 16 15.4 16 19"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default RisksThunderstormLg;
