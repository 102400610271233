import React from 'react';

export interface SpaceMapProps {
  color: string;
  size?: number;
}

const SpaceMap: React.FC<SpaceMapProps> = ({ color, size = 24 }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.0635 5.93443L9.03175 3.31148M15.0635 5.93443L19.5489 3.00865C20.2141 2.57477 21.0952 3.05206 21.0952 3.84622V16.5403C21.0952 16.8782 20.9246 17.1933 20.6416 17.3779L15.0635 21.0164M15.0635 5.93443V21.0164M9.03175 3.31148L3.48681 6.62695C3.18486 6.8075 3 7.13342 3 7.48523V20.2369C3 21.0136 3.84664 21.4938 4.51319 21.0952L9.03175 18.3934M9.03175 3.31148V18.3934M9.03175 18.3934L15.0635 21.0164"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SpaceMap;
