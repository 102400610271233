import React from 'react';

export interface AmenitiesWifiProps {
  color: string;
  size?: number;
}

const AmenitiesWifi: React.FC<AmenitiesWifiProps> = ({ color, size = 24 }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5939_17236)">
        <path
          d="M3.26035 8.40525C2.91322 8.75238 2.91322 9.31519 3.26035 9.66233C3.60748 10.0095 4.1703 10.0095 4.51743 9.66233C9.03016 5.1496 16.3467 5.1496 20.8595 9.66233C21.2066 10.0095 21.7694 10.0095 22.1166 9.66233C22.4637 9.31519 22.4637 8.75238 22.1166 8.40525C16.9096 3.19825 8.46735 3.19825 3.26035 8.40525Z"
          fill={color}
        />
        <path
          d="M5.77424 12.1765C5.42711 11.8294 5.42711 11.2665 5.77424 10.9194C9.5927 7.10095 15.7837 7.10095 19.6021 10.9194C19.9493 11.2665 19.9493 11.8294 19.6021 12.1765C19.255 12.5236 18.6922 12.5236 18.345 12.1765C15.2208 9.05229 10.1555 9.05229 7.03132 12.1765C6.68419 12.5236 6.12137 12.5236 5.77424 12.1765Z"
          fill={color}
        />
        <path
          d="M8.28857 13.4335C7.94143 13.7807 7.94143 14.3435 8.28857 14.6906C8.6357 15.0377 9.19851 15.0377 9.54565 14.6906C11.2813 12.9549 14.0954 12.9549 15.831 14.6906C16.1782 15.0377 16.741 15.0377 17.0881 14.6906C17.4353 14.3435 17.4353 13.7807 17.0881 13.4335C14.6582 11.0036 10.7185 11.0036 8.28857 13.4335Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6884 15.1667C11.2156 15.1667 10.0217 16.3606 10.0217 17.8333C10.0217 19.3061 11.2156 20.5 12.6884 20.5C14.1611 20.5 15.355 19.3061 15.355 17.8333C15.355 16.3606 14.1611 15.1667 12.6884 15.1667ZM11.7995 17.8333C11.7995 17.3424 12.1974 16.9444 12.6884 16.9444C13.1793 16.9444 13.5772 17.3424 13.5772 17.8333C13.5772 18.3243 13.1793 18.7222 12.6884 18.7222C12.1974 18.7222 11.7995 18.3243 11.7995 17.8333Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5939_17236">
          <rect
            width="25"
            height="24"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AmenitiesWifi;
