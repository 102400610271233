// import './MarkerStateAverage.scss';

import { motion } from 'framer-motion';
import React, { useRef } from 'react';

type MarkerStateAverageProps = {
  value: number;
  clientWidth: number;
};
const MarkerStateAverage: React.FC<MarkerStateAverageProps> = (props) => {
  const { clientWidth, value = 100 } = props;
  const elementRef = useRef(null);
  const markerPosition = (value / 100) * clientWidth - 16;

  return (
    <motion.div
      ref={elementRef}
      className="MarkerStateAverage"
      initial={{ x: 0 }}
      animate={{ x: `${markerPosition}px` }}
      transition={{ ease: 'easeInOut', duration: 2 }}
    >
      <div className="stage-average-marker">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
        >
          <circle
            cx="6"
            cy="6.02344"
            r="4.5"
            fill="white"
            stroke="#7B8D9D"
            strokeWidth="3"
          />
        </svg>
      </div>
    </motion.div>
  );
};

export default MarkerStateAverage;
