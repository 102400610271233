import React from 'react';

export interface HomeRoofProps {
  color: string;
  size?: number;
}

const HomeRoof: React.FC<HomeRoofProps> = ({ color, size = 24 }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_36579)">
        <path
          d="M2.67981 20.3733L6.12441 8.33105M6.12441 8.33105L10.8645 19.5975H23.5329L18.4726 8.33105H16.5493M6.12441 8.33105H13.8502"
          stroke={color}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M10.4796 8.33057L14.9649 19.3528"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M15.9966 9.74009V5.77588H13.4344V9.74009"
          stroke={color}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <line
          x1="7.14905"
          y1="11.0469"
          x2="11.5048"
          y2="11.0469"
          stroke={color}
          strokeWidth="1.5"
        />
        <line
          x1="8.81592"
          y1="13.5947"
          x2="13.1716"
          y2="13.5947"
          stroke={color}
          strokeWidth="1.5"
        />
        <line
          x1="9.96765"
          y1="16.4131"
          x2="14.3234"
          y2="16.4131"
          stroke={color}
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_36579">
          <rect
            width="24"
            height="15.5166"
            fill="white"
            transform="translate(1 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HomeRoof;
