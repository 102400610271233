import React, { FC } from 'react';

export interface RisksEarthquakeSm {
  color: string;
  size?: number;
}

const RisksEarthquakeSm: FC<RisksEarthquakeSm> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.96689 9.33442H10.9306L10.2619 11.18L9.734 12.6371L11.2042 12.147L15.1315 10.8379L15.9519 10.5645L15.5651 9.79101L14.5174 7.69554L16.7783 5.75H20C20.1381 5.75 20.25 5.86193 20.25 6V17.6364C20.25 17.7744 20.1381 17.8864 20 17.8864H4C3.86193 17.8864 3.75 17.7744 3.75 17.6364V6C3.75 5.86193 3.86193 5.75 4 5.75H10.7123L9.31452 8.21439L8.67923 9.33442H9.96689Z"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
);

export default RisksEarthquakeSm;
