import React from 'react';

export interface DocumentsClipboardProps {
  color: string;
  size?: number;
}

const DocumentsClipboard: React.FC<DocumentsClipboardProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_5205_37223)">
      <path
        d="M17.3007 19.3384L15.9457 17.9834C15.6526 18.1109 15.3275 18.1778 14.9869 18.1685C13.7427 18.1348 12.7615 17.0989 12.7952 15.8547C12.829 14.6105 13.8649 13.6293 15.1091 13.663C16.3532 13.6967 17.3345 14.7327 17.3007 15.9768C17.2983 16.0671 17.2906 16.1561 17.2779 16.2433"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.374 6.09668H7.73534C7.30074 6.09668 6.88394 6.26932 6.57663 6.57663C6.26932 6.88394 6.09668 7.30074 6.09668 7.73534V17.5673C6.09668 18.0019 6.26932 18.4187 6.57663 18.726C6.88394 19.0333 7.30074 19.206 7.73534 19.206H10.1933M17.5673 11.832V7.73534C17.5673 7.30074 17.3947 6.88394 17.0874 6.57663C16.7801 6.26932 16.3633 6.09668 15.9287 6.09668H14.29"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.37402 6.09667C9.37402 5.66207 9.54667 5.24527 9.85398 4.93796C10.1613 4.63065 10.5781 4.45801 11.0127 4.45801H12.6513C13.0859 4.45801 13.5027 4.63065 13.8101 4.93796C14.1174 5.24527 14.29 5.66207 14.29 6.09667C14.29 6.53127 14.1174 6.94807 13.8101 7.25538C13.5027 7.56269 13.0859 7.73533 12.6513 7.73533H11.0127C10.5781 7.73533 10.1613 7.56269 9.85398 7.25538C9.54667 6.94807 9.37402 6.53127 9.37402 6.09667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5205_37223">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DocumentsClipboard;
