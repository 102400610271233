import React, { FC } from 'react';

export interface NavigationalCaretDownProps {
  color: string;
  size?: number;
}

const NavigationalCaretDown: FC<NavigationalCaretDownProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect width="24" height="24" fill="white" />
      <path
        d="M15.5 9.5L11.5 14.5L7.5 9.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NavigationalCaretDown;
