import './Marker.scss';

import cx from 'classnames';
import { motion } from 'framer-motion';
import React, { useRef } from 'react';

import { getSpotlightColor, RatingType } from '../../utils/spotlightScoreUtils';

type MarkerProps = {
  value: number;
  position: 'top' | 'bottom';
  level: RatingType;
  clientWidth: number;
};

const Marker: React.FC<MarkerProps> = (props) => {
  const { clientWidth = 100, level, position, value } = props;
  const elementRef = useRef(null);
  const markerPosition = (value / 100) * clientWidth - 16;

  const divCx = cx('Marker', { [position]: position });
  const levelColor = getSpotlightColor(level);

  return (
    <motion.div
      ref={elementRef}
      className={divCx}
      initial={{ x: 0 }}
      animate={{ x: `${markerPosition}px` }}
      transition={{ ease: 'easeInOut', duration: 2 }}
    >
      <div className="value">{value}</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37"
        height="43"
        viewBox="0 0 37 43"
        fill="none"
      >
        <motion.path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 0.214844C1.79086 0.214844 0 2.0057 0 4.21484V32.2148C0 34.424 1.79086 36.2148 4 36.2148H11.7395C11.8913 36.4284 12.0648 36.6281 12.2585 36.8105L18.1293 42.3359C18.8994 43.0607 20.1006 43.0607 20.8707 42.3359L26.7415 36.8105C26.9352 36.6281 27.1087 36.4284 27.2605 36.2148H33C35.2091 36.2148 37 34.424 37 32.2148V4.21484C37 2.0057 35.2091 0.214844 33 0.214844H4Z"
          fill="#CCCCCC"
          animate={{ fill: levelColor }}
          transition={{ ease: 'easeInOut', duration: 1 }}
        />
      </svg>
    </motion.div>
  );
};

export default Marker;
