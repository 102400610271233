import React from 'react';

type RoomsBabyRoomProps = {
  size?: number;
  color: string;
};

const RoomsBabyRoom: React.FC<RoomsBabyRoomProps> = ({ color, size = 24 }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_36906)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 5.25C6.5 5.68332 6.27951 6.06515 5.9446 6.2894C5.94816 6.31906 5.95 6.34926 5.95 6.37988V7.62988H18.45V6.37988C18.45 6.34925 18.4518 6.31905 18.4554 6.28938C18.1205 6.06512 17.9 5.68331 17.9 5.25C17.9 4.55965 18.4597 4 19.15 4C19.8404 4 20.4 4.55965 20.4 5.25C20.4 5.6436 20.2181 5.99472 19.9337 6.22385C19.9444 6.27418 19.95 6.32638 19.95 6.37988V18.3799C19.95 18.7941 19.6142 19.1299 19.2 19.1299C18.7858 19.1299 18.45 18.7941 18.45 18.3799V18.1299H5.95V18.3799C5.95 18.7941 5.61421 19.1299 5.2 19.1299C4.78579 19.1299 4.45 18.7941 4.45 18.3799V6.37988C4.45 6.32637 4.4556 6.27417 4.46626 6.22383C4.18192 5.9947 4 5.64359 4 5.25C4 4.55965 4.55965 4 5.25 4C5.94035 4 6.5 4.55965 6.5 5.25ZM18.45 9.12988H15.95V13.6299H18.45V9.12988ZM18.45 15.1299H5.95V16.6299H18.45V15.1299ZM14.45 9.12988H12.95V13.6299H14.45V9.12988ZM9.95 9.12988H11.45V13.6299H9.95V9.12988ZM8.45 9.12988H5.95V13.6299H8.45V9.12988Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_36906">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoomsBabyRoom;
