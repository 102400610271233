import React from 'react';

export interface ActionCopyProps {
  color: string;
  size?: number;
}

const ActionCopy: React.FC<ActionCopyProps> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8 7H5C3.89543 7 3 7.89543 3 9V19C3 20.1046 3.89543 21 5 21H15C16.1046 21 17 20.1046 17 19V16"
      stroke={color}
      strokeWidth="1.5"
    />
    <rect
      x="7.75"
      y="3.75"
      width="12.5"
      height="12.5"
      rx="1.25"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
);

export default ActionCopy;
