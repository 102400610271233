import React from 'react';

export interface RisksThunderstormSmProps {
  color: string;
  size?: number;
}

const RisksThunderstormSm: React.FC<RisksThunderstormSmProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M13.1299 15.3333L11.052 18.1039L13.1299 19.2294L11.052 22M17.7186 16.4589L16.3334 18.2771L17.7186 19.0563L16.3334 20.8745M7.84849 16.4589L6.4632 18.2771L7.84849 19.0563L6.4632 20.8745M8.71429 6.84848C8.10823 6.329 7.24245 5.98268 6.37664 5.98268M16.3334 4.51082C15.6407 4.5974 14.9481 4.85714 14.4286 5.20346M16.7663 4.51082C16.5931 4.51082 16.5065 4.51082 16.3334 4.5974C15.381 3.03896 13.6493 2 11.658 2C9.14719 2 6.9827 3.7316 6.37664 6.06926C4.47188 6.15584 3 7.62771 3 9.53247C3 11.3506 4.38529 12.8225 6.20347 12.9957H16.7663C19.1039 12.9957 21.0087 11.0909 21.0087 8.75325C21.0087 6.41558 19.1039 4.51082 16.7663 4.51082Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksThunderstormSm;
