import { colors } from '@propertylens/app-commons/dist/tailwindBaseConfig';

export const RATINGS: Record<
  'VERY_HIGH' | 'HIGH' | 'MODERATE' | 'LOW' | 'VERY_LOW',
  RatingType
> = {
  VERY_HIGH: 'very-high',
  HIGH: 'high',
  MODERATE: 'moderate',
  LOW: 'low',
  VERY_LOW: 'very-low',
};

export type RatingType =
  | 'very-high'
  | 'high'
  | 'moderate'
  | 'low'
  | 'very-low'
  | 'average';

export const levelMap: Record<RatingType, string> = {
  'very-high': colors['pl-rating-red'],
  high: colors['pl-rating-orange'],
  moderate: colors['pl-rating-orange'],
  low: colors['pl-rating-light-green'],
  'very-low': colors['pl-rating-green'],
  average: colors['pl-gray'],
};

export const getSpotlightColor = (level: RatingType): string => {
  return levelMap[level];
};

/**
 * Returns the spotlight score based on the rating
 * @param {number} rating - The rating to compare
 * @returns {string} - The spotlight score
 */
export const getSpotlightScore = (rating: number): RatingType => {
  let ratingType: RatingType = RATINGS.VERY_HIGH;
  if (rating > 0 && rating < 25) {
    ratingType = RATINGS.VERY_HIGH;
  } else if (rating >= 25 && rating < 50) {
    ratingType = RATINGS.HIGH;
  } else if (rating >= 50 && rating < 75) {
    ratingType = RATINGS.MODERATE;
  } else if (rating >= 75 && rating < 100) {
    ratingType = RATINGS.LOW;
  } else if (rating >= 100) {
    ratingType = RATINGS.VERY_LOW;
  }

  return ratingType;
};

export default getSpotlightScore;
