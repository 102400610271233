import React from 'react';

export interface RisksWindSmProps {
  color: string;
  size?: number;
}

const RisksWindSm: React.FC<RisksWindSmProps> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M10.75 6.07812C10.9844 6.07812 11.2188 6 11.5313 6C13.7188 6 15.6719 7.17187 16.7656 8.89062C16.9219 8.89062 17.0781 8.8125 17.2344 8.8125C19.8906 8.8125 22 10.9219 22 13.5781C22 16.2344 19.8906 18.3437 17.2344 18.3437H11.6094M3.48438 11.3906H7.70313C8.875 11.3906 9.89062 10.4531 9.89062 9.20312C9.89062 7.95312 8.95313 7.01562 7.70313 7.01562C7.3125 7.01562 6.92188 7.09375 6.60938 7.32812M10.9844 12.4844H13.0156C13.875 12.4844 14.5781 11.7812 14.5781 10.9219C14.5781 10.0625 13.875 9.35937 13.0156 9.35937C12.7031 9.35937 12.4687 9.4375 12.2344 9.59375M6.6875 17.7969C7 17.9531 7.39063 18.1094 7.78125 18.1094C8.95313 18.1094 9.96875 17.1719 9.96875 15.9219C9.96875 14.6719 9.03125 13.7344 7.78125 13.7344H2"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksWindSm;
