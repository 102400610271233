import { FC, HTMLProps } from 'react';

import cx from 'classnames';

type InfoBoxProps = HTMLProps<HTMLDivElement> & {
  color?: 'warning' | 'info' | 'commercialAddress';
  header?: string;
  desktopSize?: 'sm' | 'base';
};

const InfoBox: FC<InfoBoxProps> = ({
  header,
  className,
  children,
  color = 'info',
  desktopSize = 'sm',
  ...rest
}) => {
  const containerCx = cx('InfoBox rounded p-4 text-sm', {
    'bg-pl-light-blue': color === 'info' || color === 'commercialAddress',
    'bg-pl-rating-secondary-red': color === 'warning',
    [`lg:text-${desktopSize}`]: desktopSize,
    [`${className}`]: className,
  });

  return (
    <div className={containerCx} {...rest}>
      {header && <p className="font-bold">{header}</p>}
      <p>{children}</p>
    </div>
  );
};

export default InfoBox;
