import React from 'react';

export interface ActionDownloadPdfProps {
  color: string;
  size?: number;
}

const ActionDownloadPdf: React.FC<ActionDownloadPdfProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_5684_30683)">
      <path
        d="M16.4673 12.52V19.2569M16.4673 19.2569L19.8357 15.8884M16.4673 19.2569L13.0989 15.8884"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7368 11.5789V6C19.7368 4.89543 18.8414 4 17.7368 4L12.9141 4C12.4268 4 11.9563 4.17791 11.5909 4.50032L5.67676 9.71865C5.24648 10.0983 5 10.6445 5 11.2183L5 18C5 19.1046 5.89543 20 7 20H11.7368"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.77075 11.0687H10.5076C11.6122 11.0687 12.5076 10.1733 12.5076 9.06872V4.75293"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <line
        x1="10.2209"
        y1="14.3672"
        x2="7.46563"
        y2="14.3672"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="10.2209"
        y1="16.9204"
        x2="7.46563"
        y2="16.9204"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5684_30683">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="matrix(1 0 0 -1 0 24)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ActionDownloadPdf;
