import React from 'react';

export interface SubsectionsTermsDefinitionsProps {
  color: string;
  size?: number;
}

const SubsectionsTermsDefinitions: React.FC<
  SubsectionsTermsDefinitionsProps
> = ({ color, size = 56 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <circle cx="34" cy="34.833" r="6" stroke={color} strokeWidth="2" />
    <circle cx="34" cy="34.833" r="10" stroke={color} strokeWidth="2" />
    <path
      d="M37 20.8334V5.33344C31 2.3332 23 5.33344 23 5.33344C23 5.33344 23.0017 18.8334 23 24.3332"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 20.8334V5.33344C17 2.3332 9 5.33344 9 5.33344C9 5.33344 9.00174 25.8334 9 31.3332H21"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.5 40.833L47.6347 44.377C49.1285 45.6574 49.102 47.9769 47.5792 49.2227V49.2227C46.1774 50.3697 44.1004 50.1062 43.0293 48.6456L39.5 43.833"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 9.83301C12.5 9.83301 13.2907 9.33301 15.5 9.33301C17.7093 9.33301 19 9.83301 19 9.83301"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 14.333C12.5 14.333 13.2907 13.833 15.5 13.833C17.7093 13.833 19 14.333 19 14.333"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 19.333C12.5 19.333 13.2907 18.833 15.5 18.833C17.7093 18.833 19 19.333 19 19.333"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5 9.83301C26.5 9.83301 27.2907 9.33301 29.5 9.33301C31.7093 9.33301 33 9.83301 33 9.83301"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5 14.333C26.5 14.333 27.2907 13.833 29.5 13.833C31.7093 13.833 33 14.333 33 14.333"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5 19.333C26.5 19.333 27.2907 18.833 29.5 18.833C31.7093 18.833 33 19.333 33 19.333"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 24.333C12.5 24.333 13.2907 23.833 15.5 23.833C17.7093 23.833 19 24.333 19 24.333"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41 22.833V10.333C41 9.22844 40.1046 8.33301 39 8.33301H38"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 8.33301H7C5.89543 8.33301 5 9.22844 5 10.333V33.833C5 34.9376 5.89543 35.833 7 35.833H19.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SubsectionsTermsDefinitions;
