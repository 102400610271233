import React from 'react';

export interface HomeFoundationProps {
  color: string;
  size?: number;
}

const HomeFoundation: React.FC<HomeFoundationProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.5001 4.93073L12.4754 3.5L9.27783 4.93073L12.4754 6.61111L15.5001 4.93073Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <line
        x1="12.3611"
        y1="6.61133"
        x2="12.3611"
        y2="12.8336"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M12.75 15.7778L12.75 20.1112" stroke={color} strokeWidth="1.5" />
      <path
        d="M8.11115 11.112L5.00004 12.4922L5.00004 17.0002L12.3889 20.5002L19.809 16.7677L19.809 12.3759L16.2778 10.7231"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M19.7778 12.6665L12.3889 16.1665L5 12.6665"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M8.50005 4.89893L8.50005 11.1417L12.059 12.8326L16.2778 10.8036L16.2778 4.89953"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default HomeFoundation;
