import React from 'react';

export interface SubsectionsHealthSafetyLgProps {
  color: string;
  size?: number;
}

const SubsectionsHealthSafetyLg: React.FC<SubsectionsHealthSafetyLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clipPath="url(#clip0_6501_42338)">
      <path
        d="M31.274 37.8635V31.1694C31.274 30.8851 31.0395 30.6506 30.7553 30.6506H24.5799C24.2956 30.6506 24.0611 30.8851 24.0611 31.1694V37.8635H17.367C17.0827 37.8635 16.8482 38.098 16.8482 38.3823V44.5577C16.8482 44.8419 17.0827 45.0764 17.367 45.0764H24.0611V51.7706C24.0611 52.0548 24.2956 52.2893 24.5799 52.2893H30.7553C31.0395 52.2893 31.274 52.0548 31.274 51.7706V45.0764H37.9682C38.2524 45.0764 38.4869 44.8419 38.4869 44.5577V38.3823C38.4869 38.098 38.2524 37.8635 37.9682 37.8635H31.274Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5887 12.5793C34.1663 15.3011 35.7865 18.975 36.419 23.8002"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9233 23.8073C19.5486 19.0603 21.1404 15.4361 22.6825 12.7144"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0424 24.0489C14.1194 24.0489 16.7914 26.0315 18.1558 28.9522C18.4187 29.5136 19.172 29.5349 19.4633 28.9877C21.0267 26.0528 24.118 24.056 27.6782 24.056C31.2385 24.056 34.3297 26.0528 35.8931 28.9877C36.1845 29.5349 36.9378 29.5136 37.2007 28.9522C38.5651 26.0386 41.2371 24.0489 44.3141 24.0489C46.247 24.0489 48.0165 24.8305 49.4093 26.131C49.9565 26.6427 50.8306 26.1239 50.6103 25.4062C47.5759 15.6137 38.4727 8.50024 27.6853 8.50024C16.898 8.50024 7.78057 15.6137 4.74618 25.4062C4.52588 26.1239 5.39996 26.6498 5.94714 26.131C7.33998 24.8305 9.10945 24.0489 11.0424 24.0489Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.6711 3.71069V5.6294"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.6711 5.62939V6.36845"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6501_42338">
        <rect
          width="47.3493"
          height="50"
          fill="white"
          transform="translate(4 3)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SubsectionsHealthSafetyLg;
