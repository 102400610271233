import React from 'react';

export interface RisksTornadoLgProps {
  color: string;
  size?: number;
}

const RisksTornadoLg: React.FC<RisksTornadoLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M31.486 18.375C30.1713 18.3525 30.1714 16.3969 31.486 16.375C32.8005 16.3974 32.8003 18.3528 31.486 18.375Z"
      fill={color}
    />
    <path
      d="M17.9283 6.26674L17.9283 6.26675C16.337 6.73722 15.2926 7.25495 14.6224 7.74677C14.2869 7.993 14.0274 8.24572 13.8427 8.50001C13.6784 8.72624 13.5 9.05489 13.5 9.4375C13.5 9.82011 13.6784 10.1488 13.8427 10.375C14.0274 10.6293 14.2869 10.882 14.6224 11.1282C15.2926 11.6201 16.337 12.1378 17.9283 12.6083C20.7203 13.4337 24.3865 13.875 28.25 13.875C32.1135 13.875 35.7797 13.4337 38.5717 12.6083L38.2882 11.6493L38.5717 12.6083C40.163 12.1378 41.2074 11.62 41.8776 11.1282C42.2131 10.882 42.4726 10.6293 42.6573 10.375C42.8216 10.1488 43 9.82011 43 9.4375C43 9.0549 42.8216 8.72624 42.6573 8.50001C42.4726 8.24572 42.2131 7.99299 41.8776 7.74676C41.2074 7.25493 40.163 6.7372 38.5717 6.26674C35.7797 5.44129 32.1135 5 28.25 5C24.3865 5 20.7203 5.44129 17.9283 6.26674Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M23.9648 9.34596C26.1932 8.90054 37.1962 8.30978 39.695 10.9478C41.3521 12.6971 36.8937 13.143 33.3269 13.5888"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M13.5 9.875C14.5 16.875 15.9737 25.5843 23 30.375C34 37.875 28.5 49.375 28.5 49.375C28.5 49.375 39.5 42.375 36 31.875C36.1667 30.7083 37.1 27.675 39.5 24.875C42.5 21.375 44 11.375 43 9.375"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M18 17.375C19.1667 17.7083 22.8 18.275 28 17.875"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M14.9991 17.875C7.49923 18.875 7.49969 24.375 26.4991 22.875C35.6661 21.875 51.8 19.075 43 15.875"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M38.9991 25.875C41.8325 26.375 43.5991 28.475 27.9991 32.875C12.3991 37.275 15.4991 33.375 18.9991 30.875"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M28.9988 38.2047C22.5 40.375 29.4249 44.4631 33.507 43.8768C38.5071 43.1587 40.0154 39.8312 37.2593 38.2068"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M27.4695 29.3013C26.9231 24.2316 33.0699 25.4431 33.0699 25.4431C33.0699 25.4431 32.9892 30.1858 27.4695 29.3013Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M41.2693 37.6126C38.7978 33.2032 43.8606 33.081 43.8606 33.081C43.8606 33.081 45.7328 37.3216 41.2693 37.6126Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M11.6399 29.9339C10.3513 25.0461 15.2834 26.1956 15.2834 26.1956C15.2834 26.1956 16.034 30.77 11.6399 29.9339Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M11.5 29.875L10 31.375"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default RisksTornadoLg;
