import React from 'react';

export interface NavigationalNavigateProps {
  color: string;
  size?: number;
}

const NavigationalNavigate: React.FC<NavigationalNavigateProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_6133_59443)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 12.0001C14.3196 12.0001 16.2 10.1197 16.2 7.8001C16.2 5.4805 14.3196 3.6001 12 3.6001C9.68039 3.6001 7.79999 5.4805 7.79999 7.8001C7.79999 10.1197 9.68039 12.0001 12 12.0001Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M12 6C11.0059 6 10.2 6.80589 10.2 7.8"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.21065 16.3576C5.32194 15.9124 5.72192 15.6001 6.18079 15.6001H17.8192C18.2781 15.6001 18.6781 15.9124 18.7894 16.3576L19.4894 19.1576C19.6472 19.7887 19.1698 20.4001 18.5192 20.4001H5.48079C4.83022 20.4001 4.35286 19.7887 4.51065 19.1576L5.21065 16.3576Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M12 12V18"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6133_59443">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NavigationalNavigate;
