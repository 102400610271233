import React from 'react';

export interface LensInspectorLgProps {
  color: string;
  size?: number;
}

const LensInspectorLg: React.FC<LensInspectorLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.008 10.856L6.46028 13.8124C5.48329 14.6265 4 13.9318 4 12.6601V3.67774C4 2.84931 4.67157 2.17773 5.5 2.17773H17.8538C18.6822 2.17773 19.3538 2.84931 19.3538 3.67773V20.3706C19.3538 21.199 18.6822 21.8706 17.8538 21.8706H5.5C4.67157 21.8706 4 21.199 4 20.3706V19.2004"
      stroke="#0A94EF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      opacity="0.2"
      d="M19.3792 20.0106L19.2831 18.6419C19.1861 17.2604 17.236 17.064 16.8656 18.3985C16.7865 18.6834 16.6048 18.9323 16.3588 19.0963C15.1827 19.8804 15.814 21.7381 17.2193 21.5866L18.1443 21.4868C18.8859 21.4068 19.4315 20.7546 19.3792 20.0106Z"
      fill="#0A94EF"
    />
    <circle
      cx="12.3442"
      cy="9.85445"
      r="4.00533"
      fill="#0A94EF"
      fillOpacity="0.2"
    />
    <path
      d="M9.00586 8.51942C9.00586 8.95774 9.09219 9.39177 9.25993 9.79673C9.42767 10.2017 9.67353 10.5696 9.98347 10.8796C10.2934 11.1895 10.6614 11.4354 11.0663 11.6031C11.4713 11.7709 11.9053 11.8572 12.3436 11.8572C12.782 11.8572 13.216 11.7709 13.6209 11.6031C14.0259 11.4354 14.3939 11.1895 14.7038 10.8796C15.0137 10.5696 15.2596 10.2017 15.4273 9.79673C15.5951 9.39177 15.6814 8.95774 15.6814 8.51942C15.6814 8.08109 15.5951 7.64706 15.4273 7.24211C15.2596 6.83715 15.0137 6.46919 14.7038 6.15925C14.3939 5.84931 14.0259 5.60345 13.6209 5.43571C13.216 5.26797 12.782 5.18164 12.3436 5.18164C11.9053 5.18164 11.4713 5.26797 11.0663 5.43571C10.6614 5.60345 10.2934 5.84931 9.98347 6.15925C9.67353 6.46919 9.42767 6.83715 9.25993 7.24211C9.09219 7.64706 9.00586 8.08109 9.00586 8.51942Z"
      stroke="#0A94EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3438 18.5327L16.3491 18.5327"
      stroke="#0A94EF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M13.6797 15.1953L16.3499 15.1953"
      stroke="#0A94EF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M6.6709 17.8656L8.00601 19.2007L10.0087 16.8643"
      stroke="#0A94EF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LensInspectorLg;
