import React from 'react';

export interface SubsectionsHomeCareServicesProps {
  color: string;
  size?: number;
}

const SubsectionsHomeCareServices: React.FC<
  SubsectionsHomeCareServicesProps
> = ({ color, size = 56 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clipPath="url(#clip0_6551_27350)">
      <path
        d="M50.003 9.17034L36.3445 23.3409C35.7149 23.9957 35.4295 24.9108 35.547 25.809L36.0004 29.209C36.1263 30.166 35.8157 31.1146 35.1609 31.8114L31.8869 35.2785C31.3832 35.8073 30.7284 36.1515 30.0064 36.2523L24.3566 37.033C23.8697 37.1002 23.5759 36.5209 23.9117 36.1599L28.5037 31.2993C29.2676 30.4766 29.2257 29.2006 28.4198 28.4282L26.2791 26.4219C25.4731 25.6579 24.1887 25.6999 23.4164 26.5058L18.8244 31.3748C18.4886 31.7274 17.901 31.4672 17.9429 30.9803L18.3795 25.2969C18.4466 24.575 18.7488 23.8866 19.2357 23.3661L22.5098 19.899C23.1646 19.2022 24.1048 18.8413 25.0534 18.9084L28.4869 19.1519C29.3936 19.219 30.275 18.8748 30.9046 18.22L42.0111 6.69385"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.8465 21.6619V50.1877C46.8465 51.2707 45.9651 52.1521 44.8821 52.1521H10.8408C9.75787 52.1521 8.8764 51.2707 8.8764 50.1877V21.6619"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.5218 13.4348L29.1417 8.7924C28.3694 8.27191 27.3452 8.27191 26.5729 8.7924L6.05573 23.7101C5.1239 24.3397 3.83948 23.6933 3.83948 22.5852V21.4519C3.83948 20.9986 4.06614 20.5788 4.44391 20.327L26.5812 4.24236C27.3536 3.71348 28.3778 3.71348 29.1501 4.24236L38.1158 10.7484"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.2106 15.1726L51.2875 20.3187C51.6652 20.5789 51.8835 20.9987 51.8835 21.4436V22.5769C51.8835 23.685 50.5991 24.3398 49.6673 23.7018L41.6249 17.8506"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.8858 40.8274H22.8372V52.1605H32.8858V40.8274Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6551_27350">
        <rect
          width="49.723"
          height="50"
          fill="white"
          transform="translate(3 3)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SubsectionsHomeCareServices;
