import React from 'react';

export interface RisksHurricaneSmProps {
  color: string;
  size?: number;
}

const RisksHurricaneSm: React.FC<RisksHurricaneSmProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12.6211 9.04037C14.0241 9.06872 15.1706 9.95652 16.2945 10.6703C17.3873 11.3643 18.4586 11.8937 19.7234 11.3087C21.7265 10.3819 21.4778 8.46424 20.9316 7.70423C20.3802 6.93734 19.2728 6.60817 18.378 7.18281"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8242 14.9626C11.4212 14.9342 10.2747 14.0464 9.15078 13.3326C8.05802 12.6387 6.98669 12.1092 5.72194 12.6942C3.71877 13.621 3.96752 15.5387 4.51375 16.2987C5.06508 17.0656 6.1725 17.3948 7.06735 16.8201"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.107 10.6076C10.833 9.40675 12.1752 8.8577 13.3552 8.24125C14.5026 7.64189 15.4968 6.97882 15.6226 5.59101C15.8215 3.3928 14.0364 2.64939 13.1051 2.74244C12.1653 2.83646 11.3265 3.63093 11.3767 4.69321"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3383 13.3924C14.6123 14.5932 13.2702 15.1423 12.0901 15.7587C10.9427 16.3581 9.94849 17.0212 9.82273 18.409C9.62381 20.6072 11.4089 21.3506 12.3402 21.2576C13.28 21.1635 14.1188 20.3691 14.0686 19.3068"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2125 13.5682C9.53552 12.339 9.73109 10.9022 9.78728 9.57195C9.84191 8.2786 9.76477 7.08607 8.62577 6.28325C6.82152 5.01188 5.28515 6.18613 4.90009 7.03919C4.5116 7.90009 4.78024 9.02374 5.72532 9.51138"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2368 10.4318C15.9137 11.661 15.7181 13.0978 15.6619 14.428C15.6073 15.7214 15.6845 16.9139 16.8234 17.7167C18.6277 18.9881 20.1641 17.8139 20.5491 16.9608C20.9376 16.0999 20.669 14.9763 19.7239 14.4886"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7749 14.064C15.9143 12.9246 15.9143 11.0773 14.7749 9.93799C13.6355 8.79863 11.7883 8.79863 10.6489 9.93799C9.50957 11.0773 9.50957 12.9246 10.6489 14.064C11.7883 15.2033 13.6355 15.2033 14.7749 14.064Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.46 12.748C13.8726 12.3355 13.8726 11.6665 13.46 11.2539C13.0474 10.8413 12.3784 10.8413 11.9658 11.2539C11.5532 11.6665 11.5532 12.3355 11.9658 12.748C12.3784 13.1606 13.0474 13.1606 13.46 12.748Z"
      fill={color}
    />
  </svg>
);

export default RisksHurricaneSm;
