import React from 'react';

export interface DocumentsPrepurchaseProps {
  color: string;
  size?: number;
}

const DocumentsPrepurchase: React.FC<DocumentsPrepurchaseProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.481 18.9998H16.2215C18.4658 18.9998 19.962 17.5036 19.962 15.2593V12.5188C19.962 10.499 18.6902 9.00275 16.82 8.77832C16.6704 8.77832 16.4459 8.77832 16.2215 8.77832H8.7405C8.51607 8.77832 8.36645 8.7783 8.14202 8.85311C6.19696 9.07754 5 10.499 5 12.5188"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8203 8.77791C16.7455 8.17943 16.5211 7.58094 16.0722 7.13208L14.7256 5.78551C13.6783 4.73816 12.0325 4.73816 10.9851 5.78551L8.5912 8.25423C8.36677 8.47866 8.21714 8.62827 8.14233 8.8527"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9622 11.8931H17.7179C16.895 11.8931 16.2217 12.5664 16.2217 13.3893C16.2217 14.2122 16.895 14.8855 17.7179 14.8855H19.9622"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.84388 18.551C9.16801 18.551 10.2378 17.4813 10.2378 16.1571C10.2378 14.833 9.16801 13.7632 7.84388 13.7632C6.51974 13.7632 5.44995 14.833 5.44995 16.1571C5.44995 17.4813 6.51974 18.551 7.84388 18.551Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 19.0001L5.7481 18.252"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocumentsPrepurchase;
