import React, { FC } from 'react';

export interface Props {
  color: string;
  size?: number;
}

const RisksContaminationSm: FC<Props> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <ellipse
      cx="11.9394"
      cy="8.90909"
      rx="6.30303"
      ry="5.90909"
      stroke={color}
      strokeWidth="1.5"
    />
    <circle
      cx="7.90909"
      cy="15.2729"
      r="5.90909"
      stroke={color}
      strokeWidth="1.5"
    />
    <circle
      cx="16.0909"
      cy="15.2729"
      r="5.90909"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
);

export default RisksContaminationSm;
