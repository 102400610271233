import React from 'react';

export interface DocumentsReportProps {
  color: string;
  size?: number;
}

const DocumentsReport: React.FC<DocumentsReportProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 19.25C6.30964 19.25 5.75 18.6904 5.75 18V6C5.75 5.30964 6.30964 4.75 7 4.75H13.7149L18.25 8.78124V18C18.25 18.6904 17.6904 19.25 17 19.25H7Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3518 4.56787V8.75711C13.3518 9.30939 13.7995 9.75711 14.3518 9.75711H18.541"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M9 13.0679H15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 10.0679H11"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 16.1084H15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocumentsReport;
