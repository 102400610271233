import React from 'react';

export interface SubsectionsHomeWarrantiesProps {
  color: string;
  size?: number;
}

const SubsectionsHomeWarranties: React.FC<SubsectionsHomeWarrantiesProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clipPath="url(#clip0_6551_27345)">
      <path
        d="M8.64803 37.4106H23.4783C26.1472 37.4106 28.3101 39.5736 28.3101 42.2425V44.7727H3.81616V42.2425C3.81616 39.5736 5.97907 37.4106 8.64803 37.4106Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00354 44.7727H26.1227V46.3806C26.1227 47.2947 25.38 48.0293 24.474 48.0293H7.66041C6.74628 48.0293 6.0117 47.2866 6.0117 46.3806V44.7727H6.00354Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5413 33.4929H19.5932C20.7196 33.4929 21.6419 34.4071 21.6419 35.5416V37.4188H10.4927V35.5416C10.4927 34.4152 11.4068 33.4929 12.5413 33.4929Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5413 33.4849V33.3298C12.7372 29.5345 12.2067 25.731 10.9253 22.1479C10.6314 21.3236 10.4682 20.5237 10.5825 19.9034C10.9906 17.6262 12.827 15.798 15.1042 15.4143C18.6056 14.8267 21.6337 17.512 21.6337 20.9073C21.6337 21.2338 21.5358 21.6337 21.3725 22.0745C20.034 25.7229 19.4218 29.5998 19.5851 33.4849"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0918 15.3326V6.24026C16.0918 4.9017 17.1773 3.81616 18.5159 3.81616H49.0089C50.3474 3.81616 51.433 4.9017 51.433 6.24026V49.7597C51.433 51.0982 50.3474 52.1838 49.0089 52.1838H18.5159C17.1773 52.1838 16.0918 51.0982 16.0918 49.7597V48.0375"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.8877 47.7519C44.5344 47.7519 47.4907 44.7956 47.4907 41.1489C47.4907 37.5022 44.5344 34.5459 40.8877 34.5459C37.2409 34.5459 34.2847 37.5022 34.2847 41.1489C34.2847 44.7956 37.2409 47.7519 40.8877 47.7519Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.8596 41.1489L39.9491 43.4833L44.0709 39.2554"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8746 9.97021H46.6582"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.4822 16.5979H46.2337"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6412 22.458H46.2338"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6412 28.3591H46.2338"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6551_27345">
        <rect
          width="49.2491"
          height="50"
          fill="white"
          transform="translate(3 3)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SubsectionsHomeWarranties;
