import React from 'react';

export interface RoomsKitchenProps {
  color: string;
  size?: number;
}

const RoomsKitchen: React.FC<RoomsKitchenProps> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_5205_36876)">
      <path
        d="M10 15.2832H9"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 7.28516H9"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10.2V3.54C6 3.24177 6.2467 3 6.55102 3H18.3061C18.6105 3 18.8571 3.24177 18.8571 3.54V10.2M6 10.2V20.46C6 20.7583 6.2467 21 6.55102 21H18.3061C18.6105 21 18.8571 20.7583 18.8571 20.46V10.2M6 10.2H18.8571"
        stroke={color}
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_5205_36876">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RoomsKitchen;
