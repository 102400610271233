import React from 'react';

export interface AmenitiesDresserProps {
  color: string;
  size?: number;
}

const AmenitiesDresser: React.FC<AmenitiesDresserProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_36605)">
        <path
          d="M4 10.5H18.8364"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9819 13.2817H13.0183"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9819 7.68164H13.0183"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.7091 4.96667C19.7091 4.96667 19.7091 10.9305 19.7091 16.0333C19.7091 16.2911 19.4356 16.5 19.0982 16.5H4.61091C4.27351 16.5 4 16.2911 4 16.0333V4.96667C4 4.70893 4.27351 4.5 4.61091 4.5H19.0982C19.4356 4.5 19.7091 4.70893 19.7091 4.96667Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.8911 17.4639V19.5002"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.69067 17.4639V19.5002"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_36605">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AmenitiesDresser;
