import React from 'react';

export interface FormsNameProps {
  color: string;
  size?: number;
}

const FormsName: React.FC<FormsNameProps> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M16.5 4L17.7209 4C18.9796 4 20 5.04467 20 6.33333L20 17.4167C20 18.7053 18.9796 19.75 17.7209 19.75L16.5 19.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12.5 4L12.5 19.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.50043 4L7.33376 4C6.07506 4 5.05469 5.04467 5.05469 6.33333L5.05469 17.4167C5.05469 18.7053 6.07506 19.75 7.33376 19.75L8.50043 19.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default FormsName;
