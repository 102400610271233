import './ImageHeaderAnimate.scss';

import { motion, useScroll, useTransform } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

function ImageHeaderAnimate(props) {
  const { src, label, alt } = props;
  const imageContainerRef = useRef(null);
  const { scrollYProgress } = useScroll();

  const imageScale = useTransform(scrollYProgress, [0, 0.6], [1, 0.2]);
  const imageBorder = useTransform(scrollYProgress, [0, 0.6], ['4px', '100px']);
  const labelYPosition = useTransform(scrollYProgress, [0, 0.6], [0, -95]);
  const labelXPosition = useTransform(scrollYProgress, [0, 0.6], [0, 80]);
  const labelColor = useTransform(scrollYProgress, [0, 0.6], ['#FFF', '#000']);

  return (
    <div className="ImageHeaderAnimate" ref={imageContainerRef}>
      <motion.div className="image-container" style={{ scale: imageScale }}>
        <motion.img src={src} alt={alt} style={{ borderRadius: imageBorder }} />
      </motion.div>
      <motion.div
        className="image-label"
        style={{ y: labelYPosition, x: labelXPosition, color: labelColor }}
      >
        {label}
      </motion.div>
    </div>
  );
}

ImageHeaderAnimate.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ImageHeaderAnimate;
