import React from 'react';

export interface NavigationalAccountProps {
  color: string;
  size?: number;
}

const NavigationalAccount: React.FC<NavigationalAccountProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.7303 12.8408C13.8955 12.8408 15.6507 11.0856 15.6507 8.92037C15.6507 6.75517 13.8955 5 11.7303 5C9.56505 5 7.80981 6.75517 7.80981 8.92037C7.80981 11.0856 9.56505 12.8408 11.7303 12.8408Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4607 18.9998C18.4607 15.9711 15.4489 13.5146 11.7304 13.5146C8.01184 13.5146 5 15.9711 5 18.9998"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NavigationalAccount;
