import React from 'react';

export interface SubsectionsPermitHistoryProps {
  color: string;
  size?: number;
}

const SubsectionsPermitHistory: React.FC<SubsectionsPermitHistoryProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clipPath="url(#clip0_6412_74062)">
      <path
        d="M14.8599 44.1326L17.6098 39.5399C17.6098 39.5399 17.6949 39.521 17.6949 39.5588V42.8001C17.6949 42.9419 17.865 43.008 17.9595 42.9135L19.5849 41.2881V43.1781L21.4749 41.2881C21.4749 41.2881 21.4749 43.1781 24.3099 43.1781H26.0109"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.4556 23.3047V16.0849L33.3251 5.94507H11.0798C10.7018 5.94507 10.3994 6.24747 10.3994 6.62547V49.538C10.3994 49.916 10.7018 50.2184 11.0798 50.2184H42.7751C43.1531 50.2184 43.4556 49.916 43.4556 49.538V31.2616"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.3252 15.3762V5.94507L43.4651 16.0849H34.0339C33.6465 16.0849 33.3252 15.7731 33.3252 15.3762Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8594 16.7615L20.3971 12.3389L25.9253 16.7615"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0078 22.6682V18.4062H21.7767V22.6682"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.332 15.7695V22.668H24.4496V15.7695"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.543 14.4281V12.5098"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8047 28.0801H18.5357"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4766 28.0801H30.5675"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8047 33.6445H21.3707"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3086 33.6445H26.0946"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2486 39.483L46.366 20.3656C46.8763 19.8553 47.7173 19.8553 48.2371 20.3656L50.0893 22.2178C50.5996 22.7281 50.5996 23.5692 50.0893 24.0889L30.9719 43.2063C30.6979 43.4804 26.1713 44.775 25.4531 45.0113C25.6894 44.2931 26.984 39.7665 27.2581 39.4925L27.2486 39.483Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.7727 30.9592L39.4866 34.6731M37.1619 37.0072L33.448 33.2934"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.001 20.1578L49.2862 18.8726C49.9193 18.2394 50.9494 18.2394 51.5825 18.8726C52.2157 19.5057 52.2157 20.5358 51.5825 21.1689L50.2973 22.4541"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.2488 39.4832L30.9721 43.197"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6412_74062">
        <rect
          width="50"
          height="46.1727"
          fill="white"
          transform="translate(3 5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SubsectionsPermitHistory;
