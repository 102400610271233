import React from 'react';

export interface NavigationalMenuProps {
  color: string;
  size?: number;
}

const NavigationalMenu: React.FC<NavigationalMenuProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
    >
      <circle cx="14" cy="14" r="10.25" stroke={color} strokeWidth="1.5" />
      <path
        d="M10.7 10.7002H17.3"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.7 17.2998H17.3"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.7 14H17.3"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default NavigationalMenu;
