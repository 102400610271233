import React from 'react';

export interface DocumentsInspecProps {
  color: string;
  size?: number;
}

const DocumentsInspec: React.FC<DocumentsInspecProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19 10.7732V7.44161C19 4.28989 18.2259 3.5 15.1129 3.5H8.88706C5.77412 3.5 5 4.28989 5 7.44161V16.2476C5 18.3279 6.20236 18.8207 7.66001 17.3347L7.66823 17.3269C8.34352 16.6465 9.37293 16.7012 9.95764 17.4442L10.7894 18.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7599 18.0197C17.1737 18.0197 18.3198 16.8736 18.3198 15.4598C18.3198 14.046 17.1737 12.8999 15.7599 12.8999C14.3461 12.8999 13.2 14.046 13.2 15.4598C13.2 16.8736 14.3461 18.0197 15.7599 18.0197Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7997 18.5002L17.9998 17.7002"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 8H15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11H12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocumentsInspec;
