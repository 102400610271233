import React from 'react';

export interface DocumentsInsuranceProps {
  color: string;
  size?: number;
}

const DocumentsInsurance: React.FC<DocumentsInsuranceProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.8834 11.4136V8.84139C18.8834 7.91901 18.1785 6.89914 17.3161 6.57668L13.5741 5.17435C12.9516 4.94188 11.9318 4.94188 11.3093 5.17435L7.5673 6.58418C6.70491 6.90664 6 7.92651 6 8.84139V14.4132C6 15.2981 6.58493 16.4604 7.29734 16.9929L10.5219 19.4001C11.0469 19.805 11.7443 20 12.4417 20"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.874 16.5003L15.2131 17.7501L17.6236 14.7505"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocumentsInsurance;
