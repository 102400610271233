import React from 'react';

export interface AmenitiesParkingProps {
  color: string;
  size?: number;
}

const AmenitiesParking: React.FC<AmenitiesParkingProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="1.5"
    >
      <path
        d="M17.6667 15.167V16.167C17.6667 17.0875 16.9206 17.8337 16.0001 17.8337V17.8337C15.0796 17.8337 14.3334 17.0875 14.3334 16.167V15.167"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M9.66675 15.167V16.167C9.66675 17.0875 8.92056 17.8337 8.00008 17.8337V17.8337C7.07961 17.8337 6.33341 17.0875 6.33341 16.167V15.167"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle
        cx="0.809524"
        cy="0.809524"
        r="0.809524"
        transform="matrix(-1 0 0 1 8.99988 12.3096)"
        fill={color}
      />
      <circle
        cx="0.809524"
        cy="0.809524"
        r="0.809524"
        transform="matrix(-1 0 0 1 17.6667 12.3096)"
        fill={color}
      />
      <path
        d="M14 12.8335H10.7619"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.38804 10.1964C5.00345 10.0425 4.81639 9.60604 4.97023 9.22146C5.12406 8.83687 5.56054 8.64981 5.94513 8.80364L5.38804 10.1964ZM7.05471 10.863L5.38804 10.1964L5.94513 8.80364L7.61179 9.47031L7.05471 10.863Z"
        fill={color}
      />
      <path
        d="M18.2785 10.1964C18.663 10.0425 18.8501 9.60604 18.6963 9.22146C18.5424 8.83687 18.106 8.64981 17.7214 8.80364L18.2785 10.1964ZM16.6118 10.863L18.2785 10.1964L17.7214 8.80364L16.0547 9.47031L16.6118 10.863Z"
        fill={color}
      />
      <path
        d="M7.7241 10.1665H16.5049C17.0353 10.1665 17.544 10.3772 17.9191 10.7523L18.4142 11.2474C18.7893 11.6225 19 12.1312 19 12.6616V13.4998C19 14.6044 18.1046 15.4998 17 15.4998H7C5.89543 15.4998 5 14.6044 5 13.4998V12.7699C5 12.1764 5.26365 11.6135 5.71963 11.2335L6.44373 10.6301C6.80316 10.3305 7.25623 10.1665 7.7241 10.1665Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M16.9999 9.83333L16.4977 7.97761C16.2618 7.10559 15.4706 6.5 14.5672 6.5H9.69805C8.81749 6.5 8.04053 7.07592 7.78447 7.91842L6.99988 10.5"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default AmenitiesParking;
