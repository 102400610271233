import React from 'react';

export interface SubsectionsInspectionHistoryProps {
  color: string;
  size?: number;
}

const SubsectionsInspectionHistory: React.FC<
  SubsectionsInspectionHistoryProps
> = ({ color, size = 56 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8317 6.31641H7V47.7438H30.1271"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.9104 6.31641H38.7421V29.988"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.9103 3H13.8328V9.61715H31.9103V3Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0835 19.4276L12.5686 20.9128L15.1594 16.6719"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.804 18.7917H33.6175"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0835 26.9443L12.5686 28.4377L15.1594 24.1968"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.804 26.3167H33.6175"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0835 34.4684L12.5686 35.9536L15.1594 31.7126"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.1473 29.9885C43.1473 29.9885 44.3272 34.1387 48.3123 35.4093C48.3123 35.4093 47.6275 48.6767 37.0995 53.0001C25.8866 48.5034 25.8866 35.4093 25.8866 35.4093C29.8717 34.1387 31.0516 29.9885 31.0516 29.9885H43.1391H43.1473Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.8353 42.1012L36.3832 45.0715L42.5713 36.1606"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SubsectionsInspectionHistory;
