import React from 'react';

export interface FinancialSecurePaymentProps {
  color: string;
  size?: number;
}

const FinancialSecurePayment: React.FC<FinancialSecurePaymentProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M16.96 17.2972L13.3642 19.8675C12.1768 20.7108 10.24 20.7108 9.04421 19.8675L5.44001 17.2972C4.64843 16.735 4 15.49 4 14.5502V8.59839C4 7.61847 4.78316 6.53413 5.74316 6.18875L9.93684 4.68675C10.6274 4.43775 11.7726 4.43775 12.4632 4.68675L16.6484 6.18875C17.4484 6.47791 18.1305 7.28113 18.3411 8.1004"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.72607 11.8667H20.8419"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.8419 10.4213V14.8792C20.825 16.6623 20.3113 17.1041 18.3661 17.1041H12.2019C10.2229 17.1041 9.72607 16.6302 9.72607 14.7747V10.4133C9.72607 8.72655 10.1303 8.18839 11.6882 8.092C11.8482 8.092 12.0166 8.08398 12.2019 8.08398H18.3661C20.345 8.09202 20.8419 8.55788 20.8419 10.4213Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8486 15.1118H12.9686"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7368 15.1118H17.4905"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FinancialSecurePayment;
