import React from 'react';

export interface ISpaceSqFtProps {
  color: string;
  size?: number;
}

const SpaceSqFt: React.FC<ISpaceSqFtProps> = ({
  color,
  size = 24,
}: ISpaceSqFtProps) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip1_5205_37042)">
      <path
        d="M21.2975 11.9954H14.0282M10.2811 9.41926V21.2881M8.13589 14.8057H10.2811M2.70264 14.8057H5.32559M10.2811 2.70264V6.60896M21.2975 21.2975H2.70264V2.70264H21.2975V21.2975Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5205_37042">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_5205_37042">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

export default SpaceSqFt;
