import React from 'react';

export interface FinancialPaymentMethodProps {
  color: string;
  size?: number;
}

const FinancialPaymentMethod: React.FC<FinancialPaymentMethodProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3.66675 13.0078H17.8334"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8334 11.0673V17.0257C17.8084 19.4007 17.1584 20.0007 14.6834 20.0007H6.81677C4.30011 20.0007 3.66675 19.3757 3.66675 16.8923V11.0673C3.66675 8.81732 4.19175 8.09232 6.16675 7.97565C6.36675 7.96732 6.58344 7.95898 6.81677 7.95898H14.6834C17.2001 7.95898 17.8334 8.58398 17.8334 11.0673Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3334 8.10833V13.9333C20.3334 16.1833 19.8084 16.9083 17.8334 17.025V11.0667C17.8334 8.58333 17.2001 7.95833 14.6834 7.95833H6.81677C6.58344 7.95833 6.36675 7.96667 6.16675 7.975C6.19175 5.6 6.84177 5 9.31677 5H17.1834C19.7001 5 20.3334 5.625 20.3334 8.10833Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.375 17.3403H7.80831"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.59204 17.3403H12.4587"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FinancialPaymentMethod;
