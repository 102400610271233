import React, { FC } from 'react';

export interface Props {
  color: string;
  size?: number;
}

const OutdoorsAcres: FC<Props> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_5205_36654)">
      <path
        d="M8.24282 7.9624L4.98389 11.8705C4.98389 11.8705 7.42809 12.9227 11.5018 12.9227C15.5754 12.9227 18.0196 11.8705 18.0196 11.8705L14.7607 7.9624"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.30688 12.4268L3 16.4179C3 16.4179 5.55102 18.8752 11.5034 18.8752C17.4558 18.8752 20.0068 16.4179 20.0068 16.4179L16.6999 12.4268"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5034 20.9999V18.874"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5051 3L6.96851 7.60602C6.96851 7.60602 9.72892 7.96032 11.5051 7.96032C13.2812 7.96032 16.0416 7.60602 16.0416 7.60602L11.5051 3Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5205_36654">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default OutdoorsAcres;
