export const colors = {
  'pl-main-brand': '#4494D0',
  'pl-blue-gray': '#767676',
  'pl-border-gray': '#E1E3EA',
  'pl-dark': '#050A30',
  'pl-gray': '#858585',
  'pl-gray-background': '#F9F9F9',
  'pl-gray-light': '#E0E0E0',
  'pl-gray-medium': '#757575',
  'pl-gray-white': '#FFFFFF',
  'pl-light-blue': '#DFF2FE',
  'pl-rating-green': '#3CC32E',
  'pl-rating-light-green': '#98DD60',
  'pl-rating-orange': '#FF7342',
  'pl-rating-red': '#FF1616',
  'pl-rating-yellow': '#FFC700',
  'pl-rating-secondary-blue': '#F0F9FF',
  'pl-rating-secondary-green': '#E3F6D4',
  'pl-rating-secondary-orange': '#FDEBE3',
  'pl-rating-secondary-red': '#FFF5F8',
  'pl-rating-secondary-yellow': '#FFF8DD',
  'pl-stytch-white': '#E5E7EB',
}