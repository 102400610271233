import './Icon.scss';

import cx from 'classnames';
import { colors } from '@propertylens/app-commons/dist/tailwindBaseConfig';

import icons from './icons';

export type IconNameType = keyof typeof icons;

type IconProps = {
  color?: string | `#${string}`;
  icon: IconNameType;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | number;
  className?: string;
};

function Icon(props: IconProps) {
  const { color = colors['pl-dark'], icon, size = 'md', className } = props;

  let iconSize: number;
  switch (size) {
    case 'sm':
      iconSize = 16;
      break;

    case 'lg':
      iconSize = 32;
      break;

    case 'xl':
      iconSize = 60;
      break;

    case 'xxl':
      iconSize = 80;
      break;

    case 'md':
    default:
      iconSize = 24;
      break;
  }

  if (typeof size === 'number' && Number.isInteger(size)) {
    iconSize = size;
  }

  const ComponentTag = icons[icon];

  if (!ComponentTag) return null;

  const pxSize = `${iconSize}px`;

  const iconCx = cx('Icon', {
    [`${className}`]: className,
    [`icon-${icon}`]: true,
  });

  return (
    <span
      className={iconCx}
      style={{
        width: pxSize,
        height: pxSize,
      }}
    >
      <ComponentTag color={color} size={iconSize} />
    </span>
  );
}

export default Icon;
