import React from 'react';

export interface AmenitiesEducationProps {
  color: string;
  size?: number;
}

const AmenitiesEducation: React.FC<AmenitiesEducationProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_36191)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9995 12.5668L17.1911 10.1929L11.9995 8.11562L6.80802 10.1929L11.9995 12.5668ZM4.88432 10.9626L6.40328 11.6572V14.8794V15.3031L6.7661 15.5218L11.5665 18.4153L11.9176 18.627L12.2853 18.4457L17.1767 16.0344L17.5951 15.8281V15.3617V11.6575L19.1148 10.9626L19.6834 10.7026V14.4654L21.1834 14.4654V10.125H20.9463L20.9991 10.1009L19.0754 9.33119L11.9995 6.5L4.92371 9.33119L3 10.1009L4.88432 10.9626ZM7.90328 14.4558V12.3431L11.9995 14.2162L16.0951 12.3434V14.8952L11.9897 16.9191L7.90328 14.4558Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_36191">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AmenitiesEducation;
