import React from 'react';

export interface AmenitiesFirepitProps {
  color: string;
  size?: number;
}

const AmenitiesFirepit: React.FC<AmenitiesFirepitProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11.4246 9.08119C7.12744 7.14749 11.6773 3.5 11.6773 3.5C11.6773 3.5 11.5058 4.98781 12.1829 5.93387C12.8931 6.92621 14.4578 6.89789 14.7106 8.35321C15.0677 10.4096 13.1805 12.2337 11.3974 12.2337C9.61436 12.2337 8.1689 10.8461 8.1689 9.13431V9.13431C8.1689 9.03853 8.0489 8.98826 7.98963 9.06349C7.44844 9.75045 7.12744 10.6062 7.12744 11.5338C7.12744 13.7978 9.03918 15.633 11.3974 15.633C13.132 15.633 14.6251 14.6401 15.2934 13.214"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.26501 15.5659L18.4091 21.3898"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M18.1438 15.6768L11.9825 18.4067"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M5.00001 21.5001L9.10754 19.6802"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default AmenitiesFirepit;
