import classNames from 'classnames';
import { motion } from 'framer-motion';
import { HTMLProps, useEffect, useRef, useState } from 'react';

type AnimateChangeInHeightProps = HTMLProps<HTMLDivElement>;

function AnimateChangeInHeight({
  children,
  className,
}: AnimateChangeInHeightProps) {
  const containerRef = useRef(null);
  const [height, setHeight] = useState('auto' as any);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        // We only have one entry, so we can use entries[0].
        const observedHeight = entries[0].contentRect.height;
        setHeight(observedHeight);
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        // Cleanup the observer when the component is unmounted
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <motion.div
      className={classNames(className, 'overflow-hidden')}
      style={{ height }}
      animate={{ height }}
      transition={{ duration: 0.3 }}
    >
      <div ref={containerRef}>{children}</div>
    </motion.div>
  );
}

export default AnimateChangeInHeight;
