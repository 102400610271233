import { Link } from '@nextui-org/react';
import cx from 'classnames';
import React, { HTMLProps } from 'react';

import StarRating from '../StarRating';
import textUtils from '@propertylens/app-commons/dist/textUtils';

type AddressCardProps = HTMLProps<HTMLDivElement> & {
  align?: 'start' | 'center' | 'end';
  name: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  website?: string;
  phone?: string;
  rating?: string;
  variant?: 'default' | 'simple';
  hideFields?: string[];
};

const AddressCard: React.FC<AddressCardProps> = ({
  align,
  children,
  className,
  name,
  address,
  address2,
  city,
  state,
  zipCode,
  website,
  phone,
  rating,
  variant = 'default',
  hideFields = [], // 'address', 'website', 'phone', 'rating'
}) => {
  const baseCx = cx(
    'AddressCard flex gap-4 flex-col font-inter',
    {
      'items-start': align === 'start',
      'items-center': align === 'center',
      'items-end': align === 'end',
    },
    className
  );

  let adjustedFields = [...hideFields];

  if (!address) adjustedFields.push('address');
  if (!website) adjustedFields.push('website');
  if (!phone) adjustedFields.push('phone');
  if (!rating) adjustedFields.push('rating');

  return (
    <div className={baseCx}>
      <div>
        {variant === 'default' && (
          <div className="text-pl-blue-gray">Company</div>
        )}
        <div className="font-semibold">{name}</div>
      </div>

      {!adjustedFields.includes('address') && (
        <div className="flex items-center gap-2">
          <div>
            {textUtils({
              address,
              address2,
              city,
              state,
              zip: zipCode,
            })}
          </div>
        </div>
      )}

      {!adjustedFields.includes('website') && (
        <div className="flex items-center gap-2">
          <div className="font-semibold">
            <Link href={website} target="_blank">
              {website}
            </Link>
          </div>
        </div>
      )}

      {!adjustedFields.includes('phone') && (
        <div className="flex items-center gap-2">
          <div>
            <Link href={`tel:${phone}`}>{phone}</Link>
          </div>
        </div>
      )}

      {!adjustedFields.includes('rating') && rating && (
        <div>
          <StarRating rating={rating} />
        </div>
      )}

      <div className="button-group flex flex-col">{children}</div>
    </div>
  );
};

export default AddressCard;
