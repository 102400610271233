import React from 'react';

export interface SubsectionsInsuringPropertyProps {
  color: string;
  size?: number;
}

const SubsectionsInsuringProperty: React.FC<
  SubsectionsInsuringPropertyProps
> = ({ color, size = 56 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M30.5205 26.0647C30.5205 26.0647 25.4285 18.276 31.3162 11.3666C31.3162 11.3666 31.3497 11.9947 31.5339 12.8406C31.9778 14.8925 35.0598 14.725 35.2775 12.6396C35.6209 9.44875 37.0363 4.95975 40.4365 3C40.4365 3 39.3227 9.13049 43.2757 14.5659C44.3979 16.1069 46.7429 16.0315 47.2957 14.2225C47.5972 13.2426 47.8317 11.9864 47.9154 10.4035C47.9154 10.4035 50.587 16.4084 50.118 21.9275C49.5904 28.1836 42.4214 32.4884 36.249 30.5789C33.7952 29.8251 31.8605 28.1166 30.5122 26.048L30.5205 26.0647Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5917 25.3188V45.3853H34.9412"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.3253 34.1124V31.0388"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.6426 16.8694L25.4517 14.3402L14.5056 22.9999L11.5911 25.3198L9.98311 26.5844L5 23.6448L25.4517 7.47266L30.9457 11.8109"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.6749 32.1375H21.2496V45.3783H29.6749V32.1375Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.6065 34.1147C47.6065 34.1147 48.578 37.5234 51.8443 38.5619C51.8443 38.5619 51.8443 49.1814 42.6401 53.0004C33.436 49.5917 33.436 38.5619 33.436 38.5619C36.7107 37.5234 37.6738 34.1147 37.6738 34.1147H47.5982H47.6065Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.1488 44.0547L42.0549 46.4918L47.1302 39.1804"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SubsectionsInsuringProperty;
