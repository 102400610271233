import React from 'react';

export interface RisksContaminationLgProps {
  color: string;
  size?: number;
}

const RisksContaminationLg: React.FC<RisksContaminationLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clipPath="url(#clip0_6501_42226)">
      <path
        d="M39.4062 9.63281L32.875 23.8516"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1406 23.8516L16.6094 9.63281"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.4062 9.63281C38.7109 8.90625 34.6094 4.78125 28.0078 4.78125C21.4062 4.78125 17.3047 8.90625 16.6094 9.63281"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.875 23.8516C32.0625 23.4219 30.3359 22.6406 28.0078 22.6406C25.6797 22.6406 23.9531 23.4219 23.1406 23.8516"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 30.8125L19.9531 29.3594"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8203 37.7812L15.7734 50.5469"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.37499 30.8125C4.09374 31.7734 2.57031 37.3984 5.86718 43.1094C9.16406 48.8203 14.7969 50.3125 15.7656 50.5547"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9531 29.3594C19.9844 30.2812 20.1719 32.1641 21.3359 34.1797C22.5 36.1875 24.0391 37.2969 24.8203 37.7891"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.2266 50.5625L31.1797 37.7891"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.0469 29.3672L51.625 30.8203"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.2266 50.5625C41.2031 50.3203 46.8281 48.8281 50.125 43.1172C53.4219 37.4062 51.8984 31.7891 51.6172 30.8203"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1797 37.7891C31.9609 37.3047 33.5 36.1953 34.6641 34.1797C35.8281 32.1719 36.0156 30.2812 36.0469 29.3594"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.2969 30.3828C31.2969 32.1953 29.8281 33.6719 28.0078 33.6719C26.1875 33.6719 24.7188 32.2031 24.7188 30.3828C24.7188 28.5625 26.1875 27.0938 28.0078 27.0938C29.8281 27.0938 31.2969 28.5625 31.2969 30.3828Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6501_42226">
        <rect
          width="50"
          height="47.3438"
          fill="white"
          transform="translate(3 4)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RisksContaminationLg;
