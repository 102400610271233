import React from 'react';

export interface DocumentsInspectionProps {
  color: string;
  size?: number;
}

const DocumentsInspection: React.FC<DocumentsInspectionProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_36280)">
        <path
          d="M18.4426 11.4114V8.83895C18.4426 7.91645 17.7376 6.89645 16.8751 6.57395L13.1326 5.17145C12.5101 4.93895 11.4901 4.93895 10.8676 5.17145L7.12512 6.58145C6.26262 6.90395 5.55762 7.92395 5.55762 8.83895V13.4114C5.55762 14.2964 6.14262 15.4589 6.85512 15.9914L10.0801 18.3989C10.6051 18.8039 11.3026 18.9989 12.0001 18.9989"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.125 14.625C13.7819 14.625 15.125 13.2819 15.125 11.625C15.125 9.96815 13.7819 8.625 12.125 8.625C10.4681 8.625 9.125 9.96815 9.125 11.625C9.125 13.2819 10.4681 14.625 12.125 14.625Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9924 16.2569C15.2853 16.5498 15.7601 16.5498 16.053 16.2569C16.3459 15.964 16.3459 15.4891 16.053 15.1962L14.9924 16.2569ZM13.4924 14.7569L14.9924 16.2569L16.053 15.1962L14.553 13.6962L13.4924 14.7569Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_36280">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DocumentsInspection;
