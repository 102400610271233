import ActionCopy from './ActionCopy';
import ActionDownloadPdf from './ActionDownloadPdf';
import ActionFavorite from './ActionFavorite';
import ActionLogOut from './ActionLogOut';
import ActionSettings from './ActionSettings';
import ActionUpload from './ActionUpload';
import AmenitiesEducation from './AmenitiesEducation';
import AmenitiesFirepit from './AmenitiesFirepit';
import AmenitiesFireplace from './AmenitiesFireplace';
import AmenitiesGym from './AmenitiesGym';
import AmenitiesLaundry from './AmenitiesLaundry';
import AmenitiesNoise from './AmenitiesNoise';
import AmenitiesParking from './AmenitiesParking';
import AmenitiesPool from './AmenitiesPool';
import AmenitiesTrash from './AmenitiesTrash';
import AmenitiesWifi from './AmenitiesWifi';
import CommunicationPhone from './CommunicationPhone';
import CommunicationQuestion from './CommunicationQuestion';
import DocumentsClipboard from './DocumentsClipboard';
import DocumentsDisclosure from './DocumentsDisclosure';
import DocumentsFlag from './DocumentsFlag';
import DocumentsInspec from './DocumentsInspec';
import DocumentsInspection from './DocumentsInspection';
import DocumentsInsurance from './DocumentsInsurance';
import DocumentsPermit from './DocumentsPermit';
import DocumentsPrepurchase from './DocumentsPrepurchase';
import DocumentsRepairs from './DocumentsRepairs';
import DocumentsReport from './DocumentsReport';
import DocumentsTitle from './DocumentsTitle';
import FinancialPay from './FinancialPay';
import FinancialPayment from './FinancialPayment';
import FinancialPaymentMethod from './FinancialPaymentMethod';
import FinancialPurchase from './FinancialPurchase';
import FinancialSecureCc from './FinancialSecureCc';
import FinancialSecurePayment from './FinancialSecurePayment';
import FormsDate from './FormsDate';
import FormsEmail from './FormsEmail';
import FormsName from './FormsName';
import FormsSearch from './FormsSearch';
import FormsWww from './FormsWww';
import HomeBuilding from './HomeBuilding';
import HomeExterior from './HomeExterior';
import HomeFoundation from './HomeFoundation';
import HomeGarage from './HomeGarage';
import HomeInterior from './HomeInterior';
import HomeItemsDresser from './HomeItemsDresser';
import HomeListingStatus from './HomeListingStatus';
import HomeNeighborhood from './HomeNeighborhood';
import HomeRentalListing from './HomeRentalListing';
import HomeRoof from './HomeRoof';
import HomeRoofV2 from './HomeRoofV2';
import HomeUse from './HomeUse';
import LensBuyerLg from './LensBuyerLg';
import LensBuyerSm from './LensBuyerSm';
import LensInspectorLg from './LensInspectorLg';
import LensInspectorSm from './LensInspectorSm';
import LensInsuranceLg from './LensInsuranceLg';
import LensInsuranceSm from './LensInsuranceSm';
import NavigationalAccount from './NavigationalAccount';
import NavigationalAscending from './NavigationalAscending';
import NavigationalCaretDown from './NavigationalCaretDown';
import NavigationalCaretLeft from './NavigationalCaretLeft';
import NavigationalCaretRight from './NavigationalCaretRight';
import NavigationalCaretUp from './NavigationalCaretUp';
import NavigationalDescending from './NavigationalDescending';
import NavigationalExpand from './NavigationalExpand';
import NavigationalExternalLink from './NavigationalExternalLink';
import NavigationalHidden from './NavigationalHidden';
import NavigationalMenu from './NavigationalMenu';
import NavigationalNavigate from './NavigationalNavigate';
import NavigationalTooltip from './NavigationalTooltip';
import NavigationalVisible from './NavigationalVisible';
import OutdoorsAcres from './OutdoorsAcres';
import OutdoorsEco from './OutdoorsEco';
import OutdoorsFrontyard from './OutdoorsFrontyard';
import OutdoorsOutdoors from './OutdoorsOutdoors';
import RiskTsunamiLg from './RiskTsunamiLg';
import RiskTsunamiSm from './RiskTsunamiSm';
import RisksContaminationLg from './RisksContaminationLg';
import RisksContaminationSm from './RisksContaminationSm';
import RisksCrimeLg from './RisksCrimeLg';
import RisksCrimeSm from './RisksCrimeSm';
import RisksDroughtLg from './RisksDroughtLg';
import RisksDroughtSm from './RisksDroughtSm';
import RisksEarthDisturbanceLg from './RisksEarthDisturbanceLg';
import RisksEarthDisturbanceSm from './RisksEarthDisturbanceSm';
import RisksEarthquakeLg from './RisksEarthquakeLg';
import RisksEarthquakeSm from './RisksEarthquakeSm';
import RisksFireProtectionLg from './RisksFireProtectionLg';
import RisksFireProtectionSm from './RisksFireProtectionSm';
import RisksFloodingLg from './RisksFloodingLg';
import RisksFloodingSm from './RisksFloodingSm';
import RisksHailLg from './RisksHailLg';
import RisksHailSm from './RisksHailSm';
import RisksHealthSafetyLg from './RisksHealthSafetyLg';
import RisksHealthSafetySm from './RisksHealthSafetySm';
import RisksHurricaneLg from './RisksHurricaneLg';
import RisksHurricaneSm from './RisksHurricaneSm';
import RisksLightningLg from './RisksLightningLg';
import RisksLightningSm from './RisksLightningSm';
import RisksPollutionLg from './RisksPollutionLg';
import RisksPollutionSm from './RisksPollutionSm';
import RisksSinkholeLg from './RisksSinkholeLg';
import RisksSinkholeSm from './RisksSinkholeSm';
import RisksThunderstormLg from './RisksThunderstormLg';
import RisksThunderstormSm from './RisksThunderstormSm';
import RisksTornadoLg from './RisksTornadoLg';
import RisksTornadoSm from './RisksTornadoSm';
import RisksVolcanoLg from './RisksVolcanoLg';
import RisksVolcanoSm from './RisksVolcanoSm';
import RisksWeatherAndNaturalDisastersLg from './RisksWeatherAndNaturalDisastersLg';
import RisksWeatherAndNaturalDisastersSm from './RisksWeatherAndNaturalDisastersSm';
import RisksWildfireLg from './RisksWildfireLg';
import RisksWildfireSm from './RisksWildfireSm';
import RisksWindLg from './RisksWindLg';
import RisksWindSm from './RisksWindSm';
import RisksWinterLg from './RisksWinterLg';
import RisksWinterSm from './RisksWinterSm';
import RoomsAttic from './RoomsAttic';
import RoomsBabyRoom from './RoomsBabyRoom';
import RoomsBasement from './RoomsBasement';
import RoomsBathroom from './RoomsBathroom';
import RoomsBedroom from './RoomsBedroom';
import RoomsGameroom from './RoomsGameroom';
import RoomsKitchen from './RoomsKitchen';
import RoomsLivingRoom from './RoomsLivingRoom';
import SpaceBoundary from './SpaceBoundary';
import SpaceExpansion from './SpaceExpansion';
import SpaceLot from './SpaceLot';
import SpaceMap from './SpaceMap';
import SpaceSqFt from './SpaceSqFt';
import SpaceStories from './SpaceStories';
import SubsectionsDetailedHistory from './SubsectionsDetailedHistory';
import SubsectionsEstimatedUtility from './SubsectionsEstimatedUtility';
import SubsectionsExposureWeather from './SubsectionsExposureWeather';
import SubsectionsExteriorCondition from './SubsectionsExteriorCondition';
import SubsectionsFloodRiskLg from './SubsectionsFloodRiskLg';
import SubsectionsFloodRiskSm from './SubsectionsFloodRiskSm';
import SubsectionsHealthSafetyLg from './SubsectionsHealthSafetyLg';
import SubsectionsHealthSafetySm from './SubsectionsHealthSafetySm';
import SubsectionsHomeCareServices from './SubsectionsHomeCareServices';
import SubsectionsHomeWarranties from './SubsectionsHomeWarranties';
import SubsectionsHomeownerServiceHistory from './SubsectionsHomeownerServiceHistory';
import SubsectionsInspectionHistory from './SubsectionsInspectionHistory';
import SubsectionsInsuringProperty from './SubsectionsInsuringProperty';
import SubsectionsListingDetails from './SubsectionsListingDetails';
import SubsectionsModifications from './SubsectionsModifications';
import SubsectionsOwnership from './SubsectionsOwnership';
import SubsectionsPermitHistory from './SubsectionsPermitHistory';
import SubsectionsPossibleContamination from './SubsectionsPossibleContamination';
import SubsectionsPropertyDetails from './SubsectionsPropertyDetails';
import SubsectionsRisksGlance from './SubsectionsRisksGlance';
import SubsectionsSpotlight from './SubsectionsSpotlight';
import SubsectionsTermsDefinitions from './SubsectionsTermsDefinitions';
import SystemsAc from './SystemsAc';
import SystemsAll from './SystemsAll';
import SystemsElectrical from './SystemsElectrical';
import SystemsHeating from './SystemsHeating';
import SystemsPlumbing from './SystemsPlumbing';
 
 export default {
  'action-copy': ActionCopy,
  'action-download-pdf': ActionDownloadPdf,
  'action-favorite': ActionFavorite,
  'action-log-out': ActionLogOut,
  'action-settings': ActionSettings,
  'action-upload': ActionUpload,
  'amenities-education': AmenitiesEducation,
  'amenities-firepit': AmenitiesFirepit,
  'amenities-fireplace': AmenitiesFireplace,
  'amenities-gym': AmenitiesGym,
  'amenities-laundry': AmenitiesLaundry,
  'amenities-noise': AmenitiesNoise,
  'amenities-parking': AmenitiesParking,
  'amenities-pool': AmenitiesPool,
  'amenities-trash': AmenitiesTrash,
  'amenities-wifi': AmenitiesWifi,
  'communication-phone': CommunicationPhone,
  'communication-question': CommunicationQuestion,
  'documents-clipboard': DocumentsClipboard,
  'documents-disclosure': DocumentsDisclosure,
  'documents-flag': DocumentsFlag,
  'documents-inspec': DocumentsInspec,
  'documents-inspection': DocumentsInspection,
  'documents-insurance': DocumentsInsurance,
  'documents-permit': DocumentsPermit,
  'documents-prepurchase': DocumentsPrepurchase,
  'documents-repairs': DocumentsRepairs,
  'documents-report': DocumentsReport,
  'documents-title': DocumentsTitle,
  'financial-pay': FinancialPay,
  'financial-payment': FinancialPayment,
  'financial-payment-method': FinancialPaymentMethod,
  'financial-purchase': FinancialPurchase,
  'financial-secure-cc': FinancialSecureCc,
  'financial-secure-payment': FinancialSecurePayment,
  'forms-date': FormsDate,
  'forms-email': FormsEmail,
  'forms-name': FormsName,
  'forms-search': FormsSearch,
  'forms-www': FormsWww,
  'home-building': HomeBuilding,
  'home-exterior': HomeExterior,
  'home-foundation': HomeFoundation,
  'home-garage': HomeGarage,
  'home-interior': HomeInterior,
  'home-items-dresser': HomeItemsDresser,
  'home-listing-status': HomeListingStatus,
  'home-neighborhood': HomeNeighborhood,
  'home-rental-listing': HomeRentalListing,
  'home-roof': HomeRoof,
  'home-roof-v2': HomeRoofV2,
  'home-use': HomeUse,
  'lens-buyer-lg': LensBuyerLg,
  'lens-buyer-sm': LensBuyerSm,
  'lens-inspector-lg': LensInspectorLg,
  'lens-inspector-sm': LensInspectorSm,
  'lens-insurance-lg': LensInsuranceLg,
  'lens-insurance-sm': LensInsuranceSm,
  'navigational-account': NavigationalAccount,
  'navigational-ascending': NavigationalAscending,
  'navigational-caret-down': NavigationalCaretDown,
  'navigational-caret-left': NavigationalCaretLeft,
  'navigational-caret-right': NavigationalCaretRight,
  'navigational-caret-up': NavigationalCaretUp,
  'navigational-descending': NavigationalDescending,
  'navigational-expand': NavigationalExpand,
  'navigational-external-link': NavigationalExternalLink,
  'navigational-hidden': NavigationalHidden,
  'navigational-menu': NavigationalMenu,
  'navigational-navigate': NavigationalNavigate,
  'navigational-tooltip': NavigationalTooltip,
  'navigational-visible': NavigationalVisible,
  'outdoors-acres': OutdoorsAcres,
  'outdoors-eco': OutdoorsEco,
  'outdoors-frontyard': OutdoorsFrontyard,
  'outdoors-outdoors': OutdoorsOutdoors,
  'risk-tsunami-lg': RiskTsunamiLg,
  'risk-tsunami-sm': RiskTsunamiSm,
  'risks-contamination-lg': RisksContaminationLg,
  'risks-contamination-sm': RisksContaminationSm,
  'risks-crime-lg': RisksCrimeLg,
  'risks-crime-sm': RisksCrimeSm,
  'risks-drought-lg': RisksDroughtLg,
  'risks-drought-sm': RisksDroughtSm,
  'risks-earth-disturbance-lg': RisksEarthDisturbanceLg,
  'risks-earth-disturbance-sm': RisksEarthDisturbanceSm,
  'risks-earthquake-lg': RisksEarthquakeLg,
  'risks-earthquake-sm': RisksEarthquakeSm,
  'risks-fire-protection-lg': RisksFireProtectionLg,
  'risks-fire-protection-sm': RisksFireProtectionSm,
  'risks-flooding-lg': RisksFloodingLg,
  'risks-flooding-sm': RisksFloodingSm,
  'risks-hail-lg': RisksHailLg,
  'risks-hail-sm': RisksHailSm,
  'risks-health-safety-lg': RisksHealthSafetyLg,
  'risks-health-safety-sm': RisksHealthSafetySm,
  'risks-hurricane-lg': RisksHurricaneLg,
  'risks-hurricane-sm': RisksHurricaneSm,
  'risks-lightning-lg': RisksLightningLg,
  'risks-lightning-sm': RisksLightningSm,
  'risks-pollution-lg': RisksPollutionLg,
  'risks-pollution-sm': RisksPollutionSm,
  'risks-sinkhole-lg': RisksSinkholeLg,
  'risks-sinkhole-sm': RisksSinkholeSm,
  'risks-thunderstorm-lg': RisksThunderstormLg,
  'risks-thunderstorm-sm': RisksThunderstormSm,
  'risks-tornado-lg': RisksTornadoLg,
  'risks-tornado-sm': RisksTornadoSm,
  'risks-volcano-lg': RisksVolcanoLg,
  'risks-volcano-sm': RisksVolcanoSm,
  'risks-weather-and-natural-disasters-lg': RisksWeatherAndNaturalDisastersLg,
  'risks-weather-and-natural-disasters-sm': RisksWeatherAndNaturalDisastersSm,
  'risks-wildfire-lg': RisksWildfireLg,
  'risks-wildfire-sm': RisksWildfireSm,
  'risks-wind-lg': RisksWindLg,
  'risks-wind-sm': RisksWindSm,
  'risks-winter-lg': RisksWinterLg,
  'risks-winter-sm': RisksWinterSm,
  'rooms-attic': RoomsAttic,
  'rooms-baby-room': RoomsBabyRoom,
  'rooms-basement': RoomsBasement,
  'rooms-bathroom': RoomsBathroom,
  'rooms-bedroom': RoomsBedroom,
  'rooms-gameroom': RoomsGameroom,
  'rooms-kitchen': RoomsKitchen,
  'rooms-living-room': RoomsLivingRoom,
  'space-boundary': SpaceBoundary,
  'space-expansion': SpaceExpansion,
  'space-lot': SpaceLot,
  'space-map': SpaceMap,
  'space-sq-ft': SpaceSqFt,
  'space-stories': SpaceStories,
  'subsections-detailed-history': SubsectionsDetailedHistory,
  'subsections-estimated-utility': SubsectionsEstimatedUtility,
  'subsections-exposure-weather': SubsectionsExposureWeather,
  'subsections-exterior-condition': SubsectionsExteriorCondition,
  'subsections-flood-risk-lg': SubsectionsFloodRiskLg,
  'subsections-flood-risk-sm': SubsectionsFloodRiskSm,
  'subsections-health-safety-lg': SubsectionsHealthSafetyLg,
  'subsections-health-safety-sm': SubsectionsHealthSafetySm,
  'subsections-home-care-services': SubsectionsHomeCareServices,
  'subsections-home-warranties': SubsectionsHomeWarranties,
  'subsections-homeowner-service-history': SubsectionsHomeownerServiceHistory,
  'subsections-inspection-history': SubsectionsInspectionHistory,
  'subsections-insuring-property': SubsectionsInsuringProperty,
  'subsections-listing-details': SubsectionsListingDetails,
  'subsections-modifications': SubsectionsModifications,
  'subsections-ownership': SubsectionsOwnership,
  'subsections-permit-history': SubsectionsPermitHistory,
  'subsections-possible-contamination': SubsectionsPossibleContamination,
  'subsections-property-details': SubsectionsPropertyDetails,
  'subsections-risks-glance': SubsectionsRisksGlance,
  'subsections-spotlight': SubsectionsSpotlight,
  'subsections-terms-definitions': SubsectionsTermsDefinitions,
  'systems-ac': SystemsAc,
  'systems-all': SystemsAll,
  'systems-electrical': SystemsElectrical,
  'systems-heating': SystemsHeating,
  'systems-plumbing': SystemsPlumbing,
};
