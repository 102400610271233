import React from 'react';

export interface RisksFireProtectionSm {
  color: string;
  size?: number;
}

const RisksFireProtectionSm: React.FC<RisksFireProtectionSm> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11.8 11.2C5 8.0125 12.2 2 12.2 2C12.2 2 11.9285 4.4525 13 6.01198C14.1239 7.64774 16.6 7.60106 17 10C17.5652 13.3897 14.5787 16.3966 11.7571 16.3966C8.93545 16.3966 6.64807 14.1092 6.64807 11.2876V11.2876C6.64807 11.1327 6.45277 11.0536 6.35949 11.1773C5.50604 12.3086 5 13.7166 5 15.2429C5 18.9748 8.02525 22 11.7571 22C14.502 22 16.8647 20.3633 17.9223 18.0125"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksFireProtectionSm;
