import React, { FC } from 'react';

export interface Props {
  color: string;
  size?: number;
}

const RisksFloodingSm: FC<Props> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3.30078 6.59982C4.80078 6.59982 6.10078 5.3998 6.10078 3.7998C6.10078 5.3998 7.40077 6.59982 8.90077 6.59982C10.5008 6.59982 11.7008 5.2998 11.7008 3.7998M11.8008 3.7998C11.8008 5.3998 13.1008 6.59982 14.6008 6.59982C16.1008 6.59982 17.4008 5.2998 17.4008 3.7998M17.5008 3.7998C17.5008 5.3998 18.8008 6.59982 20.3008 6.59982M3.30078 12.9998C4.90078 12.9998 6.10078 11.6998 6.10078 10.1998C6.10078 11.7998 7.40077 12.9998 8.90077 12.9998C10.5008 12.9998 11.7008 11.6998 11.7008 10.1998M11.8008 10.1998C11.8008 11.7998 13.1008 12.9998 14.6008 12.9998C16.1008 12.9998 17.4008 11.6998 17.4008 10.1998M17.5008 10.1998C17.5008 11.7998 18.8008 12.9998 20.3008 12.9998M3.30078 19.2998C4.90078 19.2998 6.10078 17.9998 6.10078 16.4998C6.10078 18.0998 7.40077 19.2998 8.90077 19.2998C10.5008 19.2998 11.7008 17.9998 11.7008 16.4998M11.8008 16.4998C11.8008 18.0998 13.1008 19.2998 14.6008 19.2998C16.1008 19.2998 17.4008 17.9998 17.4008 16.4998M17.5008 16.4998C17.5008 18.0998 18.8008 19.2998 20.3008 19.2998"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksFloodingSm;
