'use client';

import { useState, FC } from 'react';
import { ModalBody, ModalHeader } from '@nextui-org/react';
import { format } from 'date-fns';
import validateAddress from '../../../utils/validateAddress';

import {
  ModalErrorView,
  ModalView,
  useModalContext,
} from '../../../hooks/useModal';
import { ADDRESS_INPUT_ERROR } from '../../../constants';
import Button from '../../Button';
import InfoBox from '../../InfoBox';
import SectionHeadingGroup from '../../SectionHeadingGroup';
import AddressLookup from '../../AddressLookup';
import { ValidationState } from '../GetReportsModal';
import { emitTrackingEvent } from '@propertylens/app-commons';

type Props = {
  onModalClose: () => void;
  navigate: (url: string) => void;
  googleAPIKey: string;
  handleOnBack: () => void;
};

const BaseContent: FC<Props> = ({
  onModalClose,
  navigate,
  googleAPIKey,
  handleOnBack,
}: Props) => {
  const {
    selectedPlace,
    setModalView,
    setModalErrorView,
    setValidPlace,
    closeModal,
    addressInputError,
    setAddressInputError,
    setAddressInputErrorType,
    existingReport,
    isAllowed,
    setWarning,
    warning,
  } = useModalContext();
  const [validationState, setValidationState] = useState<ValidationState>(
    ValidationState.Idle
  );

  const isCommercialAddress = warning?.type === 'commercialAddress';

  const handleGetReport = async () => {
    if (validationState === ValidationState.Loading) return;

    emitTrackingEvent('Address Searched', {
      address: { ...selectedPlace },
      component: 'Modal',
    });

    try {
      setValidationState(ValidationState.Loading);

      const validateAddressResult = await validateAddress(
        selectedPlace,
        googleAPIKey
      );

      if (!validateAddressResult) {
        emitTrackingEvent('Address Error', {
          address: {
            ...selectedPlace,
          },
          reason: 'UNABLE_TO_VALIDATE_ADDRESS',
          validationResult: null,
        });
        setAddressInputError(true);
        setAddressInputErrorType(ADDRESS_INPUT_ERROR.INVALID_ADDRESS);
        return;
      }

      if (
        !validateAddressResult.verdict?.addressComplete &&
        !validateAddressResult.metadata
      ) {
        emitTrackingEvent('Address Error', {
          address: { ...selectedPlace },
          reason: 'INVALID_ADDRESS',
          validationResult: validateAddressResult?.verdict,
        });
        setAddressInputError(true);
        setAddressInputErrorType(ADDRESS_INPUT_ERROR.INVALID_ADDRESS);
        return;
      }

      if (validateAddressResult.metadata?.business) {
        emitTrackingEvent('Commercial Address', {
          address: { ...selectedPlace },
        });
        setWarning({
          message: (
            <p>
              This looks like a commercial property, which may have limited
              information, are you sure you want to proceed?
            </p>
          ),
          type: 'commercialAddress',
        });
        return;
      }
      const missingComponentTypes =
        validateAddressResult?.address?.missingComponentTypes;

      if (missingComponentTypes && missingComponentTypes.length > 0) {
        if (missingComponentTypes.includes('subpremise')) {
          setModalView(ModalView.SecondaryForm);
        } else {
          setAddressInputError(true);
          setAddressInputErrorType(ADDRESS_INPUT_ERROR.INVALID_ADDRESS);
        }
      } else {
        setModalView(ModalView.Preview);
      }
    } catch (error) {
      setModalView(ModalView.Error);
      setModalErrorView(ModalErrorView.ErrorOnBase);
    } finally {
      setValidationState(ValidationState.Idle);
    }
  };
  const onViewReport = (reportId: string) => {
    closeModal();
    setValidPlace(false);
    if (!process.env.NEXT_PUBLIC_IN_APP_DOMAIN) {
      navigate(`/reports/${reportId}`);
    } else {
      window.location.href = `${process.env.NEXT_PUBLIC_IN_APP_DOMAIN}/reports/${reportId}`;
    }
  };
  return (
    <>
      <ModalHeader className="mb-8 block p-0">
        <SectionHeadingGroup
          heading="Get a PropertyLens Report in minutes!"
          description="Start by searching for any address below..."
          level={2}
          classNames={{
            base: 'mt-0 mb-4',
            description: 'mt-4',
            heading: 'font-[raleway]',
          }}
          alwaysVisible={true}
        />
        <hr />
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="flex flex-col gap-8">
          {process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ? (
            <AddressLookup
              googleAPIKey={googleAPIKey}
              parentComponent="Modal"
            />
          ) : (
            <p>Seems like our search is not working as expected</p>
          )}

          {addressInputError && (
            <p className="text-pl-rating-red">Please enter a valid address.</p>
          )}
          {existingReport && (
            <InfoBox
              color="warning"
              header="Are you sure you want to run a report for this address again?"
            >
              {`You purchased a report for this address on ${format(existingReport.report_body.created_dt, 'MMMM d, y')}. While we add new information and insights often, its unlikely reports will significantly change within 30 days.`}
            </InfoBox>
          )}
          {warning?.message && (
            <InfoBox color={warning.type}>{warning.message}</InfoBox>
          )}
          <div className="flex justify-end gap-2">
            <Button
              color="primary"
              type="button"
              variant="bordered"
              radius="sm"
              onClick={isCommercialAddress ? handleOnBack : onModalClose}
              size="lg"
              style={{ borderColor: '#050A30' }}
            >
              {isCommercialAddress ? 'No, start over' : 'Cancel'}
            </Button>

            {existingReport && (
              <Button
                color="primary"
                type="button"
                variant="bordered"
                radius="sm"
                onClick={() => onViewReport(existingReport.report_id)}
                size="lg"
                style={{ borderColor: '#050A30' }}
              >
                View Report
              </Button>
            )}

            <Button
              color="primary"
              type="button"
              radius="sm"
              onClick={
                isCommercialAddress
                  ? () => setModalView(ModalView.Preview)
                  : handleGetReport
              }
              isDisabled={
                validationState === ValidationState.Loading || !isAllowed
              }
              isLoading={validationState === ValidationState.Loading}
              size="lg"
            >
              {isCommercialAddress ? 'Yes, get report' : 'Get Report'}
            </Button>
          </div>
        </div>
      </ModalBody>
    </>
  );
};

export default BaseContent;
