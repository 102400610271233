import './Timeline.scss';

import { Accordion, AccordionItem } from '@nextui-org/react';
import { colors } from '@propertylens/app-commons/dist/tailwindBaseConfig';
import { Case } from 'change-case-all';
import cx from 'classnames';
import React from 'react';
import Icon from '../Icons';
import { IconNameType } from '../Icons/Icon';

type TimelineProps = {
  items: {
    id: string;
    color?: string | `#${string}`;
    type: string;
    icon: IconNameType;
    name: string;
    date: string;
    metadata: any;
    fields?: {
      label: string;
      value: string;
    }[];
    company?: any;
  }[];
};

const Timeline: React.FC<TimelineProps> = (props) => {
  const { items } = props;

  return (
    <div className="Timeline mx-auto max-w-[386px] md:max-w-[100vw] lg:max-w-[calc(100vw-316px)] xl:max-w-[1276px]">
      <div className="timeline-dash" />
      <div className="items flex flex-col-reverse px-4 md:!flex-row-reverse md:justify-end md:gap-4">
        {items.map((item, index) => {
          const itemCx = cx('item max-w-52', { [item.type]: true });
          const itemDotCx = cx('item-dot', {
            [`${item.color?.[0]}`]: item.color && item.color.length > 0,
          });
          return (
            <div className={itemCx} key={item.id || index}>
              <div className="item-level">
                <div className={itemDotCx} />
              </div>
              <div className="item-right-content gap-4">
                <Accordion
                  variant="shadow"
                  isDisabled={item.fields?.length === 0}
                  defaultExpandedKeys={['1']}
                >
                  <AccordionItem
                    key="1"
                    aria-label={`Accordion ${index + 1}`}
                    title={Case.capital(item.type)}
                    subtitle={<div className="text-wrap">{item.date}</div>}
                    startContent={
                      <Icon
                        icon={item.icon}
                        color={
                          item.color && item.color.length
                            ? item.color[1]
                            : colors['pl-dark']
                        }
                      />
                    }
                    classNames={{
                      title: 'text-wrap',
                    }}
                  >
                    <hr className="mb-4" />
                    {item.fields && (
                      <div className="item-fields space-y-2">
                        {item.fields?.map((field) => (
                          <div
                            className="item-field capitalize"
                            key={field.label}
                          >
                            <div className="text-sm text-pl-gray-medium">
                              {field.label}
                            </div>
                            <div>{field.value || 'N/A'}</div>
                          </div>
                        ))}
                      </div>
                    )}
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Timeline;
