import React from 'react';

export interface RisksEarthDisturbanceLgProps {
  color: string;
  size?: number;
}

const RisksEarthDisturbanceLg: React.FC<RisksEarthDisturbanceLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M13.1055 47.5677C13.1055 39.3467 19.8213 32.6309 28.0423 32.6309C36.2634 32.6309 42.9791 39.3467 42.9791 47.5677M38.2318 47.5677C38.2318 42.0098 33.716 37.494 28.1581 37.494C22.6002 37.494 18.0844 42.0098 18.0844 47.5677M33.6002 47.5677C33.6002 44.5572 31.1686 42.0098 28.0423 42.0098C25.0318 42.0098 22.4844 44.4414 22.4844 47.5677"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="6"
      y="27.8418"
      width="43.1579"
      height="21.1579"
      rx="1"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M9.63281 26.8418V17.5786L11.9486 15.8418L14.2644 17.5786"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2617 26.8425V11.2109"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.8945 26.8425V11.2109"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.4219 13.5264H18.8956"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4727 16.9997L22.9463 15.8418"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.2124 27.4208V12.3682H22.9492V27.4208"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M29.8933 12.3682V8.89453H25.2617V12.3682"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M27.5781 6V8.89474"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.2109 15.2627L36.2636 16.9995V26.8416"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.8438 21.6318H40.3174"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.8945 26.8416V16.9995L46.1051 15.2627L45.5261 26.8416"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="26.2471"
      cy="25.9729"
      rx="0.868421"
      ry="0.868421"
      fill={color}
    />
    <ellipse
      cx="29.1419"
      cy="25.9729"
      rx="0.868421"
      ry="0.868421"
      fill={color}
    />
    <ellipse
      cx="26.2471"
      cy="15.552"
      rx="0.868421"
      ry="0.868421"
      fill={color}
    />
    <ellipse
      cx="29.1419"
      cy="15.552"
      rx="0.868421"
      ry="0.868421"
      fill={color}
    />
    <ellipse
      cx="26.2471"
      cy="22.4993"
      rx="0.868421"
      ry="0.868421"
      fill={color}
    />
    <ellipse
      cx="29.1419"
      cy="22.4993"
      rx="0.868421"
      ry="0.868421"
      fill={color}
    />
    <ellipse
      cx="26.2471"
      cy="19.0256"
      rx="0.868421"
      ry="0.868421"
      fill={color}
    />
    <ellipse
      cx="29.1419"
      cy="19.0256"
      rx="0.868421"
      ry="0.868421"
      fill={color}
    />
  </svg>
);

export default RisksEarthDisturbanceLg;
