import React from 'react';

export interface RisksSinkholeSmProps {
  color: string;
  size?: number;
}

const RisksSinkholeSm: React.FC<RisksSinkholeSmProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M4.49889 8.49968C1.00484 3.83868 22.6295 0.444264 18.9989 4.99968C18.7064 5.36661 18.3819 5.71214 18.0517 6.029C17.3424 6.7096 17.7245 8.38431 18.6075 8.81637C19.1919 9.10237 19.7451 9.48644 20.1478 10.0012C21.8511 12.1786 16.5582 13.801 13.6492 14.5051C12.8821 14.6908 12.5743 15.6491 13.1109 16.2278L16.5329 19.9185C17.1117 20.5429 16.6974 21.537 15.846 21.5346C11.3076 21.5214 1.30901 20.9916 2.99889 16.4997C3.60212 14.8962 5.42061 13.5166 6.89888 12.6152C7.6553 12.154 7.63711 10.8831 6.87413 10.4328C5.99716 9.91527 5.05713 9.24436 4.49889 8.49968Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path d="M18 8L18 13.5" stroke={color} strokeWidth="1.5" />
    <path
      d="M12.8984 15.7998L12.8984 20.7998"
      stroke={color}
      strokeWidth="1.5"
    />
    <path d="M7 12.6729L7 20.0003" stroke={color} strokeWidth="1.5" />
  </svg>
);

export default RisksSinkholeSm;
