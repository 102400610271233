import React from 'react';

export interface RisksLightningLgProps {
  color: string;
  size?: number;
}

const RisksLightningLg: React.FC<RisksLightningLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clipPath="url(#clip0_6501_41930)">
      <path
        d="M15.5802 29.6157L10.7937 41.7189H18.618L15.5318 52.1943L30.3263 36.9969H24.3956L27.2321 30.0025"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.2152 31.4932L34.7986 38.5762H39.3514L36.668 47.6092L47.224 36.0137H42.0911L44.1137 31.7752"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6358 31.4852H44.7421C47.9976 31.4933 50.6325 28.8583 50.6325 25.5948C50.6325 22.3313 47.9976 19.7124 44.7421 19.7124C44.5487 19.7124 44.3553 19.7205 44.162 19.7447C44.5407 19.0356 44.7421 18.2298 44.7421 17.3756C44.7421 14.4908 42.4214 12.154 39.5367 12.154C38.8195 12.154 38.1265 12.3071 37.498 12.5811C37.5141 12.3071 37.5383 12.0412 37.5383 11.7672C37.5383 7.38367 33.9766 3.81396 29.585 3.81396C25.6366 3.81396 22.357 6.70679 21.7445 10.5021C20.7937 10.075 19.7462 9.82525 18.6422 9.82525C14.4762 9.82525 11.0999 13.2016 11.0999 17.3676C11.0999 18.1814 11.2288 18.9711 11.4786 19.7205C8.31989 19.8333 5.80579 22.428 5.80579 25.5948C5.80579 28.7616 8.44076 31.4933 11.7043 31.4933H11.6962L14.8388 31.4852"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4786 19.7202C11.4786 19.7202 15.274 19.1562 17.0628 21.7347"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.498 12.5728C37.498 12.5728 33.622 14.2166 34.4601 18.995"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6501_41930">
        <rect
          width="46.4384"
          height="50"
          fill="white"
          transform="translate(5 3)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RisksLightningLg;
