import React from 'react';

export interface HomeBuildingProps {
  color: string;
  size?: number;
}

export default function HomeBuilding({ color, size = 24 }: HomeBuildingProps) {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.25 20.25H5.75V3.75H18.25V20.25Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M13.9327 19.562V15.562H9.93274V19.562"
        stroke={color}
        strokeWidth="1.5"
      />
      <rect
        x="9.43052"
        y="7.00364"
        width="0.995556"
        height="1.13305"
        stroke={color}
        strokeWidth="0.995556"
      />
      <rect
        x="13.4394"
        y="7.00364"
        width="0.995556"
        height="1.13305"
        stroke={color}
        strokeWidth="0.995556"
      />
      <rect
        x="9.43052"
        y="11.2883"
        width="0.995556"
        height="1.13305"
        stroke={color}
        strokeWidth="0.995556"
      />
      <rect
        x="13.4394"
        y="11.2883"
        width="0.995556"
        height="1.13305"
        stroke={color}
        strokeWidth="0.995556"
      />
    </svg>
  );
}
