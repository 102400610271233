import React from 'react';

export interface RisksTsunamiSmProps {
  color: string;
  size?: number;
}

const RiskTsunamiSm: React.FC<RisksTsunamiSmProps> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill={color}
      d="M11.032 4.054C5.757 4.574 4.884 13.094 2 16.22c.683 0 1.784-.67 2.543-.67.759 0 1.1.633 2.315.633 1.214 0 1.86-.633 2.656-.633.797 0 1.518.633 2.467.633.949 0 1.784-.633 2.543-.633.607 0 1.821.633 2.467.633.759 0 1.745-.633 2.277-.633 1.024 0 2.277.633 2.732.633v-1.6c-.797 0-1.86-.484-2.732-.484-.873 0-1.794.645-2.543.484-9.619-2.072-3.448-11.86.91-5.283.498.75 1.073.483.95 0-.608-2.382-2.278-5.767-7.553-5.246zM22 17.485c-.797 0-1.86-.484-2.732-.484-.544.199-1.761.595-2.277.595-.646 0-1.556-.595-2.467-.595s-1.708.595-2.543.595c-.835 0-1.518-.595-2.467-.595-.949 0-1.708.595-2.656.595-.95 0-1.253-.595-2.315-.595-1.063 0-1.632.595-2.543.595v1.526c.683 0 1.784-.67 2.543-.67.759 0 1.1.633 2.315.633 1.214 0 1.86-.633 2.656-.633.797 0 1.518.633 2.467.633.949 0 1.784-.633 2.543-.633.607 0 1.821.633 2.467.633.759 0 1.745-.633 2.277-.633 1.024 0 2.277.633 2.732.633v-1.6z"
    ></path>
  </svg>
);

export default RiskTsunamiSm;
