import React from 'react';

export interface RisksCrimeLgProps {
  color: string;
  size?: number;
}

const RisksCrimeLg: React.FC<RisksCrimeLgProps> = ({ color, size = 56 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M43.2299 42.3553C47.9298 38.9801 50.7585 33.0952 50.7367 26.7559C50.7367 26.7559 50.6279 7.95457 50.6279 6.4617C47.9298 8.19256 44.8183 9.1878 41.5109 9.1878C36.3758 9.1878 31.7411 6.82951 28.3684 3C24.9958 6.82951 20.3611 9.1878 15.226 9.1878C11.9186 9.1878 8.80704 8.19256 6.10892 6.4617C6.10892 7.95457 6.00012 26.7559 6.00012 26.7559C5.97837 33.0952 8.80704 38.9801 13.507 42.3553L28.3684 53L43.2299 42.3553Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39 39.5C42.677 36.9349 46.0169 31.8723 45.9999 27.0545C45.9999 27.0545 45.9148 12.7655 45.9148 11.6309C43.8039 12.9463 41.3696 13.7027 38.782 13.7027C34.7646 13.7027 31.1386 11.9104 28.5 9C25.8614 11.9104 22.2354 13.7027 18.218 13.7027C15.6304 13.7027 13.1961 12.9463 11.0852 11.6309C11.0852 12.7655 11.0001 27.0545 11.0001 27.0545C10.9831 31.8723 13.323 36.9349 17 39.5M34.3134 42.955L28.5 47L22.6866 42.955"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3047 37.2324V39.7071"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.3047 37.2324V39.7071"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3047 24.3359V32.3987"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.8867 32.2924V24.3359"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.2275 15.5923L32.4557 18.4405L38.203 22.3018V25.2998C38.203 25.2998 37.615 25.4698 37.356 25.2998L28.2345 19.1571C28.1505 19.1004 28.0455 19.1004 27.9685 19.1571L18.847 25.2998C18.588 25.4698 18 25.2998 18 25.2998V22.3018L27.9615 15.5923C28.0455 15.5356 28.1505 15.5356 28.2275 15.5923Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 35.8138V33.1862C18 33.0835 18.0843 33 18.188 33H38V36H18.188C18.0843 36 18 35.9165 18 35.8138Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.6484 29.6777V26.0431C29.6484 25.9368 29.7324 25.8447 29.8444 25.8447H32.7356C32.8406 25.8447 32.9316 25.9297 32.9316 26.0431V29.6777H29.6484Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.0898 29.6777V26.0431C23.0898 25.9368 23.1738 25.8447 23.2859 25.8447H26.177C26.282 25.8447 26.373 25.9297 26.373 26.0431V29.6777H23.0898Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 42.7331V40.2669C17 40.1196 17.0926 40 17.2066 40H40V43H17.2066C17.0926 43 17 42.8804 17 42.7331Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksCrimeLg;
