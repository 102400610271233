import React from 'react';

export interface SubsectionsDetailedHistoryProps {
  color: string;
  size?: number;
}

const SubsectionsDetailedHistory: React.FC<SubsectionsDetailedHistoryProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clipPath="url(#clip0_6551_27342)">
      <path
        d="M50.1367 34.126H5.86323C4.79698 34.126 3.93262 34.9903 3.93262 36.0566V52.4528C3.93262 53.519 4.79698 54.3834 5.86323 54.3834H50.1367C51.2029 54.3834 52.0673 53.519 52.0673 52.4528V36.0566C52.0673 34.9903 51.2029 34.126 50.1367 34.126Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33273 34.126L46.6765 34.126L46.6765 0.932541L9.33273 0.932541L9.33273 34.126Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7516 6.55664H37.2008"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7516 12.0127H32.7706"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7516 18.1682H37.2008"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7516 23.615H32.7706"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7516 29.071H37.2008"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.1273 38.5842H14.1733V42.2776H41.1273V38.5842Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6551_27342">
        <rect
          width="50"
          height="55.3162"
          fill="white"
          transform="translate(3)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SubsectionsDetailedHistory;
