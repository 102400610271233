import React from 'react';

export interface RisksFloodingLgProps {
  color: string;
  size?: number;
}

const RisksFloodingLg: React.FC<RisksFloodingLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M22.876 15.1079L24.3974 9.42961L19.2734 8.05664L16.2336 19.4014"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.621 32.9149L34.6686 8.75714L10.5124 22.7037"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2781 37.9043L15.0273 23.9121"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.5 37.2004L32.9914 32.1683L24.418 29.8711L22.4541 37.2004"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.0938 31.7011L42 38"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 39.4766C7.16215 39.4766 7.16215 43.1668 11.3166 43.1668C15.471 43.1668 15.4787 39.4766 19.6486 39.4766C23.8185 39.4766 23.8108 43.1668 27.9807 43.1668C32.1506 43.1668 32.1428 39.4766 36.3127 39.4766C40.4826 39.4766 40.4826 43.1668 44.6602 43.1668C48.8378 43.1668 48.8301 39.4766 53.0077 39.4766"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 45.3105C7.16215 45.3105 7.16215 49.0008 11.3166 49.0008C15.471 49.0008 15.4787 45.3105 19.6486 45.3105C23.8185 45.3105 23.8108 49.0008 27.9807 49.0008C32.1506 49.0008 32.1428 45.3105 36.3127 45.3105C40.4826 45.3105 40.4826 49.0008 44.6602 49.0008C48.8378 49.0008 48.8301 45.3105 53.0077 45.3105"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksFloodingLg;
