'use client';

import { Button, ModalBody } from '@nextui-org/react';
import SectionHeadingGroup from '../../SectionHeadingGroup';

import ModalHeader from './ModalHeader';
import { FC } from 'react';
import { useModalContext } from '../../../hooks/useModal';
type Props = {
  onModalClose: () => void;
};

const RetrievingContent: FC<Props> = ({ onModalClose }: Props) => {
  const { selectedPlace } = useModalContext();
  const { address1, address2 } = selectedPlace;
  const formattedStreetAddress = `${address1}${address2 ? ` ${address2}` : ''}`;
  return (
    <>
      <ModalHeader>
        <SectionHeadingGroup
          level={2}
          heading="We're on it! Check back soon."
          description={`It will take a few minutes to build your custom PropertyLens report for ${formattedStreetAddress}.`}
          classNames={{
            description: 'mt-4',
            heading: 'font-[raleway]'
          }}
        />
      </ModalHeader>
      <ModalBody className="items-center justify-center">
        <div className="space-y-7 text-center">
          <Button
            radius="sm"
            color="primary"
            size="lg"
            type="button"
            onPress={onModalClose}
          >
            Done
          </Button>
          <div className="text-sm text-[#858585]">
            <p className="font-bold">
              Don&apos;t worry! You can close this window.
            </p>
            <p>We&apos;ll send you an email when your report is ready.</p>
          </div>
        </div>
      </ModalBody>
    </>
  );
};

export default RetrievingContent;
