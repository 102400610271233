import React from 'react';

export interface RisksTornadoSmProps {
  color: string;
  size?: number;
}

const RisksTornadoSm: React.FC<RisksTornadoSmProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.6441 9.06565C14.8983 9.79886 13.339 10.3987 11.4407 10.5987C8.72881 10.932 6.62712 10.1988 6.35593 9.19895M7.10169 11.3986C7.30508 12.2651 9.20339 12.7317 11.4407 12.3984C13.1356 12.1318 14.5593 11.5319 15.1017 10.7987M8.52542 13.7982C8.86441 14.3981 10.1525 14.598 11.7119 14.1981C12.8644 13.9315 13.7458 13.3982 14.1525 12.865M9.0678 15.6645C9.33898 16.1311 10.4237 16.3311 11.7797 15.9978C12.9322 15.7312 13.8136 15.1313 13.9492 14.6647M11.1695 17.5309C11.3051 17.7308 11.8475 17.7975 12.4576 17.5975C13 17.3976 13.339 17.1309 13.4068 16.8643M12.5932 18.9306C12.7288 19.064 13 18.9973 13.2712 18.7973C13.5424 18.664 13.678 18.4641 13.7458 18.3308M13.6102 3.19996C12.9322 3.06665 12.1186 3 11.3051 3C7.84746 3 5 4.39975 5 5.79952C5 6.33276 5.47458 6.99931 6.28814 7.4659C7.23729 7.99915 9.13559 8.39909 11.3051 8.39909C14.4237 8.39909 17 7.59923 17 6.66605C17 5.66622 14.4237 5.06632 11.3051 5.06632C9.61017 5.06632 8.11864 5.33293 7.16949 5.86618"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksTornadoSm;
