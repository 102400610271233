import React from 'react';

export interface ActionFavoriteProps {
  color: string;
  size?: number;
}

const ActionFavorite: React.FC<ActionFavoriteProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17.9847 12.6172L12.8014 17.6198C12.3171 18.0873 11.5496 18.0873 11.0653 17.6198L5.88199 12.6172C4.37267 11.1605 4.37267 8.81008 5.88199 7.35336C7.40625 5.88221 9.88822 5.88221 11.4125 7.35336C11.7031 7.63383 12.1636 7.63383 12.4542 7.35336C13.9784 5.88221 16.4604 5.88221 17.9847 7.35336C19.494 8.81008 19.494 11.1605 17.9847 12.6172Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ActionFavorite;
