import React from 'react';

export interface RoomsBathroomProps {
  color: string;
  size?: number;
}

const RoomsBathroom: React.FC<RoomsBathroomProps> = ({ color, size = 24 }) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_36884)">
        <path
          d="M13.5296 5.81543C10.6812 5.81543 8.3728 8.12379 8.3728 10.9722H18.6864C18.6864 8.12379 16.378 5.81543 13.5296 5.81543Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5296 5.81534C13.5296 4.07318 12.1185 2.65332 10.3676 2.65332H7.80663C6.06447 2.65332 4.65332 4.06447 4.65332 5.81534V21.3467"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1934 21.3466V13.1846"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5295 21.3466V13.1846"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.866 21.3466V13.1846"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_36884">
          <rect
            width="15.3397"
            height="20"
            fill="white"
            transform="translate(4 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoomsBathroom;
