import React from 'react';

export interface RisksLightningSmProps {
  color: string;
  size?: number;
}

const RisksLightningSm: React.FC<RisksLightningSmProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5.50428 15.2222C3.53847 15.0513 2 13.4273 2 11.3761C2 9.32479 3.70941 7.61539 5.76069 7.52992M5.76069 7.52992C6.44445 4.88034 8.83761 3 11.6581 3C13.7949 3 15.7607 4.11111 16.7863 5.82051M5.76069 7.52992C6.70086 7.52992 7.64103 7.8718 8.32479 8.47009M16.7863 5.82051C16.9573 5.82051 17.1282 5.73504 17.2991 5.73504C19.8632 5.73504 22 7.87179 22 10.4359C22 13 19.8632 15.1367 17.2991 15.1367M16.7863 5.82051C16.0171 5.90598 15.2479 6.16239 14.6496 6.58974M15.9316 9.92308H11.5727L8.06838 15.2222H10.2906L8.92308 21.9743L15.0769 15.2222H12.2564L15.9316 9.92308Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksLightningSm;
