import './Card.scss';

import cx from 'classnames';
import React from 'react';

import Badge from '../Badge';
import formatValue from '../DetailedList/formatValue';
import Icon from '../Icons';
import { IconNameType } from '../Icons/Icon';
import { ListItemType } from '../../types';

/**
 * Card component
 */
type CardProps = {
  label: string | number;
  badge?: string;
  subLabel?: string | number;
  icon?: IconNameType;
  level?: string;
  sideValue?: string;
  type?: ListItemType;
  formatCase?: string;
};
const Card: React.FC<CardProps> = (props) => {
  const { badge, icon, label, level, sideValue, subLabel, type, formatCase } =
    props;
  const divCx = cx('Card shadow-medium', {
    'has-badge': badge,
    [`${level}`]: level,
  });

  const formattedLabel = formatValue({ type, value: label || '' });
  const formattedBadge = formatValue({
    type,
    value: badge || '',
    formatCase: formatCase,
  });

  return (
    <div className={divCx} data-testid="card">
      {sideValue && <div className="side-value">{sideValue}</div>}
      {icon && !sideValue && (
        <Icon icon={icon} className="side-value" size="lg" />
      )}
      <div className="main-value">
        {badge ? (
          <Badge level={level} label={formattedBadge} />
        ) : (
          <div className="empty-div" />
        )}
        <div className="label font-raleway capitalize">{formattedLabel}</div>
        <div className="sub-label">{subLabel}</div>
      </div>
    </div>
  );
};

export default Card;
