import React from 'react';

export interface SubsectionsEstimatedUtilityProps {
  color: string;
  size?: number;
}

const SubsectionsEstimatedUtility: React.FC<
  SubsectionsEstimatedUtilityProps
> = ({ color, size = 56 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <g clipPath="url(#clip0_6551_27349)">
      <path
        d="M28.9099 48.2772H6.6742C5.12308 48.2772 3.86658 47.0207 3.86658 45.4609V6.68275C3.86658 5.13162 5.12308 3.86646 6.6742 3.86646H36.3969C37.9567 3.86646 39.2132 5.13162 39.2132 6.68275V24.2824"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.9498 8.12134H8.12134V18.1127H34.9498V8.12134Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.6135 33.78C41.6135 33.78 40.825 31.9775 37.8094 31.9775C34.7938 31.9775 32.766 36.2583 36.9255 37.3762C41.6655 38.65 42.3068 39.5252 41.9168 41.3796C41.3969 43.7973 34.8891 44.2046 34.6118 40.8077"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.208 30.175V46.0156"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.0848 47.8847C53.4926 42.4769 53.4926 33.7091 48.0848 28.3014C42.677 22.8936 33.9093 22.8936 28.5015 28.3014C23.0937 33.7091 23.0937 42.4769 28.5015 47.8847C33.9093 53.2924 42.677 53.2924 48.0848 47.8847Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.12134 23.7451H13.2773"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8561 23.7451H22.0035"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6603 23.7451H30.8163"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.12134 27.9827H13.2773"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8561 27.9827H22.0035"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.12134 31.9775H13.2773"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8561 31.9775H22.0035"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.12134 36.0935H13.2773"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8561 36.0935H22.0035"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.12134 40.0972H13.2773"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8561 40.0972H22.0035"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.12134 44.0918H13.2773"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8561 44.0918H22.0035"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6551_27349">
        <rect
          width="50"
          height="49.8094"
          fill="white"
          transform="translate(3 3)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SubsectionsEstimatedUtility;
