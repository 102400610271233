import React from 'react';

export interface HomeNeighborhoodProps {
  color: string;
  size?: number;
}

const HomeNeighborhood: React.FC<HomeNeighborhoodProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_36559)">
        <path
          d="M12.3653 21.4107H3V12.5918L12.3653 7.60656V21.4191V21.4107ZM12.3653 21.4107H21.7306V8.98529L12.3653 4V21.4107ZM9.11181 21.4107V16.1564H6.26187V21.4107M18.4771 21.4107V16.1564H15.6272V21.4107M20.5704 8.36318V4.21017H18.5275V7.27028"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_36559">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HomeNeighborhood;
