import React from 'react';

export interface RisksWinterSmProps {
  color: string;
  size?: number;
}

const RisksWinterSm: React.FC<RisksWinterSmProps> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11.3984 8.8996L14.3984 10.5996M11.3984 8.8996L8.39844 10.5996V14.0996M11.3984 8.8996V1.59961M14.3984 10.5996V14.0996M14.3984 10.5996L20.6984 6.8996M14.3984 14.0996L11.3984 15.7996M14.3984 14.0996L20.6984 17.7996M11.3984 15.7996L8.39844 14.0996M11.3984 15.7996V23.0996M8.39844 14.0996L2.09843 17.7996M7.99844 2.79961L11.3984 6.29961M14.6984 2.79961L11.2984 6.29961M1.99844 6.9996L8.29844 10.6996M1.39844 10.4996L6.09843 9.29961L4.79844 4.6996M4.79844 20.0996L6.09843 15.4996M1.39844 14.1996L6.09843 15.3996M14.6984 21.8996L11.2984 18.3996M7.99844 21.8996L11.3984 18.3996M21.2984 14.1996L16.5984 15.3996L17.8984 19.9996M17.8984 4.6996L16.5984 9.29961L21.2984 10.4996"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksWinterSm;
