import { createContext, useContext, useState, HTMLProps, FC, Dispatch, SetStateAction } from 'react';
import { ServerReport } from '../../types';

export enum PageState {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
  NOT_FOUND = 'not_found',
  SEARCHING = 'searching',
}

export enum SortBy {
  ASC = 'asc',
  DESC = 'desc',
}

export const onSort = (list: ServerReport[], newSortBy: SortBy) => {
  const sortedList = list.sort((a: ServerReport, b: ServerReport) => {
    const aCreatedDate = a.report_body.created_dt;
    const bCreatedDate = b.report_body.created_dt;

    if (a.status === 'pending' && b.status !== 'pending') {
      return -1;
    } else if (a.status !== 'pending' && b.status === 'pending') {
      return 1;
    } else {
      if (newSortBy === SortBy.ASC) {
        return (
          new Date(aCreatedDate).valueOf() - new Date(bCreatedDate).valueOf()
        );
      }
      if (newSortBy === SortBy.DESC) {
        return (
          new Date(bCreatedDate).valueOf() - new Date(aCreatedDate).valueOf()
        );
      }
    }
    return 0;
  });

  return sortedList;
};

export interface MyReportsContextType {
  getReports: () => void;
  reports: ServerReport[];
  setReports: Dispatch<SetStateAction<ServerReport[]>>;
  originalReports: ServerReport[];
  setOriginalReports: Dispatch<SetStateAction<ServerReport[]>>;
  pageState: PageState;
  setPageState: Dispatch<SetStateAction<PageState>>;
  hasPendingReport: boolean;
  setHasPendingReport: Dispatch<SetStateAction<boolean>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
}

type MyReportsContextProviderProps = HTMLProps<HTMLDivElement>;

const MyReportsContext = createContext<MyReportsContextType>(
  {} as MyReportsContextType
);
export const MyReportsContextProvider: FC<
  MyReportsContextProviderProps
> = ({ children }) => {
  const [originalReports, setOriginalReports] = useState<ServerReport[]>([]);
  const [reports, setReports] = useState<ServerReport[]>([]);
  const [pageState, setPageState] = useState<PageState>(PageState.LOADING);
  const [error, setError] = useState<string>('');
  const [hasPendingReport, setHasPendingReport] = useState<boolean>(false);

  return (
    <MyReportsContext.Provider
      value={{
        getReports: async () => {
          try {
            const reportsResponse = await fetch('/api/reports');
            const reportsData = await reportsResponse.json();
            if (reportsData.success) {
              const reportsWithSearchValues = reportsData.data.map(
                (report: ServerReport) => {
                  const reportBody = report.report_body;
                  const searchValues = Object.values(reportBody)
                    .join(' ')
                    .replaceAll('  ', ' ');

                  return {
                    ...report,
                    ui_search: searchValues,
                  };
                }
              );
              const reportHasPending = reportsData.data.some(
                (report: ServerReport) => report.status === 'pending'
              );
              setHasPendingReport(reportHasPending);
              setOriginalReports(reportsWithSearchValues);
              // Sort data from newest to oldest
              const sortedList = onSort(reportsWithSearchValues, SortBy.DESC);
              setReports(sortedList);
              setPageState(PageState.SUCCESS);
            } else {
              setError(reportsData.data.details);
              throw new Error(reportsData.data.details);
            }
          } catch (error) {
            setPageState(PageState.ERROR);
          }
        },
        originalReports,
        setOriginalReports,
        reports,
        setReports,
        pageState,
        setPageState,
        hasPendingReport,
        setHasPendingReport,
        error,
        setError,
      }}
    >
      {children}
    </MyReportsContext.Provider>
  );
};

export const useMyReportsContext = () => {
  const context = useContext(MyReportsContext);
  if (context === undefined) {
    throw new Error(
      'useMyReportsContext must be used within a MyReportsProvider'
    );
  }

  return context;
};
