import React from 'react';

export interface SystemsHeatingProps {
  color: string;
  size?: number;
}

const SystemsHeating: React.FC<SystemsHeatingProps> = ({ color, size = 24 }) => {

  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask id="path-1-inside-1_3925_56744" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.75 0.75C4.36929 0.75 3.25 1.86929 3.25 3.25V13.9189C1.7555 14.7834 0.75 16.3993 0.75 18.25C0.75 21.0114 2.98858 23.25 5.75 23.25C8.51142 23.25 10.75 21.0114 10.75 18.25C10.75 16.3993 9.7445 14.7834 8.25 13.9189V3.25C8.25 1.86929 7.13071 0.75 5.75 0.75Z"
        />
      </mask>
      <path
        d="M3.25 13.9189L4.00109 15.2173L4.75 14.7841V13.9189H3.25ZM8.25 13.9189H6.75V14.7841L7.49891 15.2173L8.25 13.9189ZM4.75 3.25C4.75 2.69772 5.19772 2.25 5.75 2.25V-0.75C3.54086 -0.75 1.75 1.04086 1.75 3.25H4.75ZM4.75 13.9189V3.25H1.75V13.9189H4.75ZM2.25 18.25C2.25 16.9564 2.95102 15.8248 4.00109 15.2173L2.49891 12.6205C0.559971 13.7421 -0.75 15.8422 -0.75 18.25H2.25ZM5.75 21.75C3.817 21.75 2.25 20.183 2.25 18.25H-0.75C-0.75 21.8398 2.16015 24.75 5.75 24.75V21.75ZM9.25 18.25C9.25 20.183 7.683 21.75 5.75 21.75V24.75C9.33985 24.75 12.25 21.8398 12.25 18.25H9.25ZM7.49891 15.2173C8.54898 15.8248 9.25 16.9564 9.25 18.25H12.25C12.25 15.8422 10.94 13.7421 9.00109 12.6205L7.49891 15.2173ZM6.75 3.25V13.9189H9.75V3.25H6.75ZM5.75 2.25C6.30228 2.25 6.75 2.69772 6.75 3.25H9.75C9.75 1.04086 7.95914 -0.75 5.75 -0.75V2.25Z"
        fill={color}
        mask="url(#path-1-inside-1_3925_56744)"
      />
      <circle cx="5.75" cy="18.25" r="1.25" stroke={color} strokeWidth="1.5" />
      <path
        d="M12.25 3.25H21.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.25 7.25H18.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.75 11.25H13.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SystemsHeating;