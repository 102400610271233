import React from 'react';

export interface SubsectionsFloodRiskSmProps {
  color: string;
  size?: number;
}

const SubsectionsFloodRiskSm: React.FC<SubsectionsFloodRiskSmProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M13.7734 9.74609C14.4398 10.8957 15.1242 12.4476 15.3913 14.4857"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00391 14.4893C8.26805 12.4842 8.94042 10.9533 9.59178 9.80371"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.67419 15.5911C5.97391 15.5911 7.10253 16.4285 7.67884 17.6622C7.78991 17.8993 8.10808 17.9083 8.23115 17.6772C8.89151 16.4375 10.1972 15.5941 11.7011 15.5941C13.2049 15.5941 14.5106 16.4375 15.171 17.6772C15.294 17.9083 15.6122 17.8993 15.7233 17.6622C16.2996 16.4315 17.4282 15.5911 18.7279 15.5911C19.5444 15.5911 20.2918 15.9212 20.8801 16.4705C21.1112 16.6867 21.4804 16.4675 21.3874 16.1644C20.1057 12.0281 16.2606 9.02344 11.7041 9.02344C7.14755 9.02344 3.29643 12.0281 2.01473 16.1644C1.92168 16.4675 2.29088 16.6897 2.52201 16.4705C3.11033 15.9212 3.85774 15.5911 4.67419 15.5911Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6992 7V7.81045"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6992 7.81055V8.12272"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

export default SubsectionsFloodRiskSm;
