import React from 'react';

export interface DocumentsTitleProps {
  color: string;
  size?: number;
}

const DocumentsTitle: React.FC<DocumentsTitleProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M20.9999 6.84444V8.56533C20.9999 9.68889 20.2888 10.4 19.1653 10.4H16.7333V5.42933C16.7333 4.64 17.3804 4 18.1697 4C18.9448 4.00711 19.6559 4.32 20.1679 4.832C20.6799 5.35111 20.9999 6.06222 20.9999 6.84444Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.77781 16.0889V7.55556V6.84444C6.77781 5.27289 7.48892 4 9.62226 4H10.3334H18.1556C17.3734 4 16.7334 4.64 16.7334 5.42222V17C16.7334 18.6569 15.3902 20 13.7334 20H7.35559C5.69874 20 4.35559 18.6569 4.35559 17V16.4444H12.1778C12.7301 16.4444 13.1778 16.8921 13.1778 17.4444C13.1778 17.6523 13.1778 17.8631 13.1778 18.0444C13.1778 19.2889 13.8889 20 13.8889 20"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8889 11.8223H9.62225"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8889 8.97754H9.62225"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocumentsTitle;
