import React from 'react';

export interface RisksHealthSafetyLgProps {
  color: string;
  size?: number;
}

const RisksHealthSafetyLg: React.FC<RisksHealthSafetyLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 56 56"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeWidth="2"
      d="M30.57 15.917v1h4.958v5.438h-4.959v4.959h-5.438v-4.959h-4.959v-5.438h4.96V11.96h5.437v3.958z"
    ></path>
    <rect
      width="27.752"
      height="42.629"
      x="13.925"
      y="7"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      rx="1"
    ></rect>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21.957 34.273H34.833V49.628H21.957z"
    ></path>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M28.034 34.166v14.876M13.917 21.173H6a2 2 0 00-2 2v24.513a2 2 0 002 2h7.917M42.083 21.173H50a2 2 0 012 2v24.513a2 2 0 01-2 2h-7.917M46.15 26.53v3.57M46.15 33.67v3.57M46.15 40.81v3.57M8.661 26.53v3.57M8.661 33.67v3.57M8.661 40.81v3.57"
    ></path>
  </svg>
);

export default RisksHealthSafetyLg;
