import React from 'react';

export interface RisksPollutionSmProps {
  color: string;
  size?: number;
}

const RisksPollutionSm: React.FC<RisksPollutionSmProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3.75 20C3.75 19.8619 3.86193 19.75 4 19.75H21C21.1381 19.75 21.25 19.8619 21.25 20V22C21.25 22.1381 21.1381 22.25 21 22.25H4C3.86193 22.25 3.75 22.1381 3.75 22V20Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8 19L7.5 14H5.5L5 19"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 19L19.5 14H17.5L17 19"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 19L14.8333 9H10.1667L9 19"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 12.5H10.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M5.96904 6C5.96904 6 5.16535 6.68091 5.46806 7.5C5.71211 8.16037 6.9139 8.52519 6.96806 9.5C7.0165 10.3719 6.47021 11 6.47021 11"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M17.9676 6C17.9676 6 17.1639 6.68091 17.4666 7.5C17.7106 8.16037 18.9124 8.52519 18.9666 9.5C19.015 10.3719 18.4688 11 18.4688 11"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M13.5691 2C13.5691 2 12.7654 2.68091 13.0682 3.5C13.3122 4.16037 14.514 4.52519 14.5682 5.5C14.6166 6.37189 14.0703 7 14.0703 7"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10.5691 2C10.5691 2 9.76545 2.68091 10.0682 3.5C10.3122 4.16037 11.514 4.52519 11.5682 5.5C11.6166 6.37189 11.0703 7 11.0703 7"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default RisksPollutionSm;
