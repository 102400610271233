import { FC } from 'react';

export interface Props {
  color: string;
  size?: number;
}

const RisksHealthSafetySm: FC<Props> = ({ color, size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M8.253 5.583h-.834a1.833 1.833 0 00-1.833 1.833v11a1.833 1.833 0 001.833 1.834h9.167a1.833 1.833 0 001.833-1.834v-11a1.833 1.833 0 00-1.833-1.833h-.833"
    ></path>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9 5.5c0-.398.155-.78.43-1.06A1.45 1.45 0 0110.467 4h3.066c.39 0 .762.158 1.037.44.275.28.43.662.43 1.06s-.155.78-.43 1.06a1.45 1.45 0 01-1.037.44h-3.066a1.45 1.45 0 01-1.037-.44A1.517 1.517 0 019 5.5zM11.993 15.354l2.518-2.58a1.742 1.742 0 000-2.413 1.626 1.626 0 00-2.347 0l-.168.172-.168-.173a1.626 1.626 0 00-2.346 0 1.74 1.74 0 000 2.413l2.51 2.59v-.009z"
    ></path>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeWidth="1.5"
      d="M9.75 17.75L14.25 17.75"
    ></path>
  </svg>
);

export default RisksHealthSafetySm;
