import React from 'react';

export interface FinancialPurchaseProps {
  color: string;
  size?: number;
}

const FinancialPurchase: React.FC<FinancialPurchaseProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_36504)">
        <path
          d="M16.7349 3H7.17419C5.97342 3 5 3.97342 5 5.17419V18.4974C5 18.9481 5.175 19.3812 5.4881 19.7053L5.72749 19.9532C6.28017 20.5253 7.19711 20.5253 7.74979 19.9532C8.18148 19.5062 8.85538 19.3957 9.4072 19.6814L10.9551 20.4826C11.5819 20.8071 12.3272 20.8071 12.954 20.4826L14.599 19.6311C15.1213 19.3607 15.7423 19.3607 16.2646 19.6311C17.4705 20.2553 18.9091 19.3801 18.9091 18.0223V5.17419C18.9091 3.97342 17.9357 3 16.7349 3Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M10.0289 10.9707L10.3761 11.3302C10.7077 11.6735 11.2579 11.6735 11.5895 11.3302L13.8445 8.99561"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M8.47644 15.5986H15.431"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_36504">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FinancialPurchase;
