import React from 'react';

export interface HomeRentalListingProps {
  color: string;
  size?: number;
}

export default function HomeRentalListing({
  color,
  size = 24,
}: HomeRentalListingProps) {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_6482_21267)">
        <path
          d="M19.8607 10.854L22.5 12V8.29017L20.4457 7.58494L19.4185 7.23232L19.4375 4H16.0516V5.82186L11.9429 4L1.5 8.29017V12L4.11073 10.854M7.5 9.5L11.9429 7.41598L16.5 9.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <circle
          cx="12.1381"
          cy="16.0053"
          r="2.23964"
          stroke={color}
          strokeWidth="1.5"
        />
        <circle
          cx="17.4297"
          cy="11.7793"
          r="2.5"
          stroke={color}
          strokeWidth="1.5"
        />
        <circle
          cx="6.42969"
          cy="11.7793"
          r="2.5"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M19.4166 14.1504V14.1504C20.9466 14.616 21.9922 16.0273 21.9922 17.6265V19.7652"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M4.63418 14.1504V14.1504C3.10423 14.616 2.05859 16.0273 2.05859 17.6265V19.7652"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M10.1224 17.2373V17.2373C9.17162 17.643 8.55469 18.577 8.55469 19.6107V21.8207"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M14.0416 17.2373V17.2373C14.9924 17.643 15.6094 18.577 15.6094 19.6107V21.8207"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M9 13L9.22766 13.1204C9.89452 13.4732 10.4944 13.9401 11 14.5V14.5"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M15 13L14.7723 13.1204C14.1055 13.4732 13.5056 13.9401 13 14.5V14.5"
          stroke={color}
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_6482_21267">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
