import React, { FunctionComponent } from 'react';

export interface FinancialPaymentProps {
  color: string;
  size?: number;
}

const FinancialPayment: FunctionComponent<FinancialPaymentProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 12H5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3336 11.141V12.8577C20.3336 13.316 19.9669 13.691 19.5003 13.7077H17.8669C16.9669 13.7077 16.1419 13.0493 16.0669 12.1493C16.0169 11.6243 16.2169 11.1327 16.5669 10.791C16.8752 10.4743 17.3002 10.291 17.7669 10.291H19.5003C19.9669 10.3077 20.3336 10.6826 20.3336 11.141Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5667 10.7915C16.2167 11.1332 16.0168 11.6248 16.0668 12.1498C16.1418 13.0498 16.9667 13.7082 17.8667 13.7082H19.5001V14.9165C19.5001 17.4165 17.8334 19.0832 15.3334 19.0832H8.83341C6.33341 19.0832 4.66675 17.4165 4.66675 14.9165V9.08317C4.66675 6.8165 6.03342 5.23317 8.15842 4.9665C8.37508 4.93317 8.60008 4.9165 8.83341 4.9165H15.3334C15.5501 4.9165 15.7584 4.92483 15.9584 4.95816C18.1084 5.20816 19.5001 6.79984 19.5001 9.08317V10.2915H17.7667C17.3001 10.2915 16.8751 10.4748 16.5667 10.7915Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FinancialPayment;
