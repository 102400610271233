import './Badge.scss';

import cx from 'classnames';
import React from 'react';
import { RatingLevelProps } from '../../types';

type BadgeProps = {
  label: string | number | React.ReactNode;
  level?: RatingLevelProps | React.ReactNode;
};

function Badge(props: BadgeProps) {
  const { level = null, label } = props;

  const divCx = cx('Badge font-inter', level);
  return (
    <div className={divCx} data-testid="badge">
      {label}
    </div>
  );
}

export default Badge;
