import React from 'react';

export interface RisksWindLgProps {
  color: string;
  size?: number;
}

const RisksWindLg: React.FC<RisksWindLgProps> = ({ color, size = 56 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M22.0156 47V35.9884C22.0156 33.1226 23.192 30.3824 25.2697 28.4086L30.7262 23.225C31.4613 22.5266 32.603 22.4895 33.382 23.1386V23.1386C34.2673 23.8764 34.3468 25.2082 33.5555 26.046L28.8276 31.052C27.3428 32.6242 26.5156 34.7047 26.5156 36.8672V47"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M22.5156 33.4167L19.6535 31.6952C16.5501 29.8287 17.3266 25.1281 20.8654 24.3587L21.7281 24.1712C22.1877 24.0713 22.5156 23.6645 22.5156 23.194V19.8758C22.5156 16.4294 26.5832 14.5963 29.1651 16.8791L30.2052 17.7987C30.5131 18.071 30.9563 18.1271 31.3224 17.9401L32.8831 17.143C35.82 15.6429 39.2084 18.1415 38.6433 21.3906L38.1006 24.5115C38.0471 24.8193 38.1404 25.1344 38.353 25.3633L39.1569 26.2291C41.384 28.6275 39.9451 32.5396 36.6947 32.9233L33.3871 33.3138C32.9369 33.3669 32.6487 33.8195 32.7908 34.2499V34.2499C33.6573 36.8735 30.6747 39.0829 28.4174 37.4895L27.0156 36.5"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M15 14.375H19.2188C20.3906 14.375 21.4062 13.4375 21.4062 12.1875C21.4062 10.9375 20.4688 10 19.2188 10C18.8281 10 18.4375 10.0781 18.125 10.3125M18.2969 17.7187H9.51562"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43 20.375H47.2188C48.3906 20.375 49.4062 19.4375 49.4062 18.1875C49.4062 16.9375 48.4688 16 47.2188 16C46.8281 16 46.4375 16.0781 46.125 16.3125M47.2969 23.7187H41.5156"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35 41.375H39.2188C40.3906 41.375 41.4062 40.4375 41.4062 39.1875C41.4062 37.9375 40.4688 37 39.2188 37C38.8281 37 38.4375 37.0781 38.125 37.3125M37.2969 43.7187H31.5156"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 30.375H11.2188C12.3906 30.375 13.4062 29.4375 13.4062 28.1875C13.4062 26.9375 12.4688 26 11.2188 26C10.8281 26 10.4375 26.0781 10.125 26.3125M14.2969 33.7187H9.51562"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.51562 47H40.0156"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RisksWindLg;
