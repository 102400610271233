import React from 'react';

export interface DocumentsDisclosureProps {
  color: string;
  size?: number;
}

const DocumentsDisclosure: React.FC<DocumentsDisclosureProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5.625 16.5V8.25C5.625 5.25 6.375 4.5 9.375 4.5H14.625C17.625 4.5 18.375 5.25 18.375 8.25V15.75C18.375 15.855 18.375 15.96 18.3675 16.065"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.7625 14.25H18.375V16.875C18.375 18.3225 17.1975 19.5 15.75 19.5H8.25C6.8025 19.5 5.625 18.3225 5.625 16.875V16.3875C5.625 15.21 6.585 14.25 7.7625 14.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 8H15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11H12.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocumentsDisclosure;
