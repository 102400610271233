import React from 'react';

export interface AmenitiesNoiseProps {
  color: string;
  size?: number;
}

const AmenitiesNoise: React.FC<AmenitiesNoiseProps> = ({
  color,
  size = 24,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_5205_36199)">
      <path
        d="M6.80005 11.7001V10.5C6.80005 7.73857 9.03863 5.5 11.8 5.5V5.5C14.5615 5.5 16.8 7.73858 16.8 10.5V12.2361"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M5 16.5183V14.9149C5 13.2684 6.33479 11.9336 7.98134 11.9336C8.98579 11.9336 9.80005 12.7479 9.80005 13.7523V17.681C9.80005 18.6854 8.98579 19.4997 7.98134 19.4997C6.33479 19.4997 5 18.1649 5 16.5183Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M19.0001 16.5183V14.9149C19.0001 13.2684 17.6653 11.9336 16.0188 11.9336C15.0143 11.9336 14.2001 12.7479 14.2001 13.7523V17.681C14.2001 18.6854 15.0143 19.4997 16.0188 19.4997C17.6653 19.4997 19.0001 18.1649 19.0001 16.5183Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_5205_36199">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default AmenitiesNoise;
