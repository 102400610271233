import React from 'react';

export interface RoomsBasementProps {
  color: string;
  size?: number;
}

const RoomsBasement: React.FC<RoomsBasementProps> = ({ color, size = 24 }) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_5205_36921)">
      <path
        d="M21.25 12V21.25H2.75V12C2.75 9.54675 3.72455 7.19398 5.45926 5.45926C7.19398 3.72455 9.54675 2.75 12 2.75C14.4532 2.75 16.806 3.72455 18.5408 5.45926C20.2755 7.19397 21.25 9.54674 21.25 12Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.71387 17.5547H21.2853"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.71387 17.3976V12.9531H21.0472"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0479 12.4755V8.66602H20.3812"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5205_36921">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RoomsBasement;
