'use client';

import { ChangeEvent, useState, FC } from 'react';
import { Button, Input, ModalBody } from '@nextui-org/react';

import {
  useModalContext,
  ModalErrorView,
  ModalView,
} from '../../../hooks/useModal';
import { ADDRESS_INPUT_ERROR } from '../../../constants';
import ModalHeader from './ModalHeader';
import SectionHeadingGroup from '../../SectionHeadingGroup';
import validateAddress from '../../../utils/validateAddress';
import { ValidationState } from '../GetReportsModal';
import { emitTrackingEvent } from '@propertylens/app-commons';

type Props = {
  googleAPIKey: string;
};

const SecondaryForm: FC<Props> = ({ googleAPIKey }: Props) => {
  const {
    selectedPlace,
    setSelectedPlace,
    setModalView,
    setModalErrorView,
    addressInputError,
    addressInputErrorType,
    setAddressInputError,
    setAddressInputErrorType,
  } = useModalContext();
  const { address1, locality, state, zipCode } = selectedPlace;
  const [inputValue, setInputValue] = useState('');
  const [validationState, setValidationState] = useState<ValidationState>(
    ValidationState.Idle
  );
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setSelectedPlace({ ...selectedPlace, address2: event.target.value });
  };

  const handleGetReport = async () => {
    if (validationState === 'loading') return;
    if (!inputValue) {
      setAddressInputErrorType(ADDRESS_INPUT_ERROR.INVALID_ADDRESS_2);
      setAddressInputError(true);
      return;
    }
    try {
      emitTrackingEvent('Address Unit Added', {
        address: {
          ...selectedPlace
        }
      });
      setValidationState(ValidationState.Loading);
      const validateAddressResult = await validateAddress(
        selectedPlace,
        googleAPIKey
      );
      setValidationState(ValidationState.Idle);
      const unconfirmedComponentTypes =
        validateAddressResult?.address?.unconfirmedComponentTypes;
      if (unconfirmedComponentTypes && unconfirmedComponentTypes.length > 0) {
        if (unconfirmedComponentTypes.includes('subpremise')) {
          setAddressInputError(true);
          setAddressInputErrorType(ADDRESS_INPUT_ERROR.INVALID_ADDRESS_2);
        } else {
          setAddressInputError(true);
          setAddressInputErrorType(ADDRESS_INPUT_ERROR.INVALID_ADDRESS);
        }
        emitTrackingEvent('Address Unit Error', {
          address: {
            ...selectedPlace
          },
          reason: 'INVALID_ADDRESS',
          validationResult: validateAddressResult?.verdict
        });
      } else {
        setModalView(ModalView.Preview);
      }
    } catch (error) {
      setModalView(ModalView.Error);
      setModalErrorView(ModalErrorView.ErrorOnBase);
    }
  };

  const handleGoBack = () => {
    setModalView(ModalView.Idle);
  };

  return (
    <>
      <ModalHeader>
        <SectionHeadingGroup
          heading="Please add unit/floor number"
          level={2}
          description="For condos, please be sure to include the unit or floor number for accuracy. For multi-family homes, simply enter one of the units and we will gather data for all!"
        />
      </ModalHeader>
      <ModalBody className="p-0">
        <p>
          {address1} {locality} {state} {zipCode}
        </p>
        <div className="flex flex-col gap-8">
          <Input
            type="text"
            label="Unit/Floor Number"
            value={inputValue}
            onChange={handleInputChange}
            variant="bordered"
          />
          {addressInputError && (
            <p className="text-red-500">
              {(() => {
                switch (addressInputErrorType) {
                  case ADDRESS_INPUT_ERROR.INVALID_ADDRESS:
                    return 'Please enter a valid address.';
                  case ADDRESS_INPUT_ERROR.INVALID_ADDRESS_2:
                    return 'Please enter a valid unit/floor number';
                  default:
                    return 'Please enter a valid address.';
                }
              })()}
            </p>
          )}
          <div className="flex justify-end gap-2">
            <Button
              variant="bordered"
              radius="sm"
              type="button"
              color="primary"
              className="border-[#050a30] disabled:bg-gray-300 disabled:opacity-80"
              size="lg"
              onPress={handleGoBack}
              style={{ borderColor: '#050A30' }}
            >
              Back
            </Button>
            <Button
              radius="sm"
              type="button"
              color="primary"
              className="disabled:bg-gray-300 disabled:opacity-80"
              size="lg"
              onPress={handleGetReport}
              disabled={validationState === 'loading'}
            >
              Get Report
            </Button>
          </div>
        </div>
      </ModalBody>
    </>
  );
};

export default SecondaryForm;
