import React from 'react';

const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      version="1.1"
      viewBox="0 0 657.53 105.56"
      className="w-48"
    >
      <defs>
        <style>
          {`.cls-1{fill:#342e2f}.cls-1,.cls-3,.cls-4{stroke-width:0}.cls-3{fill:#342e2e}.cls-4{fill:#3a94d0}`}
        </style>
      </defs>
      <path
        d="M.05 97.67c2.34-3.72 6.22-5.68 9.56-8.24 7.77-5.94 15.63-11.77 23.49-17.59.91-.67.94-1.1.37-2.04-10.59-17.28-8.26-38.63 5.91-52.96 7.72-7.8 17.2-11.99 28.19-12.69 22.46-1.43 42.69 15.44 45.21 37.69 2.7 23.75-13.64 44.83-37.36 47.85-12.96 1.65-24.45-2.07-34.24-10.74-1.06-.93-1.57-.9-2.61.02-8.97 7.91-17.92 15.86-27.22 23.39-.68.55-1.38 1.08-2.1 1.57-3.3 2.2-6.85 1.31-8.72-2.17-.15-.28-.32-.55-.49-.82v-3.28Zm101.81-45.74c.06 2.46-.92 4.66-1.68 6.93-.42 1.26-.86 1.11-1.77.4-9.59-7.53-19.22-15-28.79-22.54-.94-.74-1.46-.68-2.34.01-9.54 7.5-19.09 14.98-28.7 22.38-1.24.95-1.2 1.69-.6 2.93 6.63 13.93 21.41 21.97 36.77 19.95 20.05-2.64 33.93-21.85 30.04-41.6-3.07-15.61-16.66-27.67-32.15-28.53C56 10.93 41.32 20.78 36.33 36.3c-1.63 5.07-2.19 10.26-1.27 15.81 1.07-.93 2.12-1.49 2.87-2.45.45-.3.93-.57 1.36-.9 9.35-7.29 18.7-14.57 28.01-21.91.92-.73 1.44-.71 2.34.02 3.48 2.82 7.04 5.54 10.57 8.29 1.78 1.39 3.58 2.76 5.6 4.31 0-1.66.05-2.96-.02-4.25-.05-.96.33-1.22 1.24-1.19 1.69.06 3.4.1 5.09-.01 1.3-.08 1.55.44 1.53 1.61-.07 2.94-.02 5.89-.02 8.84 0 .6-.03 1.16.56 1.6 2.58 1.93 5.12 3.9 7.68 5.85Z"
        className="cls-1"
      />
      <path
        d="M502.75 1.04V51.3c0 5.02 1.74 6.38 6.63 5.17q3.01-.75 3.56 2.39c.13.75.15 1.55.42 2.24.65 1.69.02 2.42-1.65 2.86-3.73.97-7.45 1.81-11.32.98-3.95-.85-6.66-3.81-7.13-7.78-.15-1.3-.23-2.61-.23-3.91-.01-16.75 0-33.5-.01-50.26 0-.65-.08-1.3-.12-1.95h9.86Z"
        className="cls-4"
      />
      <path
        d="M146.21 56.36v9.25c0 5.35-.02 10.69.02 16.04 0 .98-.2 1.34-1.25 1.3-2.46-.08-4.93-.05-7.39-.01-.77.01-1.13-.2-1.03-1.01.04-.32 0-.65 0-.98 0-19.86.03-39.72-.05-59.58 0-1.78.53-2.21 2.17-2.08 1.74.14 3.5.08 5.25.01.95-.04 1.22.29 1.19 1.21-.06 2.01-.02 4.02-.02 5.97.48.25.6-.09.74-.27 7.95-10.65 24.01-9.85 31.91.76 7.17 9.64 6.39 24.54-1.91 32.81-8.14 8.1-21.38 7.24-28.46-1.85-.3-.39-.59-.78-1.18-1.58Zm0-14.98c0 .87.11 1.76-.02 2.62-.52 3.57 1.26 6.16 3.57 8.54.23.24.46.47.7.69 6.32 5.95 15.19 4.82 19.79-2.54 2.27-3.63 3.01-7.6 2.25-11.76-1.04-5.65-4.32-9.61-9.82-11.44-5.43-1.81-9.98.17-13.94 3.82-1.74 1.61-2.74 3.63-2.54 6.13.11 1.3.02 2.62.02 3.93Z"
        className="cls-1"
      />
      <path
        fill="#352f2f"
        strokeWidth="0"
        d="M284.64 26.7c2.16-2.4 4.43-4.49 7.23-5.96 7.91-4.14 17.9-2.23 24.11 4.6 8.07 8.87 8.42 23.74.79 33.01-7.87 9.56-22.3 9.31-29.9-.53-.26-.33-.51-.67-.95-1.26-.04.78-.08 1.29-.08 1.8 0 7.69-.04 15.39.03 23.08.01 1.26-.34 1.56-1.55 1.51-2.35-.1-4.71-.07-7.06 0-.93.02-1.2-.26-1.19-1.19.03-20.41.02-40.82 0-61.23 0-.9.21-1.25 1.18-1.22 2.08.07 4.16.07 6.24 0 .96-.03 1.19.32 1.17 1.21-.06 2.06-.02 4.12-.02 6.18Zm1.21 14.66c0 .93.11 1.87-.02 2.78-.45 3.22.95 5.7 3.06 7.91.64.67 1.29 1.34 2.01 1.92 5.32 4.29 12.16 4.11 16.8-.54 2.93-2.94 4.35-6.59 4.64-10.71.44-6.3-3.1-12.28-8.81-14.78-5.14-2.25-11.17-.84-15.42 3.6-1.53 1.6-2.47 3.42-2.29 5.73.11 1.36.02 2.73.02 4.09Z"
      />
      <path
        d="M544.68 44.98c-5.64 0-11.27.02-16.91-.02-1.1 0-1.36.21-1.14 1.37 1.88 10.15 12.49 15 21.28 9.66 1.52-.92 2.86-2.07 3.64-3.68.43-.89.96-1.03 1.82-.77 1.93.58 3.87 1.17 5.83 1.68 1 .26 1.19.6.65 1.55-2.25 3.88-5.53 6.61-9.59 8.37-12.47 5.41-27.92.82-32.77-13.52-4.47-13.2 2.99-28.47 18.39-30.72 17.92-2.61 28.22 11.1 27.35 24.32-.09 1.4-.52 1.83-1.95 1.8-5.53-.09-11.05-.04-16.58-.04Zm-4.74-6.32c4.11 0 8.21-.04 12.32.02 1.22.02 1.49-.3 1.21-1.5-.34-1.44-.74-2.84-1.41-4.16-2.67-5.25-8.37-8.02-14.59-7.09-5.39.81-10.11 5.53-11.04 10.96-.22 1.3 0 1.84 1.51 1.8 3.99-.11 7.99-.04 11.99-.04Z"
        className="cls-4"
      />
      <path
        d="M356.37 44.99c-5.64 0-11.28.02-16.92-.02-1.03 0-1.47.16-1.28 1.34 1.69 10.27 13.19 15.22 21.84 9.37 1.33-.9 2.5-1.99 3.19-3.45.39-.83.85-.94 1.65-.7 1.98.6 3.97 1.2 5.99 1.69 1.09.27 1.07.68.59 1.53-2.34 4.11-5.84 6.9-10.17 8.63-13.6 5.45-29.25-.72-32.73-15.77-3.77-16.3 8.22-28.95 22.68-29.02 12.6-.06 21.39 8.53 23.24 18.75.37 2.04.48 4.1.31 6.18-.1 1.16-.41 1.52-1.64 1.51-5.58-.08-11.17-.03-16.75-.03Zm-4.93-6.34c4.05 0 8.1-.08 12.14.04 1.54.04 1.7-.5 1.45-1.78-1.31-6.58-7.07-11.36-13.61-11.24-6.8.13-12.28 4.9-13.43 11.61-.19 1.09-.01 1.42 1.14 1.4 4.1-.06 8.21-.02 12.31-.02ZM244.3 18.67c13.46 0 23.37 9.9 23.37 23.35 0 13.39-9.99 23.26-23.5 23.23-13.39-.03-23.33-9.92-23.38-23.21-.05-13.4 10.37-23.81 23.52-23.37Zm-13.6 23.31c0 2.03.28 4.04 1.04 5.92 2 4.94 5.42 8.34 10.88 9.03 5.17.66 9.3-1.38 12.3-5.6 3.68-5.17 3.81-12.87.38-18.21-4.99-7.77-15.18-8.46-20.98-1.4-2.45 2.98-3.61 6.42-3.62 10.26Z"
        className="cls-3"
      />
      <path
        d="M440.7 19.33c2.99 0 6 .04 9-.02 1.04-.02 1.12.72 1.36 1.33 3.55 9.02 7.07 18.04 10.61 27.06.87 2.22 1.76 4.42 2.75 6.93 2.99-8.59 5.87-16.84 8.75-25.1 1-2.88 2.05-5.74 2.97-8.65.37-1.15.88-1.66 2.16-1.58 2.02.12 4.05.11 6.07 0 1.43-.08 1.76.17 1.21 1.64C482 30.35 478.52 39.8 475 49.23c-3.23 8.67-6.46 17.34-9.71 26-2.01 5.35-6.77 8.65-12.45 8.75-1.7.03-3.37.06-5.04-.35-.68-.17-.88-.46-.88-1.1.02-2.18.04-4.37 0-6.55-.02-.89.39-1 1.1-.86.59.11 1.18.22 1.78.29 3.48.42 4.71-.21 6.27-3.36 1.09-2.2 1.87-4.52 2.73-6.81.3-.8-.01-1.37-.27-2-5.79-14.04-11.58-28.07-17.37-42.11-.22-.54-.62-1.04-.46-1.8Z"
        className="cls-1"
      />
      <path
        d="M579.84 27.36c2.51-3.07 5.43-5.33 8.95-6.88 3.92-1.72 8.03-2.18 12.21-1.67 5.38.65 8.98 4.51 10.17 10.52.53 2.69.72 5.4.72 8.13 0 8.46-.03 16.92.03 25.38 0 1.26-.29 1.73-1.62 1.66-2.18-.11-4.38-.09-6.57 0-1.19.05-1.44-.39-1.44-1.49.04-8.08.02-16.15.03-24.23 0-1.87-.21-3.7-.67-5.5-1.19-4.62-4.77-6.92-9.42-5.86-5.31 1.21-9.02 4.45-11.25 9.4-.32.72-.35 1.47-.35 2.24 0 7.97-.02 15.94.03 23.9 0 1.23-.31 1.61-1.55 1.55-2.24-.1-4.49-.06-6.73-.01-.95.02-1.35-.21-1.35-1.26.03-14.24.03-28.49 0-42.73 0-.98.37-1.19 1.25-1.17 2.08.05 4.16.09 6.24-.01 1.18-.06 1.36.45 1.34 1.44-.05 2.2-.02 4.41-.02 6.61ZM637.14 65.25c-4.88-.1-11.19-1.78-16.5-6.38-.96-.83-1.18-1.5-.46-2.6.81-1.22 1.5-2.54 2.13-3.86.46-.98.84-1 1.66-.33 3.31 2.68 6.98 4.69 11.22 5.47 2.66.49 5.33.51 7.97-.2 1.22-.33 2.36-.87 3.27-1.78 2.23-2.24 1.71-5.84-1.06-7.31-2.14-1.13-4.51-1.65-6.81-2.31-3.31-.95-6.53-2.12-9.71-3.44-9.86-4.1-7.92-15.9-2.05-20.08 2.69-1.92 5.65-3.13 8.9-3.5 6.99-.8 13.63.01 19.42 4.53 1.3 1.01 1.54 1.8.47 3.12-.92 1.14-1.67 2.43-2.42 3.69-.48.81-.81.86-1.56.23-4.52-3.79-9.7-5.33-15.57-4.24-1.03.19-2 .54-2.88 1.11-3.11 2.02-3 6.28.25 8.05 2.56 1.39 5.39 2.1 8.16 2.92 2.99.89 5.94 1.87 8.82 3.06 10.06 4.18 7.72 16.25 1.95 20.21-4.01 2.76-8.45 3.67-15.18 3.64Z"
        className="cls-4"
      />
      <path
        d="M417.48 41.87c0-4.59-.03-9.17.02-13.76.01-1.07-.31-1.45-1.39-1.38-1.25.08-2.52 0-3.78.03-.7.02-.96-.22-.95-.93.04-1.86.06-3.71 0-5.57-.03-.87.36-.98 1.08-.95 1.15.04 2.31-.09 3.45.04 1.39.15 1.61-.46 1.59-1.67-.06-3.93.02-7.86-.05-11.79-.02-1.2.28-1.62 1.54-1.55 2.18.11 4.38.13 6.57 0 1.38-.08 1.61.44 1.59 1.67-.07 3.93.02 7.86-.05 11.79-.02 1.18.21 1.63 1.51 1.57 2.4-.12 4.82 0 7.23-.05.88-.02 1.27.15 1.22 1.14-.08 1.74-.07 3.49 0 5.24.03.9-.31 1.1-1.14 1.08-2.46-.04-4.93.05-7.39-.04-1.19-.05-1.43.36-1.43 1.47.05 7.59.01 15.18.03 22.76 0 4.18 2.51 6.19 6.63 5.29 1.27-.28 2.51-.75 3.71-1.26.75-.32 1.04-.26 1.24.58.47 1.9 1.05 3.78 1.57 5.67.15.55.24 1.05-.47 1.34-4.59 1.9-9.26 3.4-14.33 2.37-5.2-1.06-7.99-4.48-8.01-9.81-.02-4.42 0-8.84 0-13.27Z"
        className="cls-3"
      />
      <path
        d="M391.42 28.22c2.21-3.14 4.64-5.83 7.88-7.72 2.37-1.38 4.91-2.04 7.67-1.97.96.02 1.19.33 1.17 1.23-.06 2.13-.07 4.26 0 6.39.03.94-.28 1.11-1.19 1.19-5.12.44-9.72 2.05-13.01 6.3-1.21 1.56-1.82 3.22-1.79 5.27.09 7.97.01 15.94.07 23.91 0 1.27-.29 1.71-1.62 1.65-2.24-.11-4.49-.06-6.73-.02-.93.02-1.35-.15-1.35-1.24.04-14.25.03-28.5.01-42.74 0-.82.17-1.17 1.08-1.15 2.24.06 4.49.06 6.73 0 .88-.02 1.11.27 1.09 1.12-.05 2.6-.02 5.2-.02 7.79Z"
        className="cls-1"
      />
      <path
        d="M200.09 28.75c1.86-3.12 3.81-5.49 6.38-7.31 2.76-1.95 5.76-3.04 9.19-2.91.92.03 1.23.3 1.2 1.23-.06 2.07-.06 4.15 0 6.22.03.94-.17 1.28-1.23 1.36-4.59.33-8.78 1.71-12.07 5.14-1.79 1.87-2.82 3.94-2.77 6.7.16 7.91.03 15.82.08 23.74 0 1.23-.3 1.6-1.54 1.54-2.29-.1-4.6-.06-6.9-.02-.86.02-1.26-.14-1.26-1.15.03-14.3.03-28.59 0-42.89 0-.87.26-1.12 1.11-1.09 2.19.05 4.38.08 6.57 0 1.09-.04 1.25.4 1.23 1.34-.06 2.53-.02 5.07-.02 8.11Z"
        className="cls-3"
      />
      <path
        d="M37.93 49.68c-1.61-15.87 10.52-31.34 26.32-33.96 17.11-2.84 31.9 7.44 36.47 21.71 1.51 4.73 2.09 9.56 1.14 14.5-2.56-1.95-5.1-3.92-7.68-5.85-.59-.44-.56-1-.56-1.6 0-2.95-.05-5.89.02-8.84.03-1.16-.23-1.69-1.53-1.61-1.69.11-3.39.07-5.09.01-.91-.03-1.29.24-1.24 1.19.07 1.29.02 2.59.02 4.25-2.02-1.56-3.82-2.93-5.6-4.31-3.53-2.75-7.09-5.47-10.57-8.29-.9-.72-1.41-.75-2.34-.02-9.31 7.34-18.66 14.62-28.01 21.91-.43.33-.9.6-1.36.9Z"
        className="cls-4"
      />
    </svg>
  );
};

export default Logo;
