import { ReactElement } from 'react';
export type ListValueType =
  | string
  | number
  | boolean
  | React.ReactElement
  | undefined
  | null;

export type ListItemType =
  | 'currency'
  | 'percentage'
  | 'number'
  | 'text'
  | 'grade'
  | undefined;

export enum GradeEnum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  F = 'F',
  UNKNOWN = 'UNKNOWN',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

// export type GradeType = (typeof GradeEnum)[keyof typeof GradeEnum];
export type GradeType = (typeof GradeEnum)[keyof typeof GradeEnum];

export enum RatingsEnum {
  VERY_LOW = 'very-low',
  LOW = 'low',
  MODERATE = 'moderate',
  HIGH = 'high',
  VERY_HIGH = 'very-high',
  UNKNOWN = 'unknown',
  NOT_AVAILABLE = 'not-available',
}

export enum RatingsTitleCasedEnum {
  VERY_LOW = 'Very Low',
  LOW = 'Low',
  MODERATE = 'Moderate',
  HIGH = 'High',
  VERY_HIGH = 'Very High',
  UNKNOWN = 'Unknown',
  NOT_AVAILABLE = 'Not Available',
}

export type RatingLevelProps = RatingsEnum;

export type LatLng = {
  lat: number;
  lng: number;
};
export type ServerReport = {
  address_1: string;
  address_2: string;
  gmaps_place_id: string;
  locality: string;
  report_id: string;
  state: string;
  status: string;
  report_body: {
    created_dt: string | number | Date;
    report_id: string;
    address_1: string;
    address_2: string;
    locality: string;
    state: string;
    zip_code: string;
    use_type: string;
    size_sqft: number;
    bedrooms: number;
    bathrooms: number;
    preview_img: string;
    isNew?: boolean;
  };
  ui_search: string;
  zip_code: string;
};

export type Warning = {
  message?: ReactElement | null;
  type: 'warning' | 'info' | 'commercialAddress';
};
