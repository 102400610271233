import React from 'react';

export interface AmenitiesTrashProps {
  color: string;
  size?: number;
}

const AmenitiesTrash: React.FC<AmenitiesTrashProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5939_17249)">
        <path
          d="M7.07407 8.35156V17.4997C7.07407 18.6043 7.9695 19.4997 9.07407 19.4997H14.9259C16.0305 19.4997 16.9259 18.6043 16.9259 17.4997V8.35156M5 8.35156H19"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M8.88892 5.5H15.1111"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 11.9814V15.8703"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M13.5 11.9814V15.8703"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5939_17249">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AmenitiesTrash;
