import React from 'react';

export interface RisksDroughtLgProps {
  color: string;
  size?: number;
}

const RisksDroughtLg: React.FC<RisksDroughtLgProps> = ({
  color,
  size = 56,
}) => (
  <svg
    data-testid="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M47.5556 33.2012H10C7.79086 33.2012 6 34.992 6 37.2012V45.3123C6 47.5214 7.79086 49.3123 10 49.3123H47.5556C49.7647 49.3123 51.5556 47.5214 51.5556 45.3123V37.2012C51.5556 34.992 49.7647 33.2012 47.5556 33.2012Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M26.1328 33.8027L29.0359 38.4099L32.5 44.5L32.1211 49"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M38.6188 33.8123L29.5667 38.1541"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M7.00156 44.2092L12.104 44.2096"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M40.4977 42.8245L45.6001 42.8249M40.4977 37.8245L50.6001 37.8249"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M32.9995 45L38.7461 48.1493"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M25 41L18 49"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M21 45L17 42"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M30 33.5L32.5 24L25 17L28.5 17.5V14.5L33.5 20L34.5 10L36.5 14.5L38.5 12L40.5 25.5L42.5 33.5"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M13.4366 6.96642V5M8.8694 8.8694L7.47388 7.47388M6.96642 13.4366H5M8.8694 18.0672L7.47388 19.4627M13.4366 19.9701V22M18.0672 18.0672L19.4627 19.4627M19.9701 13.4366H22M19.4627 7.47388L18.0672 8.8694"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41 20L45 19.2V14.8L43.9091 12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47 13L45 15"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.9088 21.0056L45.2494 19.13"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.5 22.5L26 25.5V28.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5 23.5L25.5 25.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="13.5" cy="13.5" r="3" stroke={color} strokeWidth="2" />
  </svg>
);

export default RisksDroughtLg;
