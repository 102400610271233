import React from 'react';

export interface OutdoorsOutdoorsProps {
  color: string;
  size?: number;
}

const OutdoorsOutdoors: React.FC<OutdoorsOutdoorsProps> = ({
  color,
  size = 24,
}) => {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5205_36649)">
        <path
          d="M6.66636 17.3324C5.42877 17.3324 4.24186 16.8408 3.36675 15.9657C2.49163 15.0905 2 13.9036 2 12.666C2 11.4284 2.49163 10.2415 3.36675 9.36642C4.24186 8.49131 5.42877 7.99968 6.66636 7.99968C6.66636 6.67368 7.19311 5.40199 8.13073 4.46437C9.06835 3.52675 10.34 3 11.666 3C12.992 3 14.2637 3.52675 15.2013 4.46437C16.139 5.40199 16.6657 6.67368 16.6657 7.99967C17.9033 7.99967 19.0902 8.49131 19.9653 9.36642C20.8404 10.2415 21.3321 11.4284 21.3321 12.666C21.3321 13.9036 20.8404 15.0905 19.9653 15.9657C19.0902 16.8408 17.9033 17.3324 16.6657 17.3324"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6661 9.00098V14.0007M11.6661 21.0002V16.0005M11.6661 14.0007V16.0005M11.6661 14.0007L9.66626 12.0008M11.6661 16.0005L13.666 14.0007"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5205_36649">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OutdoorsOutdoors;
