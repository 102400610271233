import React from 'react';

export interface HomeListingStatusProps {
  color: string;
  size?: number;
}

export default function HomeListingStatus({
  color,
  size = 24,
}: HomeListingStatusProps) {
  return (
    <svg
      data-testid="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_6133_60977)">
        <path
          d="M3.75 6.25H18.5842L19.9077 7.5L18.5842 8.75H3.75V6.25Z"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M4.62158 8.71622L4.62158 22.5L7.86483 22.5L7.86482 8.71622"
          stroke={color}
          strokeWidth="1.5"
        />
        <path
          d="M4.62158 6.01367L4.62158 4.66232L6.2432 2.50016L7.86482 4.66232L7.86482 6.01367"
          stroke={color}
          strokeWidth="1.5"
        />
        <rect
          x="10.2363"
          y="11.8984"
          width="9.85135"
          height="6.5"
          stroke={color}
          strokeWidth="1.5"
        />
        <line
          x1="16.8853"
          y1="9.44531"
          x2="16.8853"
          y2="12.148"
          stroke={color}
          strokeWidth="1.5"
        />
        <line
          x1="11.8582"
          y1="9.4458"
          x2="11.8582"
          y2="12.1485"
          stroke={color}
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_6133_60977">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
