import './RadialScore.scss';

import cx from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';

type RadialScoreProps = {
  circleRadius: number;
  level: string;
  rating: number;
  value: number;
  total?: number;
  size?: string;
};
const RadialScore: React.FC<RadialScoreProps> = (props) => {
  const { circleRadius, level, rating, value, total, size = 'md' } = props;

  const strokeDasharray = 2 * 3.14 * circleRadius;
  const svgRadius = circleRadius * 2 - 10;
  const strokeDashoffset = strokeDasharray * ((100 - rating) / 100);

  const divCx = cx('RadialScore', size);

  return (
    <div className={divCx}>
      <svg
        width="160"
        height="160"
        viewBox="0 0 180 180"
        style={{ transform: 'rotate(-90deg)' }}
      >
        <circle
          r={svgRadius}
          cx="90"
          cy="90"
          fill="transparent"
          stroke="#e0e0e0"
          strokeWidth="8px"
        />
        <motion.circle
          initial={{ strokeDashoffset: 285 }}
          animate={{ strokeDashoffset }}
          transition={{
            duration: 1,
            ease: 'easeInOut',
          }}
          r={svgRadius}
          cx="90"
          cy="90"
          fill="transparent"
          stroke={level}
          strokeLinecap="round"
          strokeWidth="8px"
          strokeDasharray={strokeDasharray}
          // strokeDashoffset={strokeDashoffset}
        />
      </svg>
      <div className="score-value">
        <div className="value" style={{ color: level }}>
          {value}
        </div>
        {total && <div className="total">of {total}</div>}
      </div>
    </div>
  );
};

export default RadialScore;
